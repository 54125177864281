.bots {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.bots .profit-panel canvas {
  height: 265px !important;
}
.bots .pnl-panel canvas {
  height: 270px !important;
}

.bot-header {
  background-color: white;
  padding: 15px;
  margin-left: 2px !important;
}

// .portfolio {
//   height: 400px;
// }

.bots .bottom-row {
  min-height: 860px !important;
}

.bots .show-graph-btn {
  background-color: transparent;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: #9fa2b4;
}

@media screen and (min-height: 1440px) {
  .bots .profit-panel canvas {
    height: 450px !important;
  }
  .bots .pnl-panel canvas {
    height: 500px !important;
  }
  .bots .bottom-row {
    height: 600px !important;
  }
}

@media screen and (min-height: 2000px) {
  .bots .profit-panel canvas {
    height: 650px !important;
  }
  .bots .pnl-panel canvas {
    height: 680px !important;
  }
  .bots .bottom-row {
    height: 800px !important;
  }
}
