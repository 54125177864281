@media only screen and (min-height: 1440px) {
  .general-panel .setting-row {
    margin-top: 10px;
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media only screen and (min-height: 2000px) {
  .general-panel .setting-row {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 100px;
    margin-right: 100px;
  }
}
