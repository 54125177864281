.StripeElement {
    box-sizing: border-box;
  
    height: 40px;
  
    padding: 10px 12px;
  
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    font-weight: bold;
  
    //   box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
  .plan-select {
    border: 1px solid #eee;
    border-radius: 5px;
  }
  
  .plan-select a {
    text-decoration: none !important;
  }
  
  .plan-active {
    text-decoration: none;
    background-color: #eee;
    color: #002352;
  }
  
  .plan-inactive {
    text-decoration: none;
    color: #002352;
  }
  
  .top-corner {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  
  .bottom-corner {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  
  .input:focus {
    box-shadow: 0 1px 3px 0 #cfd7df !important;
    border: 1px solid #ced4da !important;
  }
  
  .input {
    font-weight: normal;
  }
  