.ask {
  background: #12b886;
  color: white;
}

.bid {
  background: #fa5252;
  color: white;
}

.price-text {
  text-align: center;
  padding: 7px;
  font-size: 15px;
  font-weight: bold;
}

.price-info {
  font-size: 15px;
  font-weight: bold;
}
