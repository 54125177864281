.account-panel .row {
  margin-top: 20px;
}

@media only screen and (min-height: 1440px) {
  .account-panel .row {
    margin-top: 30px;
    margin-right: 400px;
    margin-left: 100px;
  }
}

@media only screen and (min-height: 2000px) {
  .account-panel .row {
    margin-top: 35px;
    margin-right: 500px;
    margin-left: 100px;
  }
}
