.referrals-redeem-form {
  width: 500px;
}
.referrals-redeem-form label {
  display: block;
}

.referrals-redeem-form input {
  display: block;
  width: 100%;
}

.referrals-redeem-form select {
  height: 40px;
  border: solid 1px #73cee2;
  width: 100%;
  border-radius: 100px;
  outline: none;
  padding: 0px 15px;
}

.referrals-redeem-form label {
  display: block;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 18px;
  padding-left: 10px;
}

.referrals-redeem-form input {
  height: 40px;
  border: solid 1px #73cee2;
  width: 100%;
  border-radius: 100px;
  outline: none;
  padding: 0px 15px;
}

.referrals-redeem-form .gift {
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 10px;
}

.referrals-redeem-form .gift-doge {
  font-size: 28px;
  color: #73cee2;
  margin-left: 30px;
}

.referrals-redeem-form .btn-redeem {
  display: block;
  margin: 30px 0px;
}
