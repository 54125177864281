.trans-from {
  max-width: 40%;
  text-align: center;
}

.trans-icon {
  max-width: 20%;
  text-align: center;
  font-size: 11px;
  padding-top: 6px;
}

.trans-icon:hover {
  color: #fa5252;
}

.trans-to {
  max-width: 40%;
  text-align: center;
}



.trans-select {
  margin-top: 30px;
  align-items: center;
}

#trans {
  font-weight: bold;
  color: #4c556a;
  font-size: 13px;
}

.trans-select-coin {
  max-width: 40%;
}

#trans label {
  font-size: 13px;
}

.trans-amount {
  margin-top: 20px;
}

#trans .modal-title {
  color: #002352 !important;
}
