.paper-wallet .total-row {
  margin-top: 25px;
  margin-bottom: 25px;
}
.paper-wallet .btn-col {
  max-width: 100px;
  margin: 3px;
}

.paper-wallet .btn {
  font-size: 12px !important;
  width: 100%;
}



.paper-wallet .value {
  font-size: 15px;
  font-weight: bold;
}

.paper-wallet .hide-small {
  max-width: 180px;
  margin: auto;
}
.paper-wallet .search-box {
  max-width: 200px;
  margin: auto;
}

.paper-wallet td {
  padding: 10px;
  //border-bottom: 0.5px solid #eee;
  border-top: none;
  vertical-align: middle;
  // display: flex !important;
}





.paper-wallet .tab-inactive:hover {
  color: #fa5252 !important;
}
