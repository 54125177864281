.signup-success {
  margin-top: 3vh;
}

.signup-success .form-group {
  margin-bottom: 1rem;
}

.signup-success .signup-success-col {
  max-width: 37% !important;
}

@media only screen and (max-width: 600px) {
  .signup-success .signup-success-col {
    max-width: 100% !important;
  }
}

@media only screen and (min-height: 1440px) {
  .signup-success {
    margin-top: 180px !important;
  }

  .signup-success .signup-success-col {
    max-width: 25% !important;
  }

  .signup-success p {
    margin-top: 30px !important;
  }
  .signup-success h4 {
    margin-top: 50px !important;
  }

  .signup-success .card-body {
    padding: 50px !important;
  }
}

@media only screen and (min-height: 2000px) {
  .signup-success {
    margin-top: 220px !important;
  }

  .signup-success .signup-success-col {
    max-width: 22% !important;
  }

  .signup-success p {
    margin-top: 30px !important;
  }
  .signup-success h4 {
    margin-top: 50px !important;
  }

  .signup-success .card-body {
    padding: 50px !important;
  }
}
