.portfolio-panel {
}

.portfolio-panel .graph-container {
  margin-top: 30px;
  margin-right: 20px;
}

.portfolio-panel .graph {
  padding-top: 20px;
  padding-right: 10px;
}

.portfolio-panel .graph-label {
  font-size: 12px !important;
  color: #9fa2b4 !important;
}

.portfolio-panel .portfolio-label {
  font-size: 25px;
  font-weight: bold;
  margin-right: 7px;
  padding-bottom: 4px;
}

.portfolio-panel .trade-volume-label {
  font-size: 25px;
  font-weight: bold;
  margin-right: 7px;
  margin-left: 15px;
  padding-bottom: 4px;
}
