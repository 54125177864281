.affiliate-navbar {
  margin-top: 25px;
  margin-left: 140px;
  margin-right: 100px;
  color: black;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.affiliate-navbar a {
  text-decoration: none;
}

.affiliate-navbar .navlink {
  margin: 3px 15px;
  font-size: 16px;
}

.affiliate-navbar .active {
  color: #0e1820;
  font-weight: bold;
}

.affiliate-navbar .inactive {
  color: #adadbd;
}

.affiliate-navbar .signin {
  margin: 0px 8px;
  padding: 7px 25px;
  height: 36px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: bold;
  background-color: #73cee2;
  color: white;
  text-decoration: none;
  border: none;
}

.welcome-msg {
  text-align: center;
  margin-top: 20px;
  color: orangered;
  font-size: 18px;

  margin-bottom: 0px;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .affiliate-navbar {
    margin-left: 20px;
    margin-right: 20px;
  }
  .affiliate-navbar .navlink {
    display: block;
    text-align: right;
    padding-bottom: 5px;
  }
  .affiliate-navbar .signin {
    display: block;
    float: right;
  }
}
