.light {
    --primary-color: #007DFF;
    --dark-blue: #0025A7;
    --light-blue: #00D7F4;
    --menu-a-hover:#E0ECFF;
    --menu-active:#007DFF;
    --progress-bar-color:#007DFF;
    --theme-background-color: #f5f5f5;
    --theme-background-second: #fff;
    --theme-body-color: #333;
    --table-bg:#fff;
    --table-border-top:#f5f5f5;
    --table-font-color:#333;
    --heading-color:#333;
    --navbar-icon-color:invert(97%) sepia(95%) saturate(1445%) hue-rotate(190deg) brightness(90%);;
    --dropdown-bg:#fff;
    --light-text: #8a92a6;
    --999: #999;
    --input-range-slider-color:#0064FE;
    --theme-div-border:#e9ecef;
    --theme-card-border:#e9ecef;
    --theme-modal-bg-color: #fff;
    --text-color: #2b3674;
    --dropdown-item-color: #8a92a6;
    --checkbox-color:rgba(0, 0, 0, 0.1);
    --text-value: #2b3674;
    --a-tag-color: #2b3674;
    --dropdown-menu-item-hover:#8a92a6;
    --button-primary: #73CEE2;
    --button-success: #05CD99;
    --button-success-border: #05CD99;
    --td-color: #555b81;
    --form-control-plaintext: #555b81;
    --tab-active: #0064FE;
    --chart-bg: #0064FE;
    --tab-active-text-color: #73CEE2;
    --tab-inactive-hover:#73CEE2;
    --form-label-color:#4c556a;
    --table-header-bg:#FFFFFF;
    --theme-border-table:#dee2e6;
    --expand-button-bg:#fff;
    --expand-button-border:#e9ecef;
    
    --menu-a-active:#F5F7FF;
    --input-select-bg-color:#F0F2F5;
    --sidebar-item-color:#73CEE2;
    --sidebar-item-color-hover:#73CEE2;
    --form-input-bg:#FFF;
    --form-input-border : var(--theme-div-border);
    --form-input-text-color :var(--text-value);
    --form-input-input-group-text-bg :#05CD99;
    --form-input-input-group-text-color : #fff;
    --btn-outline-secondary-color : var(--form-control-plaintext);
    --btn-outline-secondary-border-color : #d1d3da;
    --alert-success-color:#0064FE;
    --alert-success-bg-color:#cdf5eb;
    --alert-success-border-color:#b9f1e2;
    --up-color:#12b886;
    --down-color:#fa5252;
    --badge-secondary-color:#999;
    --badge-secondary-color: rgba(241, 155, 56, 1);
    --badge-success: rgba(5, 205, 153, 0.1);
    --badge-success-color:  rgba(5, 205, 153, 1);
    --badge-danger: #F1693A;
    --badge-danger-color: rgba(234, 57, 67, 1);
    --pagination-active-bg:#73CEE2;
    --pagination-active-txt-color:#FFF;
    --pagination-link-bg:#FFF;
    --pagination-link-color: rgba(138, 146, 166, 1);
    --pagination-link-border:#fff;
    --pagination-item-disabled-bg:rgba(240, 242, 245, 1);
    --pagination-item-disabled-border:rgba(240, 242, 245, 1);
    --pagination-item-disabled-txt-color:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-bg:#73CEE2;
    --pagination-item-hover-not-active-border:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-text-color:#fff;
    --button-active:#73CEE2;
    --segment-controller-border:#73CEE2;
    --segment-controller-bg:#73CEE2;
    --segment-controller-color:#73CEE2;
    --down-text-color:rgba(234, 57, 67, 1);
    --graph-primary-color:#73CEE2;
    --support-card-border:var(--theme-div-border);
    --support-card-bg:var(--theme-background-color);
    --navbar-item-text-color:#8A92A6;
    --table-header-color:var(--text-title);
    --portfolio-value-color:#73CEE2;
    --trade-value-color:#05cd99;
    --data-page-select-text:#2b3674;
    //marketplace
    --form-input-bg-marketplace:#fff;
    --dropzone-color:#bdbdbd;
    --dropzone-bg-color:#fafafa;
    --dropzone-border-color:3px dashed #eeeeee;
    --modal-footer-border:#dee2e6;
    --template-submit-info:#8A92A6;
    --template-modal-headings:#222222;
    --form-control-color:#4c556a;
    --form-control-bg-color:#fff;
    --form-control-border-color:#E9ECEF;
    --default-img-bg:#fff;
    --default-img-border:#E9ECEF;
    --default-img-color:#F3F3F3;
    --text-color-down:#FF3B30;
    --text-color-up:#05CD99;
    --payout-btn-bg:#F5F7FF;
    --text-div-bg:#000;
    --text-div-bg-light:#F5F7FF;
    --checkbox-div-bg:#F5F7FF;
    --text-div-color:#fff;
    --text-div-bg-hover:#F8FAFD;
    --card-list-text:#52667E;
    --success-color:#3EE488;
    --light-border:#e8e9ee;
    --subscription-card-bg:#E0ECFF;
    
}

.dark {
    --primary-color: #007DFF;
    --dark-blue: #0025a7;
    --light-blue: #00d7f4;
    --menu-a-hover:#222;
    --progress-bar-color:#007DFF;
    --theme-background-color: #222;
    --theme-background-second: #333333;
    --theme-body-color: #dcd8d8;
    --menu-active:#00d7f4;
    --table-bg:rgba(0, 0, 0, 0.30);
    --table-border-top:#262626;
    --table-font-color:#FFF;
    --heading-color:#FFF;
    --navbar-icon-color:invert(100%) brightness(15.5);;
    --dropdown-bg:#000;
    --light-text: #8a92a6;
    --999: #999;
    --input-range-slider-color:#0064FE;
    --white:rgba(0, 0, 0, 0.30);
    --tab-active:#fff;
    --chart-bg: #0064FE;
    --checkbox-color:#EEE;
    --theme-modal-bg-color: #444;
    --theme-div-border:rgba(57, 57, 59, 1);
    --theme-card-border:rgba(57, 57, 59, 1);
    --text-color: #FFF;
    --dropdown-item-color: #FFF;
    --text-title: #8A92A6;
    --text-value: #FFFFFF;
    --a-tag-color: #73CEE2;
    --dropdown-menu-item-hover:#fff;
    --button-primary: #73CEE2;
    --button-success: #12B886;
    --button-success-border: #12B886;
    --td-color: #FFFFFF;
    --form-control-plaintext: #999;
    --tab-active-text-color: #73CEE2;
    --tab-inactive-hover:#73CEE2;
    --navbar-inactive-icon-color:#73CEE2;
    --form-label-color:var(--text-title);
    --table-header-bg:#171A1E;
    --theme-border-table:#2C3139;
    --expand-button-bg:#1E2025;
    --expand-button-border:#8A92A6;
    --menu-a-active:#2C3138;
    --input-select-bg-color:#33363F;
    --sidebar-item-color:#73CEE2;
    --sidebar-item-color-hover:rgba(115, 206, 226, 1);
    --form-input-bg: var(--theme-background-color);
    --form-input-border : var(--text-title);
    --form-input-text-color :var(--text-title);
    --form-input-input-group-text-bg :#73CEE2;
    --form-input-input-group-text-color : var(--text-value);
    --btn-outline-secondary-color : var(--text-value);
    --btn-outline-secondary-border-color : #33363F;
    --alert-success-color:#0064FE;
    --alert-success-bg-color: var(--input-select-bg-color);
    --alert-success-border-color: var(--input-select-bg-color);
    --up-color:#12b886;
    --down-color:#fa5252;
    --badge-secondary-color:#999;
    --badge-success: rgba(5, 205, 153, 0.1);
    --badge-success-color:  rgba(5, 205, 153, 1);
    --badge-danger: #F1693A;
    --badge-danger-color: rgba(234, 57, 67, 1);
    --pagination-active-bg:#73CEE2;
    --pagination-active-txt-color:#FFF;
    --pagination-link-bg:var(--theme-background-color);
    --pagination-link-color: rgba(138, 146, 166, 1);
    --pagination-link-border:var(--theme-background-color);
    --pagination-item-disabled-bg:var(--theme-background-color);
    --pagination-item-disabled-border:var(--theme-background-color);
    --pagination-item-disabled-txt-color:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-bg:#73CEE2;
    --pagination-item-hover-not-active-border:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-text-color:#fff;
    --button-active:#73CEE2;
    --segment-controller-border:#73CEE2;
    --segment-controller-bg:#73CEE2;
    --segment-controller-color:rgba(138, 146, 166, 1);
    --down-text-color:rgba(234, 57, 67, 1);
    --support-card-border:var(--theme-div-border);
    --support-card-bg:var(--theme-background-color);
    --navbar-item-text-color:#8A92A6;
    --table-header-color:var(--text-title);
    --portfolio-value-color:#73CEE2;
    --trade-value-color:#05cd99;
    --data-page-select-text:#fff;

    --form-input-bg-marketplace:#171A1E;
    --dropzone-color:#8A92A6;
    --dropzone-bg-color:#171A1E;
    --dropzone-border-color:3px dashed #2C3139;
    --modal-footer-border:1px solid #8A92A6;
    --template-submit-info:#8A92A6;
    --template-modal-headings:#FFFFFF;
    --form-control-color:#8A92A6;
    --form-control-bg-color:#171A1E;
    --form-control-border-color:#2C3139;
    --default-img-bg:#171A1E;
    --default-img-border:#2C3139;
    --default-img-color:#F3F3F3;
    --text-color-down:#FF3B30;
    --text-color-up:#05CD99;
    --payout-btn-bg:#F5F7FF;
    --text-div-bg:#333740;
    --text-div-bg-light:#333740;
    --checkbox-div-bg:#333740;
    --text-div-color:#fff;
    --text-div-bg-hover:#2C3138;
    --card-list-text:#fff;
    --success-color:#3EE488;
    --light-border:#e8e9ee;
    --select-bg:#333;
    --select-text-color:#fff;
    --menu-icon-bg:#333;
    --drop-light-text:#bbb;
    --bot-desc-bg:#444;
    --white-btn-col:#333;
    --dna-cart-bg:#222;
    --border-color-table:#666;
    --create-bot-input:#444;
    --switch-bg:#444;
    --step-icon-bg:#333;
    --checkbox-bg:#666;
    --grey-button:#333;
    --tbl-header:#666;
    --table-text-color:#bdbbbb;
    --table-row-hover:#444;
    --subscription-card-bg:#999;
    --menu-active-dark:#0064FE;
    --invert-filter:invert(100%) brightness(200%);

}
.pink {
    --primary-color: #E33453;
    --dark-blue: #AA113F;
    --light-blue: #FE5487;
    --progress-bar-color:#E33453;
    --menu-active:var(--primary-color);
    --theme-background-color: #f5f5f5;
    --theme-background-second: #FFFFFF;
    --theme-body-color: #333;
    --table-bg:rgba(0, 0, 0, 0.30);
    --table-border-top:#262626;
    --table-font-color:#333;
    --heading-color:#FFF;
    --navbar-icon-color:invert(19%) sepia(93%) saturate(3194%) hue-rotate(334deg) brightness(84%);
    --dropdown-bg:#fff;
    --light-text: #8a92a6;
    --999: #999;
    --chart-bg: #D33C6D;
    --input-range-slider-color:#D33C6D;
    --white:#fff;
    --tab-active:#fff;
    --checkbox-color:#EEE;
    --theme-modal-bg-color: #fff;
    --theme-div-border:rgba(57, 57, 59, 1);
    --theme-card-border:rgba(57, 57, 59, 1);
    --text-color: #FFF;
    --dropdown-item-color: #FFF;
    --text-title: #8A92A6;
    --text-value: #FFFFFF;
    --a-tag-color: #73CEE2;
    --dropdown-menu-item-hover:#fff;
    --button-primary: #73CEE2;
    --button-success: #12B886;
    --button-success-border: #12B886;
    --td-color: #FFFFFF;
    --form-control-plaintext: #4c556a;
    --tab-active-text-color: #73CEE2;
    --tab-inactive-hover:#73CEE2;
    --navbar-inactive-icon-color:#73CEE2;
    --form-label-color:var(--text-title);
    --table-header-bg:#171A1E;
    --theme-border-table:#2C3139;
    --expand-button-bg:#1E2025;
    --expand-button-border:#8A92A6;
    --menu-a-hover:#FBEBF0;
    --menu-a-active:#2C3138;
    --input-select-bg-color:#F0F2F5;
    --sidebar-item-color:#73CEE2;
    --sidebar-item-color-hover:rgba(115, 206, 226, 1);
    --form-input-bg: var(--theme-background-color);
    --form-input-border : var(--text-title);
    --form-input-text-color :var(--text-title);
    --form-input-input-group-text-bg :#73CEE2;
    --form-input-input-group-text-color : var(--text-value);
    --btn-outline-secondary-color : var(--text-value);
    --btn-outline-secondary-border-color : #33363F;
    --alert-success-color:#0064FE;
    --alert-success-bg-color: var(--input-select-bg-color);
    --alert-success-border-color: var(--input-select-bg-color);
    --up-color:#12b886;
    --down-color:#fa5252;
    --badge-secondary-color:#999;
    --badge-success: rgba(5, 205, 153, 0.1);
    --badge-success-color:  rgba(5, 205, 153, 1);
    --badge-danger: #F1693A;
    --badge-danger-color: rgba(234, 57, 67, 1);
    --pagination-active-bg:#73CEE2;
    --pagination-active-txt-color:#FFF;
    --pagination-link-bg:var(--theme-background-color);
    --pagination-link-color: rgba(138, 146, 166, 1);
    --pagination-link-border:var(--theme-background-color);
    --pagination-item-disabled-bg:var(--theme-background-color);
    --pagination-item-disabled-border:var(--theme-background-color);
    --pagination-item-disabled-txt-color:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-bg:#73CEE2;
    --pagination-item-hover-not-active-border:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-text-color:#fff;
    --button-active:#73CEE2;
    --segment-controller-border:#73CEE2;
    --segment-controller-bg:#73CEE2;
    --segment-controller-color:rgba(138, 146, 166, 1);
    --down-text-color:rgba(234, 57, 67, 1);
    --support-card-border:var(--theme-div-border);
    --support-card-bg:var(--theme-background-color);
    --navbar-item-text-color:#8A92A6;
    --table-header-color:var(--text-title);
    --portfolio-value-color:#73CEE2;
    --trade-value-color:#05cd99;
    --data-page-select-text:#fff;

    --form-input-bg-marketplace:#171A1E;
    --dropzone-color:#8A92A6;
    --dropzone-bg-color:#171A1E;
    --dropzone-border-color:3px dashed #2C3139;
    --modal-footer-border:1px solid #8A92A6;
    --template-submit-info:#8A92A6;
    --template-modal-headings:#FFFFFF;
    --form-control-color:#8A92A6;
    --form-control-bg-color:#171A1E;
    --form-control-border-color:#2C3139;
    --default-img-bg:#171A1E;
    --default-img-border:#2C3139;
    --default-img-color:#F3F3F3;
    --text-color-down:#FF3B30;
    --text-color-up:#05CD99;
    --payout-btn-bg:#F5F7FF;
    --text-div-bg:#333740;
    --text-div-bg-light:#333740;
    --checkbox-div-bg:#333740;
    --text-div-color:#fff;
    --text-div-bg-hover:#2C3138;
    --card-list-text:#fff;
    --success-color:#3EE488;
    
}
.purple {
    --primary-color: #7A51EC;
    --dark-blue: #5421A7;
    --light-blue: #B56BFF;
    --progress-bar-color:#7A51EC;
    --theme-background-color: #f5f5f5;
    --theme-background-second: #FFFFFF;
    --theme-body-color: #333;
    --menu-active:var(--primary-color);
    --table-bg:rgba(0, 0, 0, 0.30);
    --table-border-top:#262626;
    --table-font-color:#333;
    --heading-color:#FFF;
    --navbar-icon-color:invert(25%) sepia(87%) saturate(7462%) hue-rotate(250deg) brightness(76%);
    --dropdown-bg:#fff;
    --light-text: #8a92a6;
    --999: #999;
    --chart-bg: #923CFF;
    --input-range-slider-color:#923CFF;
    --white:#fff;
    --tab-active:#fff;
    --checkbox-color:#EEE;
    --theme-modal-bg-color: #fff;
    --theme-div-border:rgba(57, 57, 59, 1);
    --theme-card-border:rgba(57, 57, 59, 1);
    --text-color: #FFF;
    --dropdown-item-color: #FFF;
    --text-title: #8A92A6;
    --text-value: #FFFFFF;
    --a-tag-color: #73CEE2;
    --dropdown-menu-item-hover:#fff;
    --button-primary: #73CEE2;
    --button-success: #12B886;
    --button-success-border: #12B886;
    --td-color: #FFFFFF;
    --form-control-plaintext: #4c556a;
    --tab-active-text-color: #73CEE2;
    --tab-inactive-hover:#73CEE2;
    --navbar-inactive-icon-color:#73CEE2;
    --form-label-color:var(--text-title);
    --table-header-bg:#171A1E;
    --theme-border-table:#2C3139;
    --expand-button-bg:#1E2025;
    --expand-button-border:#8A92A6;
    --menu-a-hover:#EBE5FC;
    --menu-a-active:#2C3138;
    --input-select-bg-color:#F0F2F5;
    --sidebar-item-color:#73CEE2;
    --sidebar-item-color-hover:rgba(115, 206, 226, 1);
    --form-input-bg: var(--theme-background-color);
    --form-input-border : var(--text-title);
    --form-input-text-color :var(--text-title);
    --form-input-input-group-text-bg :#73CEE2;
    --form-input-input-group-text-color : var(--text-value);
    --btn-outline-secondary-color : var(--text-value);
    --btn-outline-secondary-border-color : #33363F;
    --alert-success-color:#923CFF;
    --alert-success-bg-color: var(--input-select-bg-color);
    --alert-success-border-color: var(--input-select-bg-color);
    --up-color:#12b886;
    --down-color:#fa5252;
    --badge-secondary-color:#999;
    --badge-success: rgba(5, 205, 153, 0.1);
    --badge-success-color:  rgba(5, 205, 153, 1);
    --badge-danger: #F1693A;
    --badge-danger-color: rgba(234, 57, 67, 1);
    --pagination-active-bg:#73CEE2;
    --pagination-active-txt-color:#FFF;
    --pagination-link-bg:var(--theme-background-color);
    --pagination-link-color: rgba(138, 146, 166, 1);
    --pagination-link-border:var(--theme-background-color);
    --pagination-item-disabled-bg:var(--theme-background-color);
    --pagination-item-disabled-border:var(--theme-background-color);
    --pagination-item-disabled-txt-color:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-bg:#73CEE2;
    --pagination-item-hover-not-active-border:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-text-color:#fff;
    --button-active:#73CEE2;
    --segment-controller-border:#73CEE2;
    --segment-controller-bg:#73CEE2;
    --segment-controller-color:rgba(138, 146, 166, 1);
    --down-text-color:rgba(234, 57, 67, 1);
    --support-card-border:var(--theme-div-border);
    --support-card-bg:var(--theme-background-color);
    --navbar-item-text-color:#8A92A6;
    --table-header-color:var(--text-title);
    --portfolio-value-color:#73CEE2;
    --trade-value-color:#05cd99;
    --data-page-select-text:#fff;

    --form-input-bg-marketplace:#171A1E;
    --dropzone-color:#8A92A6;
    --dropzone-bg-color:#171A1E;
    --dropzone-border-color:3px dashed #2C3139;
    --modal-footer-border:1px solid #8A92A6;
    --template-submit-info:#8A92A6;
    --template-modal-headings:#FFFFFF;
    --form-control-color:#8A92A6;
    --form-control-bg-color:#171A1E;
    --form-control-border-color:#2C3139;
    --default-img-bg:#171A1E;
    --default-img-border:#2C3139;
    --default-img-color:#F3F3F3;
    --text-color-down:#FF3B30;
    --text-color-up:#05CD99;
    --payout-btn-bg:#F5F7FF;
    --text-div-bg:#333740;
    --text-div-bg-light:#333740;
    --checkbox-div-bg:#333740;
    --text-div-color:#fff;
    --text-div-bg-hover:#2C3138;
    --card-list-text:#fff;
    --success-color:#3EE488;
    --select-bg:#fff;
    
}
.green {
    --primary-color: #21B298;
    --dark-blue: #0B816C;
    --light-blue: #25CF9C;
    --progress-bar-color:#21B298;
    --theme-background-color: #f5f5f5;
    --theme-background-second: #FFFFFF;
    --theme-body-color: #333;
    --menu-active:var(--primary-color);
    --table-bg:#fff;
    --table-border-top:#f5f5f5;
    --table-font-color:#333;
    --heading-color:#333;
    --navbar-icon-color:invert(45%) sepia(21%) saturate(1287%) hue-rotate(143deg) brightness(87%);
    --dropdown-bg:#fff;
    --light-text: #8a92a6;
    --999: #999;
    --input-range-slider-color:#ACD2A6;
    --theme-div-border:#e9ecef;
    --theme-card-border:#e9ecef;
    --theme-modal-bg-color: #fff;
    --text-color: #2b3674;
    --dropdown-item-color: #8a92a6;
    --checkbox-color:rgba(0, 0, 0, 0.1);
    --text-value: #2b3674;
    --a-tag-color: #2b3674;
    --dropdown-menu-item-hover:#8a92a6;
    --button-primary: #73CEE2;
    --button-success: #05CD99;
    --button-success-border: #05CD99;
    --td-color: #555b81;
    --form-control-plaintext: #555b81;
    --tab-active: #ACD2A6;
    --chart-bg: #ACD2A6;
    --tab-active-text-color: #73CEE2;
    --tab-inactive-hover:#73CEE2;
    --form-label-color:#4c556a;
    --table-header-bg:#FFFFFF;
    --theme-border-table:#dee2e6;
    --expand-button-bg:#fff;
    --expand-button-border:#e9ecef;
    --menu-a-hover:#DEF3F0;
    --menu-a-active:#F5F7FF;
    --input-select-bg-color:#F0F2F5;
    --sidebar-item-color:#73CEE2;
    --sidebar-item-color-hover:#73CEE2;
    --form-input-bg:#FFF;
    --form-input-border : var(--theme-div-border);
    --form-input-text-color :var(--text-value);
    --form-input-input-group-text-bg :#05CD99;
    --form-input-input-group-text-color : #fff;
    --btn-outline-secondary-color : var(--form-control-plaintext);
    --btn-outline-secondary-border-color : #d1d3da;
    --alert-success-color:#ACD2A6;
    --alert-success-bg-color:#cdf5eb;
    --alert-success-border-color:#b9f1e2;
    --up-color:#12b886;
    --down-color:#fa5252;
    --badge-secondary-color:#999;
    --badge-secondary-color: rgba(241, 155, 56, 1);
    --badge-success: rgba(5, 205, 153, 0.1);
    --badge-success-color:  rgba(5, 205, 153, 1);
    --badge-danger: #F1693A;
    --badge-danger-color: rgba(234, 57, 67, 1);
    --pagination-active-bg:#73CEE2;
    --pagination-active-txt-color:#FFF;
    --pagination-link-bg:#FFF;
    --pagination-link-color: rgba(138, 146, 166, 1);
    --pagination-link-border:#fff;
    --pagination-item-disabled-bg:rgba(240, 242, 245, 1);
    --pagination-item-disabled-border:rgba(240, 242, 245, 1);
    --pagination-item-disabled-txt-color:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-bg:#73CEE2;
    --pagination-item-hover-not-active-border:rgba(138, 146, 166, 1);
    --pagination-item-hover-not-active-text-color:#fff;
    --button-active:#73CEE2;
    --segment-controller-border:#73CEE2;
    --segment-controller-bg:#73CEE2;
    --segment-controller-color:#73CEE2;
    --down-text-color:rgba(234, 57, 67, 1);
    --graph-primary-color:#73CEE2;
    --support-card-border:var(--theme-div-border);
    --support-card-bg:var(--theme-background-color);
    --navbar-item-text-color:#8A92A6;
    --table-header-color:var(--text-title);
    --portfolio-value-color:#73CEE2;
    --trade-value-color:#05cd99;
    --data-page-select-text:#2b3674;
    //marketplace
    --form-input-bg-marketplace:#fff;
    --dropzone-color:#bdbdbd;
    --dropzone-bg-color:#fafafa;
    --dropzone-border-color:3px dashed #eeeeee;
    --modal-footer-border:#dee2e6;
    --template-submit-info:#8A92A6;
    --template-modal-headings:#222222;
    --form-control-color:#4c556a;
    --form-control-bg-color:#fff;
    --form-control-border-color:#E9ECEF;
    --default-img-bg:#fff;
    --default-img-border:#E9ECEF;
    --default-img-color:#F3F3F3;
    --text-color-down:#FF3B30;
    --text-color-up:#05CD99;
    --payout-btn-bg:#F5F7FF;
    --text-div-bg:#000;
    --text-div-bg-light:#F5F7FF;
    --checkbox-div-bg:#F5F7FF;
    --text-div-color:#fff;
    --text-div-bg-hover:#F8FAFD;
    --card-list-text:#52667E;
    --success-color:#00843b;
}