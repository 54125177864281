/*
====================================
extra Screen - Others
====================================
*/
@media screen and (min-width: 1401px) {







}

/*
====================================
Large Screen - Others
====================================
*/
@media screen and (min-width: 1200px) and (max-width: 1400px) {

/*********  dashboard-quickstart PAGE START HERE *************/
.quickstart-timeline-area {
    gap: 40px;
}
.quickstart-timeline-left ul li a {
    width: 40px;
    height: 40px;
}
.quickstart-timeline-left ul li a img {
    max-width: 20px;
}
.quickstart-timeline-left ul li::after {
    width: 16px;
    height: 16px;
    left: 94%;
    top: 40%;
}
.quickstart-timeline-right > ul li a {
    width: 340px;
    padding: 14px 0;
    display: block;
    text-align: center;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
}
.quickstart-timeline-right {
    padding-right: 40px;
}


/********* Dashboard-NewUser PAGE START HERE  *************/
.dashboard-newuser-area .newuser-cash-content {
    display: flex;
    gap: 20px;
    padding: 20px;
    width: 33.33%;
    border-radius: 20px;
}
.dashboard-newuser-area .newuser-cash-content > div span {
    font-size: 14px;
    font-weight: 600;
}
.dashboard-newuser-area .newuser-cash-content > div h5 {
    font-size: 20px;
    margin-top: 2px;
}
.dashboard-newuser-area .newuser-cash-content > button {
    width: 50px;
    min-height: 50px;
    border-radius: 15px;
    cursor: inherit;
}
.dashboard-newuser-area .newuser-cash-content > button img {
    max-width: 30px;
}
.bot-create-area {
    margin-top: 30px;
}

.rc-footer-row {
    flex-direction: column;
    gap: 15px;
}
.rc-footer-row .col-xl-7 {
    width: 100%;
}
.rc-footer-row .col-xl-5 {
    width: 100%;
}
.reports-chart-area .rc-footer-left {
    justify-content: center;
}
.rc-footer-right {
    justify-content: center;
}

.settings-subscription-area .newuser-earning-area {
    gap: 10px;
}

.settings-subscription-area .newuser-cash-content > div h5 {
    font-size: 15px;
}













}



/*
====================================
Medium Screen - Others
====================================
*/
@media screen and (min-width: 992px) and (max-width: 1199px) {



/**********DASHBOARD-BASE START HERE *************/
 .main-right-area {
    padding: 15px;
 }
.main-left-col {
    -ms-flex: 0 0 230px;
    flex: 0 0 230px;
    max-width: 230px;
}
.main-right-col {
    -ms-flex: 0 0 calc(100% - 230px);
    flex: 0 0 calc(100% - 230px);
    max-width: calc(100% - 230px);
}


/**********dashboard-quickstart PAGE START HERE*********/
 .quickstart-timeline-left {
    width: 100%;
    max-width: 100px;
    padding: 60px 0;
}
.quickstart-timeline-area {
    gap: 20px;
}
.quickstart-timeline-left ul li {
    padding: 0 20px;
}
.quickstart-timeline-left ul li a {
    width: 40px;
    height: 40px;
}
.quickstart-timeline-left ul li a img {
    max-width: 20px;
}
.quickstart-timeline-left ul li::after {
    width: 12px;
    height: 12px;
    left: 90%;
    top: 40%;
}
.quickstart-timeline-right ul li a {
    width: 280px;
    padding: 14px 0;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.24px;
}
.quickstart-timeline-right ul {
    margin-top: 20px;
}
.quickstart-timeline-right h3 {
    font-size: 18px;
}
.quickstart-timeline-right p {
    font-size: 10px;
}
.quickstart-timeline-right {
    padding-right: 40px;
}



/********* Dashboard-NewUser PAGE START HERE  *************/

.dashboard-newuser-area .newuser-cash-content {
    display: flex;
    gap: 15px;
    padding: 15px;
    width: 33.33%;
    border-radius: 20px;
}
.dashboard-newuser-area .newuser-cash-content > div span {
    font-size: 12px;
    font-weight: 600;
}
.dashboard-newuser-area .newuser-cash-content > div h5 {
    font-size: 18px;
    margin-top: 2px;
}
.dashboard-newuser-area .newuser-cash-content > button {
    width: 50px;
    min-height: 50px;
    border-radius: 15px;
    cursor: inherit;
}
.dashboard-newuser-area .newuser-cash-content > button img {
    max-width: 30px;
}
.bot-create-area {
    margin-top: 20px;
}
.newuser-earning-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
}

.ba-asset-backtest-form {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.marketplace-detail-area .md-title h1 {
    font-size: 12px;
}
.marketplace-detail-area .md-title h1 a {
    margin-left: 10px;
    padding: 2px 15px;
    font-size: 10px;
}
.marketplace-detail-area .md-title label {
    font-size: 10px;
    gap:10px;
}
.marketplace-detail-area .mdc-para {
    padding: 15px;
}
.marketplace-detail-area .mdc-para p {
    font-size: 10px;
}
.marketplace-detail-area .mdc-performance {
    margin-top: 15px;
}
.marketplace-detail-area .mdc-performance-left h4 {
    font-size: 12px;
    line-height: 20px;
}
.marketplace-detail-area .mdc-performance-left h4 img:nth-child(1) {
    width: 15px;
    margin-right: 5px;
}
.marketplace-detail-area .mdc-performance-left ul {
    margin-top: 15px;
}
.marketplace-detail-area .mdc-performance-left ul li span {
    font-size: 10px;
}
.marketplace-detail-area .mdc-performance-left ul li h6 {
    font-size: 10px;
    margin-top: 0;
}
.marketplace-detail-area .mdc-performance-left h4 img:nth-child(2) {
    width: 10px;
}
.marketplace-detail-area .mdcp-btn button:nth-child(1) {
    width: 70px;
    height: 30px;
    font-size: 8px;
    margin-right: 5px;
}
.marketplace-detail-area .mdcp-btn button:nth-child(2) {
    width: 80px;
    height: 30px;
    font-size: 8px;
}

.marketplace-detail-area .mdc-performance-left {
    padding: 10px;
}


.rc-footer-row {
    flex-direction: column;
    gap: 15px;
}
.rc-footer-row .col-xl-7 {
    width: 100%;
}
.rc-footer-row .col-xl-5 {
    width: 100%;
}
.reports-chart-area .rc-footer-left {
    justify-content: center;
}
.rc-footer-right {
    justify-content: center;
}

.settings-subscription-area .newuser-earning-area {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
.newuser-earning-area .newuser-cash-content {
    width: calc(50% - 20px);
}

.spcc-2 {
    border-radius: 0 20px 0 0;
}
.spcc-4 {
    border-radius: 0px !important;
}

.subs-plan-op2-area .spcc-1 {
    border-radius: 20px 0 0 0 !important;
}
.subs-plan-op2-area .spcc-2 {
    border-radius: 0 20px 0 0 ;
}
.subs-plan-op2-area .spcc-3 {
    border-radius: 0 0 0 0px !important;
}
.subs-plan-op2-area .spcc-4 {
    border-radius: 0 0 20px  0 !important;
}

.brokerage-logos .name-div {
    width: 33.33%;
    height: 70px;
    border-radius: 10px;
}
.brokerage-logos .name-div img {
    //width: 120px;
}

.paperwallet-main-table .open-table-content {
    overflow-x: auto;
}
.paperwallet-table {
    width: 1200px !important;
}
.spw-headong-right {
    margin-top: 20px;
}
.paperwallet-table-content .pwtc-heading ul li h6 {
    font-size: 14px;
}
.paperwallet-table-content .pwtc-heading ul li span {
    font-size: 10px;
}
.paperwallet-table-content .pwtc-heading ul {
    gap: 10px;
}





}

/*
====================================
Small Screen - Tablate
====================================
*/
@media screen and (min-width: 768px) and (max-width: 991px) {

/**********************************************
    DASHBOARD-BASE START HERE 
 **********************************************/
 
.main-left-col {
    display: none;
}
.hamburger-icon {
    display: flex;
}
.main-right-area {
    padding: 0 15px;
}
.main-right-header {
    padding-top: 15px;
}
.mr-inner-right .mr-title h4 {
    display: none;
}
.cb-form-div {
    display: block;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.cb-form-div .ba-asset-left{
    margin-bottom: 20px;
}
.ba-asset-form-content.grid-params {
    max-width: 80%;
}

/**********************************************
    dashboard-quickstart PAGE START HERE 
 **********************************************/
 .quickstart-timeline-left {
    width: 100%;
    max-width: 100px;
    padding: 60px 0;
}
.quickstart-timeline-area {
    gap: 20px;
}
.quickstart-timeline-left ul li {
    padding: 0 20px;
}
.quickstart-timeline-left ul li a {
    width: 40px;
    height: 40px;
}
.quickstart-timeline-left ul li a img {
    max-width: 20px;
}
.quickstart-timeline-left ul li::after {
    width: 12px;
    height: 12px;
    left: 90%;
    top: 40%;
}
.quickstart-timeline-right ul li a {
    width: 280px;
    padding: 14px 0;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.24px;
}
.quickstart-timeline-right ul {
    margin-top: 20px;
}
.quickstart-timeline-right h3 {
    font-size: 18px;
}
.quickstart-timeline-right p {
    font-size: 10px;
}
.quickstart-timeline-right {
    padding-right: 40px;
}


/********* Dashboard-NewUser PAGE START HERE  *************/

.dashboard-newuser-area .newuser-cash-content {
    display: flex;
    gap: 15px;
    padding: 15px;
    width: 33.33%;
    border-radius: 20px;
}
.dashboard-newuser-area .newuser-cash-content > div span {
    font-size: 12px;
    font-weight: 600;
}
.dashboard-newuser-area .newuser-cash-content > div h5 {
    font-size: 18px;
    margin-top: 2px;
}
.dashboard-newuser-area .newuser-cash-content > button {
    width: 50px;
    min-height: 50px;
    border-radius: 15px;
    cursor: inherit;
}
.dashboard-newuser-area .newuser-cash-content > button img {
    max-width: 30px;
}
.bot-create-area {
    margin-top: 20px;
}
.newuser-earning-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
}

.backtest-btn button {
    margin-top: 15px;
}

.open-table-content {
    overflow-x: scroll;
}
.open-table-content table {
    width: 991px;
}
.position-tab-area .dna-pagination {
    margin-top: 15px;
}




.rc-footer-row {
    flex-direction: column;
    gap: 15px;
}
.rc-footer-row .col-xl-7 {
    width: 100%;
}
.rc-footer-row .col-xl-5 {
    width: 100%;
}
.reports-chart-area .rc-footer-left {
    justify-content: center;
}
.rc-footer-right {
    justify-content: center;
}
.ai-accrodion-area .accordion-item {
    margin-bottom: 15px;
}

.settings-subscription-area .newuser-earning-area {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
.newuser-earning-area .newuser-cash-content {
    width: calc(50% - 20px);
}

.spcc-2 {
    border-radius: 0 20px 0 0;
}
.spcc-4 {
    border-radius: 0px !important;
}


.subs-plan-op2-area .spcc-1 {
    border-radius: 20px 0 0 0 !important;
}
.subs-plan-op2-area .spcc-2 {
    border-radius: 0 20px 0 0 ;
}
.subs-plan-op2-area .spcc-3 {
    border-radius: 0 0 0 0 !important;
}
.subs-plan-op2-area .spcc-4 {
    border-radius: 0 0 20px  0 !important;
}

.brokerage-logos .name-div {
    width: 80%;
    height: 70px;
    border-radius: 10px;
}
.brokerage-logos .name-div img {
   // width: 10\0px;
}
.brokerage-logos {
    margin-bottom: 20px;
    gap: 20px;
}
.brokerage-footer p {
    font-size: 10px;
    margin-bottom: 0;
}
.brokerage-footer a {
    width: 160px;
    margin-top: 20px;
    padding: 12px 0;
}
.brokerage-footer span {
    font-size: 10px;
}

.pwtc-heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.spw-headong-right {
    margin-top: 20px;
}
.paperwallet-table-content .pwtc-heading ul li h6 {
    font-size: 14px;
}
.paperwallet-table-content .pwtc-heading ul li span {
    font-size: 10px;
}
.paperwallet-table-content .pwtc-heading ul {
    gap: 10px;
}








}

/*
====================================
Small Screen - Mobile
====================================
*/
@media screen and (min-width: 576px) and (max-width: 767px) {	

/**********************************************
    DASHBOARD-BASE START HERE 
 **********************************************/
 
 .main-left-col {
    display: none;
}
.hamburger-icon {
    display: flex;
}
.main-right-area {
    padding: 0 15px;
}
.main-right-header {
    padding-top: 15px;
}
.mr-inner-right .mr-title h4 {
    display: none;
}
.ba-asset-form-content.grid-params {
    max-width: 90%;
}


/**********************************************
    dashboard-quickstart PAGE START HERE 
 **********************************************/
 .quickstart-timeline-left {
    width: 100%;
    max-width: 100px;
    padding: 60px 0;
}
.quickstart-timeline-area {
    gap: 20px;
}
.quickstart-timeline-left ul li {
    padding: 0 20px;
}
.quickstart-timeline-left ul li a {
    width: 40px;
    height: 40px;
}
.quickstart-timeline-left ul li a img {
    max-width: 20px;
}
.quickstart-timeline-left ul li::after {
    width: 12px;
    height: 12px;
    left: 90%;
    top: 40%;
}
.quickstart-timeline-right ul li a {
    width: 200px;
    padding: 12px 0;
    border-radius: 10px;
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 0.24px;
}
.quickstart-timeline-right ul {
    margin-top: 20px;
}
.quickstart-timeline-right h3 {
    font-size: 16px;
}
.quickstart-timeline-right p {
    font-size: 8px;
}
.quickstart-timeline-right {
    padding-right: 40px;
}



/********* Dashboard-NewUser PAGE START HERE  *************/
.dashboard-newuser-area .newuser-cash-content {
    display: flex;
    gap: 10px;
    padding: 10px;
    width: 33.33%;
    border-radius: 15px;
}
.dashboard-newuser-area .newuser-cash-content > div span {
    font-size: 10px;
    font-weight: 600;
}
.dashboard-newuser-area .newuser-cash-content > div h5 {
    font-size: 16px;
    margin-top: 2px;
}
.dashboard-newuser-area .newuser-cash-content > button {
    width: 40px;
    min-height: 40px;
    border-radius: 10px;
    cursor: inherit;
}
.dashboard-newuser-area .newuser-cash-content > button img {
    max-width: 20px;
}
.newuser-earning-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-top: 30px;
}
.bot-create-area {
    margin-top: 20px;
    width: 100%;
    min-height: 300px;
    border-radius: 10px;
    border: 1px dashed #BBB;
}
.bot-create-item h3 {
    font-size: 16px;
    margin-bottom: 0;
}
.bot-create-item p {
    font-size: 10px;
    margin-top: 5px;
} 
.bot-create-item button {
    width: 100%;
    max-width: 200px;
    padding: 12px 0;
    border-radius: 10px;
    font-size: 10px;
    margin-top: 20px;
}


/********* Dashboard-normal PAGE START HERE  *************/
.deshboard-normal-allBots-header {
    flex-direction: column;
    gap: 10px;
}

.dr-select {
    width: 250px !important;
}
.bdt-content {
    padding-bottom: 15px;
}

.backtest-btn button {
    margin-top: 15px;
}
.bot-advance-asset-tab ul.bc-step-icons {
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}
.bot-advance-asset-tab ul.bc-step-icons li label {
    width: 40px;
    height: 40px;
    //background-color: #fff;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 5px;
}
.ba-asset-form-content{
    width: 100%;
}
.bot-advance-asset-tab ul.bc-step-icons li label img {
    width: 20px;
}
.bot-advance-asset-tab ul.bc-step-icons li a {
    color: #BBB;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.bot-advance-asset-tab ul.bc-step-icons::after {
    content: "";
    display: block;
    position: absolute;
    top: 35%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
}

.open-table-content {
    overflow-x: scroll;
}
.open-table-content table {
    width: 991px;
}
.position-tab-area .dna-pagination {
    margin-top: 15px;
}

.ml-header-left {
    gap:5px;
}
.ml-header-left button:nth-child(1) {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    font-size: 8px;
    font-weight: 700;
}
.ml-header-left button:nth-child(2) {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    font-size: 8px;
    font-weight: 700;
}
.ml-header-right {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}
.ml-header-right .mr-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.ml-header-right .nice-select {
    border-radius: 5px;
    background: var(--select-bg,#fff);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    color: var(--select-text-color,#333);
    font-size: 8px;
    font-weight: 400;
    line-height: 30px;
    height: 30px;
    width: 70px;
}
.ml-header-dropdown .mlhd3 {
    width: 100px;
}



.rc-footer-row {
    flex-direction: column;
    gap: 15px;
}
.rc-footer-row .col-xl-7 {
    width: 100%;
}
.rc-footer-row .col-xl-5 {
    width: 100%;
}
.reports-chart-area .rc-footer-left {
    justify-content: center;
}
.rc-footer-right {
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
}
.reports-chart-area .rc-top a {
    width: 100px;
    padding: 10px 0;
    padding-left: 15px;
    font-size: 8px;
    padding-bottom: 5px;
}
.reports-chart-area .rc-top a h6 {
    font-size: 12px;
}
.ai-accrodion-area .accordion-item {
    margin-bottom: 15px;
}
.sgc-left h6 {
    margin-bottom: 5px;
    font-size: 10px;
}
.setting-general-content {
    margin-left: 15px;
    border-radius: 15px;
    padding: 20px 15px;
}
.setting-general-area .setting-nav-item ul li a {
    width: 120px;
    font-size: 8px;
    padding: 5px 10px;
}
.setting-general-area .setting-nav-item ul li a svg {
    margin-right: 0;
}

.setting-left-col {
    -ms-flex: 0 0 140px;
    flex: 0 0 140px ;
    max-width: 140px;
}
.setting-right-col {
    -ms-flex: 0 0 calc(100% - 140px);
    flex: 0 0 calc(100% - 140px);
    max-width: calc(100% - 140px);
}
.setting-general-content .sgc-right-checkbox input {
    width: 12px;
    height: 12px;
}

.sgc-range .form-check-input {
    width: 30px;
    height: 14px;
}

.settings-subscription-area .newuser-earning-area {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
.newuser-earning-area .newuser-cash-content {
    width: calc(100% - 20px);
}

.spcc-2 {
    border-radius: 0 20px 0 0;
}
.spcc-4 {
    border-radius: 0px !important;
}
.settings-subscription-area .subs-plan-header h4 {
    font-size: 14px;
}
.settings-subscription-area .subs-plan-header ul li a {
    width: 70px;
    padding: 8px 0;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border-radius: 5px;
    background: #E5E5E5;
    transition: 0.3s;
}




.subs-plan-op2-area .spcc-1 {
    border-radius: 20px 20px 0 0 !important;
}
.subs-plan-op2-area .spcc-2 {
    border-radius: 0 0 0 0 ;
}
.subs-plan-op2-area .spcc-3 {
    border-radius: 0 0 0 0 !important;
}
.subs-plan-op2-area .spcc-4 {
    border-radius: 0 0 20px  20px !important;
}


.brokerage-logos .name-div {
    width: 80%;
    height: 50px;
    border-radius: 5px;
}
.brokerage-logos .name-div img {
   // width: 30px;
}
.brokerage-logos {
    margin-bottom: 10px;
    //gap: 10px;
}
.brokerage-footer p {
    font-size: 8px;
    margin-bottom: 0;
}
.brokerage-footer a {
    width: 160px;
    margin-top: 10px;
    padding: 12px 0;
}
.brokerage-footer span {
    font-size: 8px;
    margin-top: 10px;
}
.setting-exchange-area {
    margin-left: 10px;
}
.brokerage-footer {
    margin-top: 20px;
}

.pwtc-heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.spw-headong-right {
    margin-top: 20px;
}
.paperwallet-table-content .pwtc-heading ul li h6 {
    font-size: 14px;
}
.paperwallet-table-content .pwtc-heading ul li span {
    font-size: 10px;
}
.paperwallet-table-content .pwtc-heading ul {
    gap: 10px;
}

.pwtc-heading > div label {
    font-size: 8px;
}
.pwtc-heading > div label>span {
    margin-right: 8px;
}
.cb-form-div {
    display: block;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.cb-form-div .ba-asset-left{
    margin-bottom: 20px;
}





}

/*
====================================
Xtra Small Screen - Small Mobile
====================================
*/
@media screen and (max-width: 576px) {

/**********************************************
    DASHBOARD-BASE START HERE 
 **********************************************/
 
.main-left-col {
    display: none;
}
.hamburger-icon {
    display: flex;
    top: 28px;
}
.main-right-area {
    padding: 0 10px;
}
.main-right-header {
    padding-top: 15px;
}
.mr-inner-right .mr-title h4 {
    display: none;
}
.mr-user-icon {
    width: 30px;
    min-height: 30px;
    border-radius: 5px;
}
.mr-user-icon i {
    font-size: 12px !important;
}


.cb-form-div {
    display: block;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.cb-form-div .ba-asset-left{
    margin-bottom: 20px;
}
.ba-asset-form-content.grid-params {
    max-width: 90%;
}




/**********************************************
            LOGIN PAGE START HERE 
 **********************************************/
 .login-content-wrapper {
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    padding: 55px 10px;
 }
 .login-content {
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    padding: 0 10px;
    margin-top: 10% !important;
}
.login-content > a img {
    max-width: 150px;
}
.login-content > h4 {
    font-size: 16px;
    margin: 20px 0;
}
.login-content > a {
    margin-top: 15px;
}
.login-language-picker-area {
    padding: 10px;
}


/**********************************************
            SIGNUP PAGE START HERE 
 **********************************************/
 .signup-checkbox {
    margin-left: 0;
    gap: 5px;
 }
 /**********************************************
            SIGNUP PAGE START HERE 
 **********************************************/
 .resetPassword-content {
    padding-bottom: 40px;
 }



/**********************************************
    dashboard-quickstart PAGE START HERE 
 **********************************************/
 .quickstart-timeline-left {
    width: 100%;
    max-width: 70px;
    padding: 40px 0;
}
.quickstart-timeline-area {
    gap: 20px;
}
.quickstart-timeline-left ul li {
    padding: 0 10px;
}
.quickstart-timeline-left ul li a {
    width: 30px;
    height: 30px;
}
.quickstart-timeline-left ul li a img {
    max-width: 10px;
}
.quickstart-timeline-left ul li::after {
    width: 10px;
    height: 10px;
    left: 88%;
    top: 40%;
}
.quickstart-timeline-right ul li a {
    width: 180px;
    padding: 12px 0;
    border-radius: 10px;
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 0.24px;
}
.quickstart-timeline-right ul {
    margin-top: 10px;
    display: block;
    margin-bottom: 10px;
}
.quickstart3-timeline-right span {
    font-size: 8px;
}
.quickstart3-timeline-right p:nth-child(6) {
    margin-top: 10px;
}
.quickstart3-timeline-right span b {
    font-size: 8px;
}
.quickstart-timeline-right h3 {
    font-size: 12px;
}
.quickstart-timeline-right p {
    font-size: 8px;
}
.quickstart-timeline-right {
    padding-top: 50px;
    padding-right: 20px;
}

.quickstart-timeline-right .qtrtrans-btn {
    width: 100%;
    margin-bottom: 0px;
}
.quickstart-timeline-right .qtrblue-btn {
    width: 100%;
}
.quickstart-timeline-right ul li {
    display: block;
    margin-bottom: 10px;
}

.quickstart-timeline-right form input {
    padding: 12px 15px;
    margin-top: 10px;
    font-size: 10px;
}
.quickstart-timeline-right form input::placeholder {
    font-size: 10px;
}
.quickstart3-timeline-right {
    width: 100%;
}
.quickstart-timeline-right ul {
    margin-bottom: 0;
}



/********* Dashboard-NewUser PAGE START HERE  *************/
.dashboard-newuser-area .newuser-cash-content {
    display: flex;
    gap: 5px;
    padding: 10px;
    width: 33.33%;
    border-radius: 12px;
}
.dashboard-newuser-area .newuser-cash-content > div span {
    font-size: 6px;
}
.dashboard-newuser-area .newuser-cash-content > div h5 {
    font-size: 10px;
    margin-top: 2px;
}
.dashboard-newuser-area .newuser-cash-content > button {
    width: 20px;
    min-height: 20px;
    border-radius: 5px;
    cursor: inherit;
}
.dashboard-newuser-area .newuser-cash-content > button img {
    max-width: 10px;
}
.newuser-earning-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    margin-top: 30px;
}
.bot-create-area {
    margin-top: 20px;
    width: 100%;
    min-height: 250px;
    border-radius: 10px;
    border: 1px dashed #BBB;
}
.bot-create-item h3 {
    font-size: 12px;
    margin-bottom: 0;
}
.bot-create-item p {
    font-size: 8px;
    margin-top: 5px;
} 
.bot-create-item button {
    width: 100%;
    max-width: 150px;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 8px;
    margin-top: 15px;
}



/********* Dashboard-normal PAGE START HERE  *************/
.deshboard-normal-allBots-header {
    flex-direction: column;
    gap: 10px;
}

.createBot-dropdown > button {
    border-radius: 5px;
    width: 80px;
    padding: 7px 0;
    font-size: 8px;
    height: 35px;
}
.deshboard-normal-allBots-header > h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
.deshboard-normal-allBots-header-right .mr-dropdown .mr-select {
    width: 80px;
    font-size: 8px;
    height: 30px;
    line-height: 32px;
    padding: 0 10px;
}
.deshboard-normal-allBots-header-right > label input {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background: var(--select-bg,#FFF);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
    outline: none;
    color: #333;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    padding-left: 8px;
    padding-right: 5px;
    margin-top: 2px;
}
.deshboard-normal-allBots-header-right > label div > button {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    border-radius: 8px;
}
.deshboard-normal-allBots-body .dna-dsc ul li {
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 8px;
    padding-left: 12px;
}
.deshboard-normal-allBots-body .dna-dsc {
    padding-bottom: 5px;
    border-radius: 8px;
}
.dna-cart-header ul li:nth-child(1) img {
    width: 20px;
}
.dna-cart-header ul li:nth-child(1) h6 {
    font-size: 10px;
}
.dna-cart-header > ul {
    align-items: center;
}
.dna-cart-body > h4 {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin: 15px 0;
    padding-left:12px;
}
.dna-cart-progress ul li {
    font-size: 8px;
    letter-spacing: 0.22px;
}
.dna-cart-progress ul li b {
    font-size: 8px;
}
.dna-cart-btc > div span {
    font-size: 8px;
}
.dna-cart-btc > div p {
    font-size: 8px;
}
.dna-footer  {
    flex-wrap: wrap;
    justify-content: center;
}
.dr-cart-footer {
    flex-direction: column;
    gap: 5px;
}
.dr-cart-footer a {
    width: 100%;
}

.dashboard-rented-cart-content {
    padding: 12px 15px 15px;
}
.dashboard-rented-cart-header > h5 {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.24px;
    padding-left: 20px;
}
.dashboard-rented-cart-body > div span {
    font-size: 8px;
}
.dashboard-rented-cart-body > div p {
    font-size: 8px;
}
.dashboard-rented-cart-body {
    margin-top: 10px;
}
.dr-cart-dropdown ul li a {
    font-size: 10px;
    letter-spacing: 0.22px;
    padding: 8px 0;
    padding-left: 20px;
}
.dr-select {
    width: 250px !important;
}

.bot-detail-header {
    flex-direction: column;
    margin: 15px 0;
}
.bot-detail-header > h4 {
    font-size: 14px;
    padding-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.bot-detail-chart-area {
    border-radius: 10px;
    background: var(--white, #FFF);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 15px;
    margin-top: 15px;
}
.bot-detail-table-area {
    margin-top: 30px;
}
.mr-inner-right  {
   margin-left: 60%;
}
.bot-detail-header > h4 {
    padding-left: 15px;
}
.bot-detail-header > h4::after {
    width: 8px;
    height: 8px;
}
.bot-detail-header-btn button:nth-child(1) {
    font-size: 8px;
    width: 120px;
    height: 30px;
}
.bot-detail-header-btn button:nth-child(2) {
    font-size: 8px;
    width: 120px;
    height: 30px;
}
.bot-detail-left-header > label {
    font-size: 13px;
    margin-bottom: 15px;
}
.bot-detail-left-header > label img {
    display: inline-block;
    margin-right: 5px;
    width: 15px;
}
.bot-detail-left-body .bdol-item h6 {
    font-size: 11px;
}
.bot-detail-left-body .bdol-item {
    margin-bottom: 15px;
}
.bot-detail-left-header > label span {
    font-size: 14px;
}
.bdt-header button:nth-child(1) {
    font-size: 8px;
    width: 120px;
    height: 30px;
}
.bdt-header button:nth-child(2) {
    font-size: 8px;
    width: 120px;
    height: 30px;
}

.bdt-content {
    margin-top: 12px;
    padding-bottom: 15px;
}
.bdt-header {
    justify-content: center;
}
.bdt-table-responsive {
    overflow-x: scroll;
}
.bdt-table-responsive table {
    width: 500px;
}
.bot-detail-content-area {
    overflow: hidden;
}

.bot-advance-asset-tab ul.bc-step-icons {
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.bot-advance-asset-tab ul.bc-step-icons li label {
    width: 40px;
    height: 40px;
    //background-color: #fff;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 5px;
}
.bot-advance-asset-tab ul.bc-step-icons li label img {
    width: 20px;
}
.bot-advance-asset-tab ul.bc-step-icons li a {
    color: #BBB;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.bot-advance-asset-tab ul.bc-step-icons::after {
    content: "";
    display: block;
    position: absolute;
    top: 35%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
}
.ba-asset-backtest-form {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    gap: 5px;
    display: block;
}
.backtest-btn button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    display: block;
    width: 100%;
}
.ba-asset-backtest-form label {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 10px;
}
.ba-asset-backtest-form label input {
    width: 100%;
    margin-top: 5px;
}
.ba-asset-middle-chart-item {
    height: 200px;
}
.ba-asset-form-area {
    padding: 10px;
}

.ba-summary-area {
    margin-top: 30px;
}

.bafc-2 .bas-asset-btn2 button {
    margin-top: 0px;
}
.bafc-2 .bas-asset-btn2 {
    padding-bottom: 15px;
    gap: 10px;
}
.order-limit-range .range {
    margin-top: 10px;
}
.bafc-2 .col-lg-8 {
    margin-top: 10px;
}

.bafc-2 .order-limit-range-txt p {
    font-size: 10px;
}
.bafc-2 .order-limit-range-txt span {
    font-size: 10px;
}

.ec-from {
    padding-bottom: 20px;
}
.bas-asset-btn2 {
    gap: 10px;
}

.open-table-content {
    overflow-x: scroll;
}
.open-table-content table {
    width: 800px;
}

.position-tab-area .skltbs-tab {
    border: 0;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 8px 15px;
    transition: 0.3s;
}

.position-tab-area .skltbs-tab-group {
    gap: 10px;
}

.position-tab-area {
   margin: 30px 0;
   overflow: hidden;
} 
.position-tab-header .deshboard-normal-allBots-header-right > label div > button {
    margin-top: 0;
}
.position-tab-area .dna-pagination {
    margin-top: 15px;
}
.pd-th-header-left h4 {
    text-align: center;
    font-size: 20px;
}
.pd-th-table-area {
    margin-top: 10px;
}
.pd-th-header-right {
    gap: 10px;
}
.pd-th-header-right label {
    gap: 5px;
}
.positions-detail-right-header {
    padding: 10px 10px;
    min-height: 300px;
}
.pd-left-header {
    min-height: 350px;
}
.ct-arrow2 {
    margin-left: 5px !important;
    width: 7px;
}

.pd-trade-history-area {
    overflow: hidden;
}

.ml-header-left {
    gap:5px;
    justify-content: center;
    margin-bottom: 10px;
}
.ml-header-left button:nth-child(1) {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    font-size: 8px;
    font-weight: 700;
}
.ml-header-left button:nth-child(2) {
    width: 80px;
    height: 30px;
    border-radius: 5px;
    font-size: 8px;
    font-weight: 700;
}
.ml-header-right {
    display: flex;
    justify-content: center;
    gap: 5px;
}
.ml-header-right .mr-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.ml-header-right .nice-select {
    border-radius: 5px;
    background: var(--select-bg,#fff);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    color: var(--select-text-color,#333);
    font-size: 8px;
    font-weight: 400;
    line-height: 30px;
    height: 30px;
    width: 70px;
}
.ml-header-dropdown .mlhd3 {
    width: 100px;
}

.mlc-range-content .mlc-range-item label h6 {
    font-size: 8px;
}
.mlc-range-content .mlc-range-item label span {
    font-size: 8px;
}
.marketplace-landing-cart-content .mlc-footer a {
    font-size: 8px;
    padding: 2px 7px;
}
.marketplace-landing-cart-content .mlc-footer label {
    font-size: 8px;
    gap: 3px;
}
.marketplace-landing-cart-content .mlc-footer label i {
    margin-top: 0;
    margin-bottom: 2px;
}
.marketplace-landing-cart-content .mlc-body {
    padding: 0 10px;
}
.marketplace-landing-cart-content .mlc-range-content {
    margin-bottom: 10px;
    gap: 10px;
}


.marketplace-detail-area .md-title h1 {
    font-size: 10px;
}
.marketplace-detail-area .md-title h1 a {
    margin-left: 10px;
    padding: 2px 15px;
    font-size: 8px;
}
.marketplace-detail-area .md-title label {
    font-size: 8px;
    gap:10px;
}
.marketplace-detail-area .md-content .mdcl-bottom label {
    font-size: 8px;
}
.marketplace-detail-area .mdc-para {
    padding: 15px;
}
.marketplace-detail-area .mdc-para p {
    font-size: 10px;
}
.marketplace-detail-area .mdc-performance {
    margin-top: 15px;
}
.marketplace-detail-area .mdc-performance-left h4 {
    font-size: 12px;
    line-height: 20px;
}
.marketplace-detail-area .mdc-performance-left h4 img:nth-child(1) {
    width: 15px;
    margin-right: 5px;
}
.marketplace-detail-area .mdc-performance-left ul {
    margin-top: 15px;
}
.marketplace-detail-area .mdc-performance-left ul li span {
    font-size: 10px;
}
.marketplace-detail-area .mdc-performance-left ul li h6 {
    font-size: 10px;
    margin-top: 0;
}
.marketplace-detail-area .mdc-performance-left h4 img:nth-child(2) {
    width: 10px;
}
.marketplace-detail-area .mdcp-btn button:nth-child(1) {
    width: 70px;
    height: 30px;
    font-size: 8px;
    margin-right: 5px;
}
.marketplace-detail-area .mdcp-btn button:nth-child(2) {
    width: 80px;
    height: 30px;
    font-size: 8px;
}

.marketplace-detail-area .mdc-performance-left {
    padding: 10px;
    min-height: 200px;
}
.marketplace-detail-area .md-content .mdcl-bottom {
    margin-top: 10px;
}
.mdc-trade-stock {
    margin: 10px 0;
}
.md-rent-bot form label {
    font-size: 10px;
    display: flex;
    flex-wrap: wrap;
}
.mdc-trade-stock {
    padding: 10px;
}
.mdcts-dropdown label > span {
    width: 15px;
    height: 15px;
}
.mdc-trade-stock h4 {
    font-size: 12px;
}
.mdc-trade-stock h4 img {
    margin-right: 8px;
}

.mdc-trade-stock ul {
    margin-top: 15px;
}

.md-rent-bot form label {
    margin-top: 20px;
}
.md-rent-bot form label a {
    margin: 0 5px;
}
.md-rent-bot form > button {
    font-size: 10px;
    padding: 12px 0;
    margin-top: 10px;
    margin-bottom: 10px;
}
.mp-pay-header h6 {
    font-size: 10px;
}
.mp-pay-header h6 img {
    margin-right: 2px;
    width: 15px;
}
.mp-pay-header > ul li a img {
    width: 25px;
}
.mp-bot-name ul li h6 {
    font-size: 8px !important;
}
.mp-pay-form form input {
    padding: 8px 10px;
    font-size: 10px;
    border-radius: 5px;
}
.mp-pay-form form input::placeholder {
    font-size: 10px;
}
.mp-pay-form form > button {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.24px;
    display: block;
    padding: 10px 0;
    border-radius: 10px;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    z-index: 5;
    border-radius: 5px;
}
.mp-pay-form form > label {
    margin-top: 10px;
    font-size: 9px;
}
.md-content-left > img {
    max-width: 100%;
}




.rc-footer-row {
    flex-direction: column;
    gap: 15px;
}
.rc-footer-row .col-xl-7 {
    width: 100%;
}
.rc-footer-row .col-xl-5 {
    width: 100%;
}
.reports-chart-area .rc-footer-left {
    justify-content: center;
}
.rc-footer-right {
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
}




.reports-chart-area .rc-top a {
    width: 80px;
    padding: 8px 0;
    padding-left: 0;
    font-size: 7px;
    padding-bottom: 5px;
}
.reports-chart-area .rc-top a h6 {
    font-size: 9px;
    text-align: center;
}
.reports-chart-area .rc-top a span {
    display: block;
    text-align: center;
}
.rc-footer-right > h6 {
    font-size: 12px;
}
.reports-chart-content .rc-header h4 {
    font-size: 12px;
}
.reports-chart-content .rc-header h4 img {
    margin-right: 5px;
    width: 15px;
}
.rc-detail-content .rc-detail-item span {
    font-size: 8px;
}
.rc-detail-content .rc-detail-item h6 {
    font-size: 10px;
}
.rc-detail-item {
    margin-top: 20px;
}
.your-query-area .query-input {
    flex-direction: column;
    align-items: flex-start;
}
.your-query-area > h4 {
    margin-bottom: 15px;
}
.your-query-area .query-input input {
    padding: 10px;
}
.your-query-area {
    padding: 15px;
}
.your-query-area .query-input button {
    border-radius: 10px;
    font-size: 10px;
    padding: 10px 30px;
}
.your-query-area .query-trending h6 {
    margin-top: 20px;
}
.your-query-area .query-tag a {
    font-size: 8px;
    padding: 3px;
    width: 50px;
}
.query-ans-area .query-ans-btn button {
    width: 100px;
    padding: 9px 0;
    border-radius: 10px;
    border: 0;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.22px;
    z-index: 5;
    transition: 0.5s;
}
.query-ans-area .query-ans-btn {
    gap: 10px;
}
.query-ans-area {
    margin: 15px 0;
}
.ai-accrodion-area .accordion-button > div span {
    font-size: 8px;
}
.ai-accrodion-area .accordion-button > div h4 {
    font-size: 10px;
}
.accordion-button::after {
    background-size: 15px;
}
.ai-accrodion-area .accordion-button {
    padding: 10px;
}
.ai-accrodion-area .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 10px;
}
.ai-accrodion-area .accordion-item {
    margin-bottom: 10px;
}
.ai-accordion-item-wrapper > img {
    margin-top: 15px;
    margin-right: 10px;
    width: 15px;
}
.ai-accrodion-area .accordion-body {
    font-size: 8px;
    padding-left: 40px;
}


.sgc-left h6 {
    margin-bottom: 5px;
    font-size: 10px;
}
.setting-general-content {
    margin-left: 15px;
    border-radius: 15px;
    padding: 20px 15px;
}
.setting-general-area .setting-nav-item ul li a {
    width: 100%;
    font-size: 8px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}
.setting-general-area .setting-nav-item ul li a svg {
    margin-right: 0;
}

.setting-left-col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100% ;
    max-width: 100%;
}
.setting-right-col {
    -ms-flex: 0 0 calc(100% - 0px);
    flex: 0 0 calc(100% - 0px);
    max-width: calc(100% - 0px);
}
.setting-general-content .sgc-right-checkbox input {
    width: 12px;
    height: 12px;
}

.sgc-range .form-check-input {
    width: 30px;
    height: 14px;
}

.setting-general-area > .row {
    flex-direction: column;
}
.setting-general-content {
    margin-left: 0;
}

.setting-general-content .nice-select .list {
    width: 100%;
}


.settings-subscription-area .newuser-earning-area {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
}
.newuser-earning-area .newuser-cash-content {
    width: 30%;
}

.spcc-2 {
    border-radius: 0 20px 0 0;
}
.spcc-4 {
    border-radius: 0px !important;
}
.settings-subscription-area .subs-plan-header h4 {
    font-size: 14px;
}
.settings-subscription-area .subs-plan-header ul li a {
    width: 60px;
    padding: 8px 0;
    font-size: 8px;
    font-weight: 400;
    border-radius: 5px;
}
.settings-subscription-area .subs-plan-header ul {
    gap: 5px;
}
.settings-subscription-area {
    margin-left: 0;
}

.settings-subscription-area .payment-details-btn button {
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    border: 0;
    color: #FFF;
    text-align: center;
    font-size: 10px;
    padding: 8px 0;
}
.subs-history-table-area {
    margin-top: 30px;
}




.subs-plan-op2-area .spcc-1 {
    border-radius: 20px 20px 0 0 !important;
}
.subs-plan-op2-area .spcc-2 {
    border-radius: 0 0 0 0 ;
}
.subs-plan-op2-area .spcc-3 {
    border-radius: 0 0 0 0 !important;
}
.subs-plan-op2-area .spcc-4 {
    border-radius: 0 0 20px  20px !important;
}



.brokerage-logos .name-div {
    width: 80%;
    height: 50px;
    border-radius: 5px;
}
.brokerage-logos .name-div img {
    //width: 30px;
}
.brokerage-logos {
    margin-bottom: 10px;
    //gap: 10px;
}
.brokerage-footer p {
    font-size: 8px;
    margin-bottom: 0;
}
.brokerage-footer a {
    width: 160px;
    margin-top: 10px;
    padding: 12px 0;
    font-size: 10px;
}
.brokerage-footer span {
    font-size: 8px;
    margin-top: 10px;
}
.setting-exchange-area {
    margin-left: 0;
}
.brokerage-footer {
    margin-top: 20px;
}



.pwtc-heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.spw-headong-right {
    margin-top: 20px;
}
.paperwallet-table-content .pwtc-heading ul li h6 {
    font-size: 14px;
}
.paperwallet-table-content .pwtc-heading ul li span {
    font-size: 10px;
}
.paperwallet-table-content .pwtc-heading ul {
    gap: 10px;
}

.pwtc-heading > div label {
    font-size: 8px;
}
.pwtc-heading > div label>span {
    margin-right: 8px;
}
.settings-PaperWallet-area {
    margin: 0 10px;
}

.spw-headong-left label h6 {
    font-size: 12px;
}
.spw-headong-left {
    gap: 15px;
}
.settings-PaperWallet-heading .spw-headong-left button {
    width: 50px;
    height: 50px;
}
.spw-headong-right {
    gap: 5px;
    justify-content: space-between;
}
.spw-headong-right button {
    width: 33%;
    padding: 10px 0;
}
.paperwallet-table-area .paperwallet-btn {
    gap: 5px;
}
.paperwallet-table-content {
    margin-top: 10px;
}
.signup-checkbox {
    margin-left: 0px;
}
.dre-dropwdown .mr-dropdown .mr-select {
    width: 140px;
}


.above-buy-price2 {
    flex-direction: column;
    align-items: flex-start;
}

.above-buy-price {
    flex-direction: column;
    align-items: flex-start;
}













}