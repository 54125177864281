.affiliate {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14;
}

.affiliate .top-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: normal;
}

.affiliate .title {
  font-size: 48px;
  color: #002255;
  font-style: italic;
  font-weight: bold;
  line-height: normal;
}

.affiliate .top {
  background-image: url(/images/bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 200px;
  width: 100%;
  height: 700px;
  padding-left: 140px;
  padding-right: 100px;
  padding-top: 70px;
  display: flex;
}

.affiliate .top1 {
  flex: 2;
}

.affiliate .top2 {
  flex: 1;
  padding-left: 30px;
}

.affiliate .top img {
  margin-top: -80px;
}

.affiliate .top ul {
  list-style-type: inherit;
  margin-left: 60px;
  font-weight: unset;
}

.affiliate .ambassador {
  padding: 80px 140px;
  color: black;
}

.affiliate .ambassador .frag1 {
  width: 50%;
}

.affiliate .ambassador .frag2 {
  font-weight: bold;
}

.affiliate .ambassador .frag3 {
  width: fit-content;
  padding: 20px 30px;
  font-weight: bold;
  background-color: #c7ebf360;
}

.affiliate .ambassador .faq-btn {
  background-color: #73cee2;
  padding: 13px 30px;
  width: fit-content;
  border-radius: 22px;
  height: 44px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.affiliate .ambassador .faq-btn a {
  color: white;
  font-weight: bold;
}

.affiliate .faq {
  width: 100%;
  min-height: 400px;
  padding: 50px 140px;
  background-image: url(/images/bg1.png);
  background-repeat: no-repeat;
  background-size: 1600px 900px;
  background-position: -1030px 140px;
  font-size: 14px;
}

.affiliate .faq .title {
  margin-bottom: 40px;
}

.affiliate .faq .question {
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
  margin-top: 20px;
}

@media only screen and (max-width: 1200px) {
  .affiliate .top {
    background-image: url(/images/bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 200px;
    width: 100%;
    height: 600px;
    padding-left: 140px;
    padding-right: 140px;
    padding-top: 30px;
  }

  .affiliate .top2 {
    display: none;
  }
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .affiliate .top {
    background-image: none;
    width: 100%;
    height: 550px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
  }

  .affiliate .top2 {
    display: none;
  }

  .affiliate .ambassador {
    padding: 50px 20px;
  }

  .affiliate .faq {
    padding: 0px 20px;
  }

  .affiliate .title {
    font-size: 36px;
  }

  .affiliate .top-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
