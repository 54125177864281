.nav-link {
  color: #002352 !important;
  font-size: 16px !important;
  margin-left: 50px !important;
  font-weight: bold !important;
}

.nav-container {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 80px;
  padding-right: 80px;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .nav-container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgb(231, 230, 230);
  }
}

@media only screen and (min-height: 1440px) {
  .nav-container {
    padding-left: 15%;
    padding-right: 15%;
  }

  .nav-link {
    font-size: 18px !important;
  }
}

@media only screen and (min-height: 2000px) {
  .nav-container {
    padding-left: 20%;
    padding-right: 20%;
  }

  .nav-link {
    font-size: 20px !important;
  }
}
