.verify-otp .verify-otp-col {
  max-width: 37% !important;
  margin-bottom: 100px;
}

.ch-button-otp {
  background-color: rgb(10, 173, 138) !important;
  border: none;
  border-radius: 1000px;
  height: 45px;
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .verify-otp .verify-otp-col {
    max-width: 100% !important;
  }
}

@media only screen and (min-height: 1440px) {
  .verify-otp {
    margin-top: 180px !important;
  }

  .verify-otp .verify-otp-col {
    max-width: 25% !important;
  }

  .verify-otp .top-margin {
    margin-top: 25px !important;
  }
}

@media only screen and (min-height: 2000px) {
  .verify-otp {
    margin-top: 220px !important;
  }

  .verify-otp .verify-otp-col {
    max-width: 22% !important;
  }

  .verify-otp .card-body {
    padding: 50px !important;
  }

  .verify-otp .top-margin {
    margin-top: 30px !important;
  }
}
