$theme-colors: (
  //"primary": #9381ff,
  "success": #05cd99,
  "danger": #ee7874,
);


html, body,#root{
	height: 100%;
	background: var(--theme-background-color);
}

body {
	font-family: 'Public Sans', sans-serif;
	color:var(--theme-body-color);
	font-size:15px;
	font-weight: 300;
	background-color: var(--theme-background-color);
}
.pos-relative{
	position: relative;
}
.pos-absolute{
	position: absolute;
}
li a{
	color: var(--999, #999);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (min-width:320px)  { 
  /* smartphones, Android phones, landscape iPhone */ 
 /* ------------------------------------------------------------------
General Styles
------------------------------------------------------------------- */



p {line-height:25px; margin:0;}

h1 {font-size:28px; padding:0; margin:0; line-height:1; font-weight: 700;}
h2 {font-family: 'Public Sans';
	font-style: normal;
	font-weight: 300;
	font-size: 21px;
	line-height: 25px;}
h3 {font-size:16px; padding:0; margin:0; line-height:1.2; font-weight: 400; color: var(--heading-color);}

.small-text {font-size:13px; font-weight: 400; letter-spacing: 1px;}
.smallest-text {font-size:10px; font-weight: 400; letter-spacing: 1px;}

.bold-text {font-weight: 700;}
.light-text {font-weight: 300;}
.black-text {color:var(--table-font-color);}
.grey-text {color:#999;}
.green-text {color:var(--success-color);;}
.red-text {color:#F1693A;}

.v-pad {padding-top:15px; padding-bottom:15px;}

.btn {
	//width: 400px;
	height: 50px;
	padding-top: 15px;
	text-align: center;
	color:var(--chart-bg);
	font-size: 13px;
	letter-spacing: 1px;
	background: #fff;
	border-radius: 25px;
	border:1px solid var(--chart-bg);
	padding: 10px 40px;
}

.btn:hover {
	background: var(--chart-bg);
	color:#fff;
}

.full-btn {width:100%;}

.blue-btn {
	background: var(--chart-bg);
	color:#fff;
}

.blue-btn:hover {
	color:var(--chart-bg);
	background: #fff;
}

a {color:inherit;}
a:focus {text-decoration:none; outline: none !important;}

img {max-width:100%;}

footer {
	margin-top: 50px;
	border-top:1px solid #ddd;
	padding:15px 25px;
}

.footer-left {
	float:left;
}

.footer-logo {
	width:150px;
	height: 61px;
	float: left;
}

.footer-divider {
	width: 1px;
	height: 50px;
	background:#ddd;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 5px;
	float: left;
}

.footer-slogan {
	float: left;
	margin-top: 20px;
	color: var(--dropdown-item-color);
}

.copyright {
	float:right;
	font-size: 12px;
	color: var(--dropdown-item-color);
	margin-top: 20px;
}
.form-input{
	background: #F5F5F5;
	/* Drop Shadow Textfield */

	//box-shadow: 0px 10px 30px 3px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	text-align: left;
	text-transform: none;
	line-height:140%;  
	border:none;
	letter-spacing: 1px;
	color:#333;
	
}
// .{
// 	background: #f5f5f5;
// 	line-height:140%;  
// 	border:none;
// 	border-radius:5px;
// 	height:50px;	
// 	width:100%;
// 	padding:6px 12px;
// 	color:#333;
// 	margin-top: 5px;
// 	text-align: center;
// 	//text-transform: uppercase;
// 	//font-size: 10px;
// 	letter-spacing: 1px;
// }
.form-input:focus{
	background: var(--select-bg,#fff);
	box-shadow: 0px 10px 30px 3px rgba(0, 0, 0, 0.05);
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #333;
	opacity: 1; /* Firefox */
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	font-family: 'Public Sans';
	text-transform: capitalize;
	text-align: left;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #333;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: #333;
  }

/** Praveen css **/
.input-icon{
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
}
.password-input{
	padding-left:60px;
}
.input-with-icon{
	padding-left: 60px;
}
.link-text{
	color: var(--chart-bg);
}
.capitalize{
	text-transform: uppercase;
}
.social-login-div{
	padding: 10px 34%;
}
.social-circle{
	border: 1px solid #DDDDDD;
	border-radius: 25px;
	width: 40px;
	height: 40px;
	text-align: center;
	display: flex;
    justify-content: center;
    align-items: center;
	cursor: pointer;
}
.password-bg{
	background: #F5F5F5;
}
.auth-input{
	height: 50px;
}
.auth-logo{
	width: 200px;
}
/* Hide the default checkbox */
.custom-checkbox {
	display: none;
  }
  
  /* Style the label to create a custom checkbox */
  .checkbox-label {
	display: inline-block;
	position: relative;
	padding-left: 30px; /* Adjust the size of the checkbox */
	cursor: pointer;
  }
  
  /* Create the checkbox design */
  .checkbox-label:before {
	content: "";
	display: inline-block;
	position: absolute;
	left: 0;
	top: 0;
	width: 20px; /* Adjust the size of the checkbox */
	height: 20px; /* Adjust the size of the checkbox */
	//border: 1px solid #000;
	background-color: var(--checkbox-color);
	border-radius: 5px; /* Make the checkbox rounded */
  }
  
  /* Style the checkbox when checked */
  .custom-checkbox:checked + .checkbox-label:before {
	background-color:var(--blue-0064-fe, #0064FE);
  }
  
  /* Hide the default checkbox checkmark */
  .custom-checkbox:checked + .checkbox-label:after {
	content: "";
	display: block;
	position: absolute;
	top: 6px; /* Adjust the position of the checkmark */
	left: 7px; /* Adjust the position of the checkmark */
	width: 4px; /* Adjust the size of the checkmark */
	height: 8px; /* Adjust the size of the checkmark */
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
  }
  
  .bot-tabs{
	display: inline-flex;
    justify-content: space-around;
    margin-top: 22px;
  }
  .list-tab{
	padding: 11px 30px;
    border: 1px solid #BBBBBB;;
    width: 100%;
    margin-right: 10px;
    border-radius: 25px;
    text-align: center;
	color: var(--light-text);
	cursor: pointer;
	max-height: 50px;
	min-width: 162px;
  }
  .list-tab.tab-active{
	color:var(--tab-active);
	border: 1px solid var(--tab-active);;
  }
  .action-list{
	display: flex;
	justify-content: start;
	width: 175px;
	padding-left: 0;
	align-items: center;
  }
  .action-list li{
	list-style: none;
	margin-right: 10px;
  }
  .action-icon-link img{
	width: 30px;
  }
  .bot-icon-list{
	width: 20px;
	margin-right: 10px;
  }
  
  .mt-20{
	margin-top: 20px;
  }
  .deals-filter-btn .btn{
	height: 40px;
    width: 120px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 30px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
	border: none;
	color: var(--table-font-color);
	margin-right: 10px;
	padding: 10px;
  }
  .report-select-btn .btn{
	height: 46px;
    width: 164px;
    margin-left: 25px;
  }
  .deals-filter-btn .btn:hover,.deals-filter-btn .btn:active,.deals-filter-btn .btn:focus,.deals-filter-btn .btn:focus-visible{
	background: var(--theme-background-second);;
	color: var(--table-font-color);
	border: none;
  }
  .dropdown-filter-btn .btn{
	height: 40px;
    width: 100%;
    background: var(--theme-background-second);;
    box-shadow: 0px 10px 30px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
	border: none;
	color:var(--table-font-color);
	margin-right: 15px;
  }
  .dropdown-filter-btn{
	margin-right: 10px;
  }
  .dropdown-filter-btn .btn:hover,.dropdown-filter-btn .btn:active,.dropdown-filter-btn .btn:focus,.dropdown-filter-btn .btn:focus-visible{
	background: var(--theme-background-second);;
	color: var(--table-font-color);;
	border: none;
  }


.portfolio-pie{
	width: 70%;
}
.portfolio-heading{
	margin-bottom: 10px;
	color: #fff;
}
.btn-link{
	background: var(--chart-bg);
    
    border-radius: 25px;
    color: #FFFFFF;
    text-decoration: none;
    line-height: 1.5;
    vertical-align: middle;
    display: inline-block;
    padding: 0.375rem 0.75rem;
    height: 40px;
    min-width: 125px;
}
.btn-link:hover{
	text-decoration: none;
	color: #FFFFFF;
	cursor: pointer;
	box-shadow: 0px 10px 30px 3px rgba(0, 0, 0, 0.05);
}
.btn-link:disabled, .btn-link.disabled{
	color: #fff;
	background-color: #6c757d;
}
.marketplace-card {
    border-radius: 6px;
    margin: 10px;
    min-height: 734px;
    padding: 10px;
    max-width: 400px;
    max-height:734px;
    overflow: hidden;
    min-width: 378px;
}
.marketplace-card.subscription {
    min-height: 570px;
    max-width: 400px;
    max-height: 450px;
}
.success{
	color: #05cd99;
}
.light-text{
	color: var(--999, #999);
}
.marketplace-container{
	display: flex;
	flex-wrap: wrap;
}
.flex-wrap{
	flex-wrap: wrap;
}
.marketplace-card-info{
	display: flex;
	justify-content: space-between;
}
.marketplace-card-content{
	overflow: hidden;
	border-radius: 25px;
}
.mp-label{
	color: var(--999, #999);
	/* Smallest */
	font-family: Public Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.5px;
}
.mp-label-value{
	color: var(--999, #333);
	/* Smallest */
	font-family: Public Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.5px;
}
.marketplace-card-info-container{
	padding: 20px 14px;
	background: var(--theme-background-second);
	min-height: 200px;
	color: var(--999, #333);
}
.mp-label .liked-icon,.red{
	color: #D33C6D;
}
.liked-icon{
	filter: invert(52%) sepia(60%) saturate(6620%) hue-rotate(318deg) brightness(87%) contrast(90%);
}
.mpd-left-coloum{
	padding: 0% 6%;
}
.template-detail-image{
	border-radius: 25px;
}
.card-white{
	background: var(--white, #FFF);
	border-radius: 20px;
	box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
	
	font-size: 14px;
	font-weight: normal;
}
.cw-title{
	padding-left: 5%;
	padding-top: 10px;
	font-weight: bold;
}
.card-white .cw-body{
	padding: 5%;
}
.card-white .cb-section-1{
	padding: 5%;
	padding-top: 5%;
}
.cw-body div{
	//margin-bottom: 10px;
}
.select-normal {
	-webkit-appearance: auto !important;
	border: 0;
	border-radius: 10px;
	background: var(--select-bg,#fff);
	box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
	color:var(--select-text-color,#333);
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.24px;
	height: 42px;
	padding: 6px 24px;
}
.small-btn{
	height: 35px;
	padding: 28px 19px;
	width: auto !important;
}
.small-select{
	width: 300px;
}
.small-text-box{
	width: 300px;
}
.w-10{
	width: 10%;
}
.w-20{
	width: 20%;
}
.marketplace-card-title{
	font-size: 14px;
	font-weight: 400;
	line-height: 20px
}
.create-bot-icon{
	background: #FFF;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 10px;
	margin-top: 27px;
}
.add-bot-icon{
	filter: var(--navbar-icon-color);
}
.price-alert-plus{
	padding-right: 120px;
}
.bot-type-list{
	background: var(--dropdown-bg);
	width: 230px;
	position: absolute;
	color: var(--light-text);
	padding: 20px 5px;
    border-radius: 16px;
	top: 93px;
    right: -11px;
	z-index: 10000;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.bot-type-list:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -9px;
    right: 25px;
}

ul.bot-list-ul {
    list-style: none;
    padding-left: 0px;
}
ul.bot-list-ul li {
    padding: 8px 7px 8px 18px;
	border-radius: 20px;

}
.bot-title{
	width: 300px;
	margin: 0 auto;
}
.margin-auto{
	margin: 0 auto;
}
.bot-name-text{
	font-size: 24px;
}
.bn-edit-icon{
	font-size: 24px;
}
.inline-flex{
	display: inline-flex;
}
.bn-tick{
	position: relative;
	top: 3px;
	color: var(--chart-bg);
}
.section-tabs-bar{
	margin-top: 2%;
	margin-bottom: 3%;
}
.cb-section-1{
	min-height: 350px;
}
.bot-title-div{
	min-height: 40px;
}
.link{
	color: var(--blue-0064-fe, #0064FE);
}
.bw-option-div{
	width: 35%;
}
.danger{
	color: var(--Orange, #F1693A);;
}
.bw-option-div span{
	cursor: pointer;
}
.cb-form-label{
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #999;
}
.form-control.input-text{
	border-radius: 5px;
	background: var(--theme-background-color, #F5F5F5);
	border: none;
	height: 40px;
}
.form-control.input-text-area{
	border-radius: 5px;
	background: var(--theme-background-color, #F5F5F5);
	border: none;
}
.indicator-setting input {
	//margin-left: 20px;
	margin-top: 10px;
	border-radius: 5px;
	background: var(--theme-background-color, #F5F5F5);
	border: none;
	height: 40px;
	color:var(--999);
  }
.input-gp-label, .input-gp-text{
	-webkit-appearance: none !important;
  	margin: 0;
	position: relative;
	display: block;
	color:var(--999);
}
.input-gp-label::after {
    content: attr(data-text);
	position: absolute;
	top: 12px;
	right: 33px;
	font-family: arial, helvetica, sans-serif;
	font-size: 15px;
	display: block;
	color: var(--999, #999);
	font-weight: bold;
}
.dca.input-gp-label::after {
	top: 8px;
    right:  30px;
    font-size: 14px !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.bot-type-li{
	cursor: pointer;
}
.bot-name:hover{
	color:var(--table-font-color) !important;
	//opacity: 50%;
}
.add-ind-button{
	text-transform: uppercase;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	height: 40px;
	color: var(--chart-bg);
}
.tv-switch{
	top: 25px;
}
.radiobtn-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radiobtn-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
  }
/* Create a custom radio button */
.checkmark-radio {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #eee;
	border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radiobtn-container:hover input ~ .checkmark-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radiobtn-container input:checked ~ .checkmark-radio {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiobtn-container input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radiobtn-container .checkmark-radio:after {
	top: 7px;
	left: 7px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: white;
}
.dca-sm-select{
	height: 22px;
    padding-left: 12px;
    margin-left: 7px;
}
.dca-buydown-text{
	width: 150px;
	height: 30px !important;
	margin-left: 20px;
}
.dca-subseq-text{
	width: 80px !important;
	height: 30px !important;
	margin-left: 20px;
	margin-right: 20px;
}
.dca-subseq-text.small{
	width: 60px !important;
	height: 30px !important;
	margin-left: 5px;
	margin-right: 5px;
}
.small-text-box{
	width: 80px;
	height: 30px !important;
	margin-left: 10px;
	margin-right: 10px;
}
.tv-alert-url {
    color: var(--tab-active);
    font-weight: 700;
}
.save-cancel-btn{
	width: 250px;
	margin-top: 15px;
}
.tv-select-order{
	width: 350px;
}
.timeframe-btn-div{
	display: flex;
    justify-content: space-between;
    width: 338px;
    margin-left: 22px;
}
.bt-btn-active{
	padding: 12px;
    border: 1px solid;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bt-btn-inactive{
	padding: 12px;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cw-body.bp-div{
	padding: 0px;
}
.summary-chart{
	padding: 30px 100px;
}
.summary-th{
	border-bottom: 1px solid #F5F5F5;
}
.cw-body.bot-detail .form-row-div:not(:first-child){
	margin-top: 18px !important;
}
.card-white .cw-body.bot-detail{
	padding-top: 15px;
}
.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus{
	color: #000 !important;
}
.carousel-control-prev, .carousel-control-next{
color: #000 !important;
}
.carousel-indicators li{
	background-color: #000 !important;
}
.settings-menu{
	list-style: none;
	padding: 0;
}
.settings-menu .active{
	color: var(--chart-bg);
}
.settings-menu li{
	padding: 4%;
	padding-left: 29px;
	border-bottom: 1px solid var(--theme-background-color, #F5F5F5);
}
.card-white .cw-body.settings-menu-body{
	padding: 0;
}
.color-switch{
	width: 30px;
	display: block;
	/* border: 1px solid; */
	height: 30px;
	border-radius: 50%;
	border: 1px solid;
	display: inline-block;
	cursor: pointer;
	position: relative;
  }
  .theme-light{
	background-color: #007DFF;
	border: 1px solid #DBDBDB;
  }
  .theme-light.active{
	box-shadow: 0 0 0 0.25rem rgba(0, 125, 255,0.25) !important;
  }
  .theme-dark.active{
	box-shadow: 0 0 0 0.25rem rgba(241, 236, 236, 0.25);
  }
  .theme-pink.active{
	box-shadow: 0 0 0 0.25rem rgba(51, 51, 51, 0.25) !important;
  }
  .theme-green.active{
	box-shadow: 0 0 0 0.25rem rgba(32, 174, 148,0.25) !important
  }
  .theme-purple.active{
	box-shadow: 0 0 0 0.25rem rgba(32, 174, 148,0.25) !important
  }
  .theme-dark{
	background-color: #1E2025;
	border: 1px solid #2C3139;
  }
  .theme-pink{
	background-color: #AA113F;
	border:1px solid #FF828A
  }
  .theme-purple{
	background: #7A51EC;
	border: 1px solid #DDB3FF; 
  }
  .theme-green{
	background: #0B816C;
	border: 1px solid #ACD2A6;
  }
  .color-switch img{
	width: 20px;
	position: absolute;
	left: 4px;
	top: 4px;
  }
  .bot-name{
	color: var(--999, #999);
	font-family: Public Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
  }
  .bot-icon-list{
	width: 20px;
	height: 20px;
	filter: var(--navbar-icon-color)
  }
  .select-box button{
	height: 40px !important;
	border-radius: 2px !important;
	background: var(--theme-background-color, #F5F5F5) !important;
	color: #495057;
	border: none;
  }
  .color-theme-sel-div{
	width: 300px;
  }
  .settings-panel{
	margin-top: 25px !important;
  }
  .account-panel-card{
	height: 360px;
  }
  .card-white .cw-body.sub-myplan{
    padding: 0% 3%;
}
.no-pad{
	padding: 0 !important;
}
.premium-info{
	
}
.sub-col{
	min-height: 500px;
	border-right: 1px solid #DDD;
}
.sub-info-div .header-row{
	background: var(--chart-bg);
	color: #FFF;
}
.sub-plans-div{
	margin-left: 10px;
    background: var(--theme-background-second);
    border-radius: 25px;
    overflow: hidden;
	color: var(--999);
}
.sub-info-div .header-row{
	padding: 19px 0px;
}
.plan-price-text{
	font-size: 35px;
}
.promotion-text a{
	animation: blink 1s linear infinite;
	color:var(--button-success) !important;
	margin-top: 20px;
  }
  .promotion-text{
	margin-top: 20px;
  }
  .item-check{
	margin-right: 5px;
	color:var(--chart-bg);
  }
  .sub-features-info{
	text-align: left;
    padding-left: 83px;
    padding-top: 7%;
	background: var(--white, #FFF);
  }
  .sub-features-info .text-row{
	margin-top: 20px;
  }
  .sub-features-info{
	min-height: 375px;
  }
  .subscribe-btn{
	width: 240px;
  }
  .premium-info-div{
	background: var(--white);
    padding: 0 !important;
    margin: 0;
    border-radius: 25px;
    overflow: hidden;
  }
  .card-white .cw-body.addnewexchange-body,.card-white .cw-body.settings-paper-body{
	padding: 2%;
  }
  .paper-tab-currency{
	font-size: 20px;
	margin-top: 10px;
  }
  .small-btn{
	width: 200px;
  }
  .ml-10{
	margin-left: 10%;
  }
  .ml-15{
	margin-left: 15%;
  }
  .ml-20{
	margin-left: 20%;
  }
  .ml-25{
	margin-left: 25%;
  }
  .qs-bn{
	width: 350px;
  }
  .qs-tab-div{
	margin-top: 4%;
	padding: 10px 10%;
	
  }
  .qs-tab-des-div{
	min-height: 250px;
  }
  .qs-tab-button-div{
	margin-top: 30px;
  }
  .pr-20{
	padding-right: 20%;
  }
  .earnings-chart-div{
	padding: 2% 5%;
  }
  .apexcharts-xaxis-label:first-child {
	padding-left: 20px;
  }
  .personal-bal-div{
	padding: 0% 4%;
  }
  .grey-btn{
	background: var(--999, #999);
	color: #fff;
  }
  .jcb{
	justify-content: space-between;
  }
  .ac{
	align-items: center;
  }
  .w-200{
	width: 200px;
  }
  .template-action-icons{
	width: 22px;
	margin-top: 20px;
  }
  .template-action-div{
	padding: 10px 20px;
  }
  .bot-stat-div div{
	width: 50%;
	text-align: left;
  }
  .check-img{
	width: 20px;
	position: absolute;
	right: 23px;
	top: 6px;
  }
  .dropzone {
	text-align: center;
	padding: 20px;
	border: var(--dropzone-border-color);
	background-color: var(--dropzone-bg-color);
	color: var(--dropzone-color);
	margin-bottom: 20px;
	min-height: 300px;
	margin-top: 10%;
  }
  .dropzone .drag-upload-icon{
	width: 200px;
  }
  .badge-success {
	background-color: none !important;
	color: var(--badge-success-color) !important;
  }
  .secondary{
	color: var(--badge-secondary-color);
  }
  .danger{
	color: var(--badge-danger);
  }
  .form-control:focus{
	color: var(--999);
    background-color: var(--theme-background-color);
	outline: 0 !important;
	box-shadow: none !important;
	border-color: #e9ecef;
  }
  .input-range__track--active{
	background: var(--input-range-slider-color) !important;
  }
  .input-range__slider{
	background: var(--input-range-slider-color) !important;
	border: 1px solid var(--input-range-slider-color) !important;
  }
  .alert.alert-success{
	color: var(--alert-success-color);
	background-color: var(--alert-success-bg-color);
	border-color: var(--alert-success-border-color);
  }
  .modal-content{
	background-color: var(--theme-modal-bg-color);
  }
  .long,.BUY,.Long {
	color: var(--badge-success-color);
	background-color: #E9FAF4;
	
  }
  .dna-footer .long,.BUY,.Long {
	color: var(--badge-success-color);
	background-color: var(--switch-bg,#E9FAF4);
	
  }
  .dna-footer .short,.SELL,.Short {
	color: var(--badge-danger);
	background-color: var(--switch-bg,#FBEBF0);
	
  }
  
  .short,.SELL,.Short {
	color: var(--badge-danger);
	background-color: #FBEBF0;
  }
  .form-control-plaintext {
	font-size: 14px;
	font-weight: bold;
	color: var(--form-control-plaintext);
	outline: none;
  }
  .page-link{
    background-color: var(--theme-background-color);
	border: none !important;
	border-radius: 25px;
	min-width: 30px;
	min-height: 30px;
	color: var(--999);
  }
  .page-item.disabled .page-link,.page-link:focus,.page-link:hover{
	background-color: var(--theme-background-color);
	border: none !important;
  }
  .active .page-link{
	color: var(--table-font-color) !important;
	font-weight: bolder !important;
  }
  .pagination{
	margin-top: 20px;
  }
  .bg-info,a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus{
	background-color: transparent !important;
  }
  .page-link:focus{
	box-shadow: none;
  }
  .page-link:hover{
	color: var(--table-font-color) !important;
  }
.top-menu-li .inactive span{
	visibility: hidden;
}
.top-menu-li .inactive:hover span{
	visibility:visible
}
.back-button{
	width: 40px;
    position: absolute;
    height: 40px;
    text-align: center;
    align-items: center;
    display: flex;
    padding: 8px;
    border: 1px solid var(--chart-bg);
	color: var(--chart-bg);
    border-radius: 25px;
    font-size: 26px;
	cursor: pointer;
	z-index: 999;
}
.deals-page.back-button{
	position: unset;
	margin-right: 10px;
}
.rent-this-bot-div{
	padding: 0 20px;
}
.pointer{
	cursor: pointer;
}
.view-count-div{
	margin-right: 30px;
}
.w-80 {
    width: 80%;
}
.trending {
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 25px;
    margin-right: 10px;
    min-width: 70px;
    text-align: center;
    margin-top: 10px;
}
.ai-query-div {
    padding: 15px 11px;
}
.ai-query-submit {
    background:var(--theme-background-second);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding-left: 19px;
    padding-top: 13px;
}
.ai-answer-content-div {
    padding: 20px 5%;
}
pre {
    white-space: pre-wrap;
	font-size: 16px;
}
.w-30{
	width: 30%;
}
.w-70{
	width: 70%;
}
.progress {
    display: flex;
    height: 0.65rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 1.25rem;
}
.abl-top-right{
	position: absolute;
	top: 0;
	right: 0;
}
.abl-top-left{
	position: absolute;
	top: 0;
	left: 0;
}
.mp-card-details-div{
	padding-top: 25px;
}
.no-padding{
	padding: 0 !important;
}
.progress-icon{
	padding-left: 15px;
	padding-top: 10px;
}
.progress-bar-div{
	padding-left: 5px;
}
.like-icon{
	color: var(--text-color-down);
}
.progress-bar-div{
	width: 90%;
}
.trending-section-div{
	flex-wrap: wrap;
}
.card{
	background-color: var(--table-bg);
}
/*  End praveen css */
/* ------------------------------------------------------------------
Navigation
------------------------------------------------------------------- */

header {
	z-index:1000;
	width: 100%;
	transition:0.8s;
	background-color: var(--theme-background-color);
	height: 80px;
	padding-right: 30px;
	padding-left: 30px;
}

#logo {
	height: 51px;
	width: 40px;
	margin-right: 25px;
	float:left;
	margin-top: 10px;
}

.sidebar-logo {
	width: 150px;
	margin-top: 65px;
	margin-left: 20px;
	text-align: center;
}



ul#menu {
	margin:0px; padding:0px;
	box-sizing:border-box;
	transition:0.8s;
	float: left;
}

ul#menu li {
	list-style-type: none;
	text-align: center;
	display: inline;
}

ul#menu li a {
	transition:0.3s; 
	display: inline-block;
	color:#999;
	font-size: 13px;
	height: 80px;
	width: 90px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding-top: 15px;
	margin-left: 5px;
}

ul#menu li a:hover {
	color:var(--table-font-color);
	background: var(--theme-background-second)
}

ul#menu li a.active {
	color:var(--table-font-color);
	background: var(--theme-background-second)
}

ul#menu li a:hover img, ul#menu li a.active img {
	filter: var(--navbar-icon-color)
}

ul#menu li i {width:30px;}

.header-menu {
	float:right;
	margin-left: 10px;
}

.user-icon {
	width:40px;
	height: 40px;
	line-height: 40px;
	background: var(--chart-bg);
	font-size: 18px;
	color:#fff;
	border-radius: 20px;
	float:left;
	margin-right:7px;
	text-align: center;
}

.header-btn {
	background: var(--select-bg,#fff);
	font-size: 12px;
	color:var(--select-text-color,#333);
	height:45px;
	line-height: 45px;
	padding-left: 20px;
	padding-right: 20px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
	border-radius: 10px;
	font-weight: 600;
	min-width: 130px
}
.help-btn {
	background:var(--chart-bg);
	color:#FFF
}

.header-menu i {margin-top: 13px;}

.dropdown {
    position: relative;
    background: transparent;
}

.dropdown-content a {
	width: 100%;
	display: inline-block;
	padding-top: 20px;
	color: var(--999, #999);
}

.dropdown-content a:hover {
	color:var(--table-font-color);
}
.dropdown-list a:hover {
	color:var(--table-font-color) !important;
}


.dropdown-content {
    display: none;
    position: absolute;
    right:0px;
	z-index: 1;
	line-height: 25px;
	padding-top: 20px;
	z-index: 9999;
}

.dropdown-arrow {
	background: transparent;
    height: 15px;
    width: 188px;
    background-image: url(/img/arrow-white.png);
    background-position: right 6px;
    background-repeat: no-repeat;
}

.dropdown-list {
	background-color: var(--dropdown-bg);
	min-width: 180px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	padding:0 15px; 
	padding-bottom: 20px;
	border-radius: 20px;
	color: var(--999, #999);
}
.w-m-20{
	width: 20%;
}
.w-m-80{
	width: 80%;
}
.dropdown-list.exchanges span a{
	font-size: 14px !important;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown span {cursor:pointer;}

.dropdown-triangle {
	height: 7px;
	width: 14px;
	background: url(/img/triangle.png) no-repeat center;
	position: absolute;
	background-size: contain;
	right:25px;
	top:14px;
	z-index: 10;
}

// .dropdown-list img {
// 	filter: invert(61%) sepia(10%) saturate(0%) hue-rotate(236deg) brightness(99%) contrast(87%);
// }

// .dropdown-content a:hover img {
// 	filter: invert(90%) sepia(10%) saturate(0%) hue-rotate(236deg) brightness(99%) contrast(87%);
// }


.user-title {
	padding:15px;
	border-bottom: 1px solid #eee;
	margin-left: -15px;
	margin-right: -15px;
}


#sidebarMenu {
  height: 150%;
  position: fixed;
	left: 0;
	top:-50px;
  width: 260px;
  transform: translateX(-260px);
  transition: transform 0.8s ease-in-out;
  z-index: 8000;
  background-color:#fff;
  padding-top: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: none;
}


input[type="checkbox"]:checked ~ #sidebarMenu { transform: translateX(0); }

input[type=checkbox]:not(.custom-input-check input) {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}

.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 9000;
  height: 100%;
  width: 100%;
  top: 30px;
  left: 25px;
  height: 25px;
  width: 25px;
  display: none;
}

.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #000;
}

.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 5px;
}

.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}

.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 5px;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
	margin-top: 8px;
	background-color: #000;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
	margin-top: -9px;
	background-color: #000;
}


ul.sidebar-nav {
	margin:0px; padding:0px;
	box-sizing:border-box;
	transition:0.8s;
	margin-top: 15px;
}

ul.sidebar-nav li {
	list-style-type: none;
	line-height: 0;
}

ul.sidebar-nav a {
	padding:15px 0;
	padding-left: 20px;
	display: block;
	color:#999;
	font-size:14px;
	transition:0.3s; 
	width: 100%;
}

ul.sidebar-nav a:hover {
	background: var(--theme-background-color);
	color:#333;
}

ul.sidebar-nav a.active {
	background: var(--theme-background-color);;
	color:#333;
}

ul.sidebar-nav a:hover img, ul.sidebar-nav a.active img {
	filter: invert(39%) sepia(98%) saturate(5970%) hue-rotate(210deg) brightness(101%) contrast(110%);
}


ul.sidebar-nav img {
	position: relative;
	bottom:2px;
}

/* ------------------------------------------------------------------
Dashboard
------------------------------------------------------------------- */

.dashboard-content {
	padding: 30px;
	min-height: 100vh;
	background-size: contain;
	background-color: var(--theme-background-color);;
}

.dashboard-col {
	width: 100%;
	min-height: 520px;
	padding:40px;
	background: var(--white, #FFF);
	border-radius: 20px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03), 0 6px 20px 0 rgba(0, 0, 0, 0.03);
	position: relative;
}

.page-title {
	display: none;
}

input.invalid {
  background-color: #ffdddd;
}


.step-container	{
	width: 350px;
	margin:0 auto;
}

.step {
  float:left;
  text-align:center;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  opacity: 1;
}

.step-line {
	width:30px;
	height: 1px;
	float:left;
	text-align:center;
	margin-top:32px;
	background:#eee;
}

.step-icon {
	width:65px;
	height: 65px;
	background-size: contain;
	background-repeat: no-repeat;
}

.quickstart1 {background-image: url(/images/qs/qs_1_active.svg);}
.quickstart2 {background-image: url(/images/qs/qs_2.svg);}
.quickstart3 {background-image: url(/images/qs/qs_3.svg);}
.quickstart4 {background-image: url(/images/qs/qs_4.svg);}

.step.active .quickstart1 {background-image: url(/images/qs/qs_1_active.svg);}
.step.active .quickstart2 {background-image: url(/images/qs/qs_2_active.svg);}
.step.active .quickstart3 {background-image: url(/images/qs/qs_3_active.svg);}
.step.active .quickstart4 {background-image: url(/images/qs/qs_4_active.svg);}

.step3-container {
	width:615px;
	margin:0 auto;
	text-align: left;
}

ul.para {padding-left: 20px;}



button:focus {
	border: none;
	outline: none;
}

.icon-cross {
	position:absolute;
	right:20px;
	top:20px;
	width: 10px;
	height: 10px;
	background: url(/img/icon-cross.png) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.perform-col {
	width: 100%;
	padding:15px;
	background: var(--theme-background-second);
	border-radius: 20px;
	position: relative;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03), 0 6px 20px 0 rgba(0, 0, 0, 0.03);
	margin-bottom: 15px;
	background-image: url(/img/perform-arrow.png);
	background-position: center right 15px;
	background-repeat: no-repeat;
}

.perform-col p {color: var(--table-font-color);}

.perform-avatar {
	width:60px;
	height: 60px;
	border-radius: 10px;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: contain;
}

.perform-stats {
	float:left;
	margin-right:30px;
	font-size: 12px;
	letter-spacing: 1px;
	margin-top: 10px;
}

.perform-col td:nth-child(1) {
	width: 75px;
}

.check-wrapper {
	width:230px;
    margin-left:auto;
    margin-right:auto;
}

.check-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #eee;
}

.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.check-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-container input:checked ~ .checkmark:after {
  display: block;
}

.nodeals {
	width: 120px;
	height: 120px;
	display: inline-block;


/* ------------------------------------------------------------------
Active user
------------------------------------------------------------------- */}

.chart-container {
	padding:30px;
	padding-top: 15px;
	background: var(--chart-bg);
	margin-left: -30px;
	margin-right: -30px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	color: #fff;
}
.qa-container {
	padding:30px;
	padding-top: 15px;
	background: var(--table-bg);
	margin-left: -30px;
	margin-right: -30px;
	border-radius: 20px;
	color: var( --table-font-color);
	width: 90%;
}
.qa-div{
	padding: 1% 5%;
}

.chart-container h2 {color: #fff; margin-top:10px}
.chart-container .small-text {opacity: 0.6;}

.upward-change {color:var(--success-color) !important;}
.downward-change {color:#F1693A !important;}

.chart-graph img {width: 100%;}
.chart-bar img {width: 83%;}
.chart-bar {text-align: center;}

.chart-bar .small-text {
	color: #fff; 
	text-align: right; 
	opacity: 1;
	padding-right: 10%;
}


.graph-desc {float: right;}

.graph-desc ul {
	float:left;
	margin-left: 10px; 
	padding-left: 20px;
	list-style: none;
}

.graph-desc ul li::before {
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 15px;
  margin-left: -1em;
  font-size: 20px;
  position: relative;
  top: 2px;
} 

.graph-desc ul.green-bullet li::before {color: var(--success-color);;}

.viewall-btn {
	position: absolute;
	top: 15px;
	right: 15px;
}

.viewall-btn a {
	font-size: 14px;
	color: var(--chart-bg);
	text-transform: uppercase;
	letter-spacing: 1px;
}

.viewall-btn a:hover {
	
}

.deals-table {
	overflow-x:auto;
	width: 100%;
	padding-bottom: 50px;
	background: var(--table-bg);
	border-radius: 20px;
}

.deals-table table {
	width: 100%;
	font-size: 14px;
	color: var( --table-font-color);
}

.bot-simple {
	width:14px;
	height: 14px;
	float: left;
	margin-right: 15px;
	background: url(/img/icon-simple.png) no-repeat;
	background-size: contain;
	position: relative;
	top: 2px;
}

.bot-advanced {
	width:14px;
	height: 14px;
	float: left;
	margin-right: 15px;
	background: url(/img/icon-advanced.png) no-repeat;
	background-size: contain;
	position: relative;
	top: 2px;
}

.crypto-icon {
	width: 20px;
	height: 20px;
	float: left;
	margin-right: 10px;
	background-repeat: no-repeat;
	background-size: contain;
}

.eth {background-image: url(/img/coin-eth.png);}
.bitcoin {background-image: url(/img/coin-bitcoin.png);}
.binance {background-image: url(/img/coin-binance.png);}
.ada {background-image: url(/img/coin-ada.png);}

.bear-arrow {
	width: 10px;
	height: 10px;
	float: left;
	margin-right: 10px;
	background: url(/img/icon-arrow-down.png) no-repeat;
	background-size: contain;
	position: relative;
	top: 3px;
}

.bull-arrow {
	width: 10px;
	height: 10px;
	float: left;
	margin-right: 10px;
	background: url(/img/icon-arrow-up.png) no-repeat;
	background-size: contain;
	position: relative;
	top: 3px;
}

.panel {
  background: none;
	box-shadow: none;
	position: relative;
}

.panel-heading {
  border-radius: 4px;
  border: none;
  color: #fff;
  padding: 0;
}

.panel-title {
	position: relative;
}

.panel-title a {
  display: block;
  position: relative;
	color: #fff;
	font-weight: 700;
}

.panel-title a:hover {text-decoration:none;}
.panel-title a:focus {text-decoration:none;}
.panel-title a.active {text-decoration:none;}


.panel-body p {
	opacity: 0;
	position: relative;
	transition: 0.3s;
}

.panel-group {
	margin-bottom: 0;
}

.panel-group .panel-heading+.panel-collapse>.panel-body {border:none;}

.panel-group .panel+.panel {
	margin-top: -15px;
}

.panel-group .panel {
	border-radius: 0px;
}

.panel-heading .chart-arrow img {
	transform: rotate(0deg);
	transition: all 0.5s;
}

.panel-heading.active .chart-arrow img {
	transform: rotate(-180deg);
	transition: all 0.5s;
}

.show .panel-body p {
	opacity:1;
	top: 0px;
	transition: all 0.3s;
}

.chart-arrow {
	position: absolute;
	right:20px;
	top:40px;
	width:20px;
	z-index: 1000;
}
.chart-arrow-ai {
	position: absolute;
	right:10px;
	top:15px;
	width:20px;
	z-index: 1000;
}

#timeline {float:left}

.timeline-btn {
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  border: 1px solid transparent !important;
  margin-left: 5px;
  margin-right: 5px;
}

.timeline-active  {
	border: 1px solid #fff !important;
  }
  

.timeline-btn:hover {
  border: 1px solid #fff !important;
}

/* ------------------------------------------------------------------
Login
------------------------------------------------------------------- */

.login-page {
	width:100%;
	height: 100%;
	background: url(/img/bg-login.png) no-repeat right top;
	background-size: cover;
	position: relative;
	padding:30px;
	padding-top: 60px;
	text-align: center;
}

.login-col {
	width: 440px;
	display: inline-block;
}

.flag {
	width: 20px;
	height: 20px;
	border-radius: 10px;
	overflow: hidden;
}

.language {
	position: absolute;
	top:30px;
	right: 30px;
}

.language-btn {
	width: 185px;	
	font-size: 12px;
	background-color: #fff;
	color: #333;
	border-radius: 5px;
	padding-left: 15px;
	padding-right: 15px;
	height:45px;
	line-height: 45px;
	display: inline-block;
	text-align: left;
	background-image: url(/img/dropdown.svg);
	background-position: center right 15px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.language .dropdown-content {
	padding-top: 0;
}

.language .dropdown-list {
	width: 185px;
	border-radius: 5px;
	text-align: left;
}

.language-btn img, .language .dropdown-list img {width:20px; height: 20px;}



/* ------------------------------------------------------------------
Forms
------------------------------------------------------------------- */

.form-wrapper {
	background: var(--theme-background-second);
	padding:25px 15px;
	border-radius: 20px;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
	margin-bottom: 30px;
	margin-top: 15px;
}

// input, select, textarea { 
// 	background: #f5f5f5;
// 	line-height:140%;  
// 	border:none;
// 	border-radius:5px;
// 	height:50px;	
// 	width:100%;
// 	padding:6px 12px;
// 	color:#333;
// 	margin-top: 5px;
// 	text-align: center;
// 	text-transform: uppercase;
// 	font-size: 10px;
// 	letter-spacing: 1px;
// }

select {
	-webkit-appearance: none;
	-webkit-border-radius: 5px;
	background-image: url(/img/dropdown.svg);
	background-position: center right 15px;
	background-repeat: no-repeat;
	color:var(--999);
}

input:focus, textarea:focus, select:focus{
	outline: none !important;
	color:var(--table-font-color);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color:var(--999);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color:var(--999);
}

::-ms-input-placeholder { /* Microsoft Edge */
	color:var(--999);
}
.guest-bg{
	height: 100%;
	background: rgb(74,250,186);
	background: linear-gradient(212deg, rgba(74,250,186,1) 2%, rgba(99,202,247,1) 19%, rgba(255,255,255,1) 41%);
}
.auth-panel{
	width: 80%;
    margin: auto;
    padding-top: 6%;
}
}
@media (min-width:481px)  { 
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
  
  
  
  
}
@media (min-width:641px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .d-pnl-empty{
    display: block;
  }
  .no-wrap-mob{
    white-space:normal;
  }
  
}
@media (min-width:961px) { 
  /* big landscape tablets, laptops, and desktops */ 
  .auth-panel{
	width: 33%;
    margin: auto;
    padding-top: 6%;
  }
  .auth-logo{
	width: 300px;
  }
  .marketplace-card {
    border-radius: 6px;
    margin: 10px;
    min-height: 500px;
    padding: 10px;
    max-width: 280px;
    max-height: 530px;
    overflow: hidden;
    min-width: 250px;
}
.chart-container.ai-query{
	padding: 35px 115px;
}
}
@media (min-width:1025px) { 
  /* hi-res laptops and desktops */ 
  
}
@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  
}
@media screen and (min-height: 1440px) {
    
}

@media screen and (min-height: 2000px) {
  
}

@media (max-width: 1200px) {

  ul#menu li a {
      width: 70px;
  }
  
  #timeline {float: none;}
  
  .graph-desc {float: none; margin-top: 10px;}
  
  .chart-arrow {
    right:-10px;
  }
  
  
  }
  
  @media (max-width: 991px) {
  
  header {
      position:fixed;
      top:0;
      padding-left: 90px;
      padding-right: 15px;
  }
  
  ul#menu {display: none;}
  
  #logo {display: none;}
  
  #sidebarMenu {display: block;}
  
  .page-title {display: block;}
  
  .sidebarIconToggle {
    display: initial;
  }
  
  .dashboard-sidebar {
    display: none;
  }
  
  .dashboard-content {
      padding-top: 100px;
  }
  
  .retrieve-info button {
    float:none;
  }
  
  .chart-bar {padding-top: 40px;}
  
  .chart-bar img {width: 100%;}
  
  .chart-bar .small-text {
    padding-right: 0;
  }
  
  #timeline {float: left;}
  
  .graph-desc {float: right; margin-top: 0px;}
  
  }
  
  
  @media (max-width: 768px) {
  
  .footer-left {
    float:none;
  }
  
  
  .copyright {
    float:none;
    font-size: 13px;
    color: #999;
    margin-top: 30px;
  }
  
  .step3-container {
      width: 100%;
    }
  
  
  }
  
  
  @media (max-width: 576px) {	
  
  header {padding-left: 60px;}
  
  .header-menu {
      margin-left: 5px;
  }
  
  .header-btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .btn {width: 100%;}
  
  .dashboard-content {
      padding:15px;
      padding-top: 100px;
  }
  
  .dashboard-col {padding: 30px 15px;}
  
  .footer-logo {
    float: none;
  }
  
  .footer-divider {
    display: none;
  }
  
  .footer-slogan {
    float: none;
  }
  
  .step-container {width:300px}
  
  .step-icon {
    width:60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .active{
	
  }
  
  .step-line {
      width: 20px;
      margin-top: 30px;
  }
  
  .chart-container {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  #timeline {float: none;}
  
  .graph-desc {float: none; margin-top: 10px;}
  
  }
  .transition-div {
	opacity: 0;
	height: 0;
	overflow: hidden;
	transition: opacity 0.3s ease, height 0.3s ease;
  }
  
  .transition-div.show {
	opacity: 1;
	height: auto;
  }
//   .apexcharts-tooltip {
//     background: #cd1919;
//     color: orange;
//   }
.traded-stocks-div{
	flex-wrap: wrap;
	gap: 12px;
	max-height: 224px;
    overflow: scroll;
}
.pad-top-0{
	padding-top: 0 !important;
}
.traded-stocks-items{
	padding: 5px;
    border: 1px solid var(--light-border);
    border-radius: 25px;
    width: 75px;
    text-align: center;
}
.custom-bktest-div{
	padding-left: 25px !important;
	padding-top: 20px !important;
}
.no-border{
	border: none;
}
.react-select__menu{
	z-index: 10 !important;
}
.price-alert-exch-name{
	display: flex;
    gap: 20px;
    width: 200px;
}
.primary-col{
	color: var(--primary-color);
	font-size: 14px;
	cursor: pointer;
}