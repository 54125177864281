.social-post label {
  font-weight: bold;
  padding-left: 10px;
}

.social-post input {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #73cee2;
  padding: 0px 20px;
  outline: none;
}

.social-post .btn {
  background-color: #73cee2;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 44px;
  border-radius: 22px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 20px;
}

.social-post table {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}

.social-post thead tr {
  background-color: #73cee2;
}

.social-post thead {
  margin-bottom: 5px;
}

.social-post th {
  padding: 13px;
  color: white;
}

.social-post td {
  background-color: #73cee260;
  padding: 13px 30px;
  border: solid 2px white;
}
