form.send-email {
  width: 400px;
}

.send-email label {
  font-weight: bold;
  margin-top: 20px;
  padding-left: 10px;
}

.send-email input {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #73cee2;
  padding: 0px 20px;
  outline: none;
}

.send-email textarea {
  width: 100%;
  min-height: 350px;
  border-radius: 22px;
  border: solid 1px #73cee2;
  padding: 10px 20px;
  outline: none;
}

.send-email button {
  margin-top: 20px;
  margin-bottom: 40px;
}

.send-email .btn {
  background-color: #73cee2;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 44px;
  border-radius: 22px;
  padding-left: 40px;
  padding-right: 40px;
}
