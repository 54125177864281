





@media screen and (min-height: 1440px) {
  .trade-panel {
    max-width: 400px;
    background-color: white;
    border: solid 1px lightgray;
    padding: 10px;
  }

  
}
