.footer {
  margin-top: 50px;
  margin-bottom: 100px;
  margin-left: 100px;
  margin-right: 100px;
  color: #002352;
}

.social-icon {
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.social-icon-col {
  max-width: 16%;
}

a.social-icon {
  text-decoration: none;
}

.footer ul li.title {
  font-size: 14px;
  color: #8392a5;
  // font-weight: bold;
  margin-bottom: 15px;
}

.footer ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #002352;
  padding-left: 0px;
  // font-weight: bold;
}

.footer ul li a:hover {
  color: #00c1b1;
  background-color: transparent;
  text-decoration: none;
  padding-left: 0px;
}

.footer .copyright {
  color: #7a7a7a;
  font-size: 14px;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .footer {
    margin-top: 150px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
    color: #002352;
  }
}

@media only screen and (min-height: 1440px) {
  .footer {
    padding-top: 200px;
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media only screen and (min-height: 2000px) {
  .footer {
    padding-top: 300px;
    padding-left: 20%;
    padding-right: 20%;
  }
}
