.watchlist {
  color: #4c556a;
}

.watchlist .react-scrollable-list {
  width: 100%;
  overflow-y: scroll;
}

.star {
  font-size: 12px;
  color: #4c556a;
}

.text-pair {
  font-size: 12px;
  font-weight: bold;
  max-width: 60% !important;
  color: #4c556a;
}

.text-price {
  font-size: 12px;
  max-width: 20% !important;
  font-weight: bold;
}

.text-price-up {
  font-size: 12px;
  max-width: 20% !important;
  font-weight: bold;
  color: #12b886;
}

.text-price-down {
  font-size: 12px;
  max-width: 30% !important;
  font-weight: bold;
  color: #fa5252;
}

.text-change-up {
  font-size: 12px;
  text-align: right;
  max-width: 30% !important;
  font-weight: bold;
  color: #12b886;
}

.text-change-down {
  font-size: 12px;
  text-align: right;
  max-width: 30% !important;
  font-weight: bold;
  color: #fa5252;
}
