.setting-page {
  padding: 0px 20px;
}

.setting-nav {
  max-width: 300px;
  margin-right: 10px;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .setting-nav {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .setting-com {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-height: 1440px) {
  .setting-nav {
    max-width: 400px;
  }
}

@media only screen and (min-height: 2000px) {
  .setting-nav {
    max-width: 500px;
  }
}
