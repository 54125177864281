.balance-panel {
  margin-top: 30px;
}



.risk-low {
  font-size: 18px;
  color: #12b886;
  font-weight: bold;
}

.risk-medium {
  font-size: 18px;
  color: #ff9800;
  font-weight: bold;
}

.risk-high {
  font-size: 18px;
  color: #ff5252;
  font-weight: bold;
}

.asset-btn {
  font-size: 12px !important;
  font-weight: bold;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
