.security-panel .appstore-item {
  background-color: black;
  padding: 15px 20px;
  display: inline-block;
  color: white !important;
  border-radius: 10px;
}

.security-panel .appstore-item img {
  margin-bottom: 5px;
}

.security-panel .appstore-text {
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

@media only screen and (min-height: 1440px) {
  .security-panel {
    padding-right: 300px;
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .security-panel .row {
    margin-top: 25px;
  }
}

@media only screen and (min-height: 2000px) {
  .security-panel {
    padding-right: 600px;
    padding-left: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .security-panel .row {
    margin-top: 40px;
  }
}
