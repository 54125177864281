

.support-card p {
  margin-bottom: 0px;
}

.support-card img {
  margin-bottom: 30px;
  text-align: center;
}
