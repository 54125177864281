.new-order {
  padding-bottom: 20px;
}

.new-order .dropdown button {
  background-color: white !important;
  border-color: #e8ebf3 !important;
  color: #002352 !important;
}

.new-order .btn-success {
  background-color: #05cd99 !important;
  border-color: #05cd99 !important;
}



.new-order-btn {
  font-size: 12px !important;
  font-weight: bold;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.new-order .dropdown-toggle {
  width: 100%;
  font-size: 12px !important;
  font-weight: bold !important;
}

.new-order .dropdown-menu {
  width: 100%;
  text-align: center;
  font-size: 13px !important;
  font-weight: bold !important;
  color: #002352;
}



.form-group {
  margin-top: 15px;
}

// .new-order .input-group-text {
//   font-size: 13px !important;
//   font-weight: bold;
//   color: #4c556a;
//   background-color: #e8ebf3;
//   border: none;
// }



.new-order .wallet-icon {
  font-size: 14px;
  margin-right: 3px;
}
