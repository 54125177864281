.sub-panel .my-plan {
  padding: 30px 40px;
}

.sub-panel .sub-row {
  margin-top: 20px !important;
}

.sub-panel .sub-col {
  max-width: 50% !important;
  margin-left: 2% !important;
}

@media only screen and (min-height: 1440px) {
  .sub-panel {
    padding: 70px;
    margin-right: 300px;
  }

  .sub-panel .sub-row {
    margin-top: 30px !important;
  }

  .sub-panel .sub-col {
    max-width: 40% !important;
    margin-left: 7% !important;
  }
}

@media only screen and (min-height: 2000px) {
  .sub-panel {
    padding: 100px;
    margin-right: 600px;
  }

  .sub-panel .sub-row {
    margin-top: 70px !important;
  }

  .sub-panel .sub-col {
    max-width: 40% !important;
    margin-left: 7% !important;
  }
}
