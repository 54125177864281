#repay {
  font-weight: bold;
  color: #4c556a;
  font-size: 13px;
}

#repay .modal-title {
  color: #002352 !important;
}

#repay .modal-body {
  margin-left: 5px;
  margin-right: 5px;
}

.repay-select {
  margin-top: 10px;
}

.repay-balance {
  margin-top: 30px;
}

.repay-balance-value {
  text-align: right;
  color: #002352;
}

.repay-debt {
  margin-top: 30px;
}

.repay-debt-value {
  text-align: right;
  color: #002352;
}

.repay-amount {
  margin-top: 25px;
}

.repay-confirm {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}
