.support-page {
  padding: 0px 70px;
}

.support-telegram {
  color: #2ca5e0;
}

.support-facebook {
  color: #3b5998;
}

.support-twitter {
  color: #1da1f2;
}

.support-medium {
  color: #00ab6b;
}
.support-instagram {
  color: #262626;
}
.support-linkedin {
  color: #0077b5;
}

.support-whatsapp {
  color: #4fce5d;
}

.support-user-guide {
  color: #607d8b;
}

.support-forum {
  color: #7e57c2;
}

.support-faq {
  color: #607d8b;
}

.support-email {
  color: #d50000;
}
