.carousel-div-img{
    min-height: 480px;
}
.carousel-img{
    margin-bottom: 40px;
}
.carousel-div-flex img{
    display: inline-flex;
    justify-content: space-around;
    width: 340px
}
@media screen and (max-width: 992px) {
    .carousel-div-flex img{
        display: inline-flex;
        justify-content: space-around;
        width: 200px
    }
  }