.my-rewards {
  background-image: url(/images/bg2.png);
  background-repeat: no-repeat;
  background-size: 760px 570px;
  background-position: right 450px;
  min-height: 700px;
  width: 100%;
  display: flex;
  color: black;
  font-size: 14px;
  padding: 50px 0px 50px 140px;
}

.my-rewards .left {
  flex: 2 1 0;
}

.my-rewards .right {
  flex: 1 1 0;
  text-align: center;
  margin-top: 10px;
}

.my-rewards .left .header1 {
  color: #002255;
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
}

.my-rewards .left .header2 {
  color: #73cee2;
  font-style: normal;
  margin-left: 20px;
}

.my-rewards table {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}

.my-rewards thead tr {
  background-color: #73cee2;
}

.my-rewards thead {
  margin-bottom: 5px;
}

.my-rewards th {
  padding: 13px;
  color: white;
}

.my-rewards td {
  background-color: #73cee260;
  padding: 13px 35px;
  border: solid 2px white;
}

.my-rewards .btn {
  background-color: #73cee2;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 44px;
  border-radius: 22px;
  padding-left: 40px;
  padding-right: 40px;
}

.my-rewards .history-btn {
  background-color: white;
  border: solid 1px #73cee2;
  color: #73cee2;
  margin-left: 20px;
}

.my-rewards .right img {
  margin-top: 20px;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .my-rewards {
    background-image: none;
    padding: 30px 20px;
    display: block;
  }

  .my-rewards .left .header1 {
    font-size: 30px;
  }

  .my-rewards .left .header2 {
    margin-left: 20px;
  }
}
