.sub .sub-header {
  padding-left: 5%;
}

@media only screen and (min-height: 1440px) {
  .sub .sub-header {
    padding-left: 5%;
  }
}

@media only screen and (min-height: 2000px) {
  .sub .sub-header {
    padding-left: 10%;
  }

  .sub .sub-body {
    margin-left: 10% !important;
    margin-right: 10% !important;
    padding: 100px !important;
  }
}

.paypal-button-container {
  width: 100% !important;
}
