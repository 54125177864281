.rewards-redeem .form-redeem {
  width: 450px;
  margin-top: 30px;
}

.rewards-redeem label {
  display: block;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 18px;
  padding-left: 10px;
}

.rewards-redeem input {
  height: 40px;
  border: solid 1px #73cee2;
  width: 100%;
  border-radius: 100px;
  outline: none;
  padding: 0px 15px;
}

.rewards-redeem select {
  height: 40px;
  border: solid 1px #73cee2;
  width: 100%;
  border-radius: 100px;
  outline: none;
  padding: 0px 15px;
}

.rewards-redeem button {
  margin-top: 30px;
}

.rewards-redeem .frag1 {
  margin-top: 30px;
  width: 500px;
}
