.bg-header {
  background-color: #e9ecef;
}

@media only screen and (min-height: 1440px) {
  .api-panel {
    padding-right: 300px;
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .api-panel .row {
    margin-top: 25px;
  }
}

@media only screen and (min-height: 2000px) {
  .api-panel {
    padding-right: 600px;
    padding-left: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .api-panel .row {
    margin-top: 40px;
  }
}
