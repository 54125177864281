.leader-board {
  background-image: url(/images/bg3.png);
  background-repeat: no-repeat;
  background-size: 760px 570px;
  background-position: left 450px;
  min-height: 700px;
  width: 100%;
  display: flex;
  color: black;
  font-size: 14px;
  padding: 30px 0px 50px 140px;
}

.leader-board .left {
  flex: 2;
}

.leader-board .header1 {
  font-size: 48px;
  font-weight: bold;
  font-style: italic;
  color: #002255;
  line-height: 70px;
}

.leader-board .header2 {
  font-weight: bold;
  margin-top: -13px;
  line-height: 70px;
}

.leader-board .medal-row {
  padding: 40px 0px;
  display: flex;
}

.leader-board .medal {
  flex: 1;
  text-align: center;
}

.leader-board .medal .frag1 {
  color: #73cee2;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  line-height: 50px;
}

.leader-board .medal .frag2 {
  color: #002255;
  font-size: 18px;
  font-weight: bold;
  margin-top: -12px;
  line-height: 50px;
}

.leader-board .frag3 {
  margin-top: -7px;
  margin-left: 30px;
  line-height: 50px;
}

.leader-board .right {
  flex: 1;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .leader-board {
    background-image: none;
    padding: 30px 20px;
    display: block;
  }

  .leader-board .left .header1 {
    font-size: 30px;
  }

  .leader-board .left .header2 {
    margin-left: 0px;
  }
}
