.my-referrals {
  background-image: url(/images/bg2.png);
  background-repeat: no-repeat;
  background-size: 760px 570px;
  background-position: right 450px;
  min-height: 700px;
  width: 100%;
  display: flex;
  color: black;
  font-size: 14px;
  padding: 50px 0px 50px 140px;
}

.my-referrals .left {
  flex: 3;
  padding-right: 30px;
}

.my-referrals .left .header1 {
  color: #002255;
  font-size: 48px;
  font-weight: bold;
  font-style: italic;
}

.my-referrals .left .header2 {
  color: #73cee2;
  font-style: normal;
  margin-left: 30px;
}

.my-referrals table {
  margin-top: 40px;
  text-align: center;
  width: 100%;
}

.my-referrals thead tr {
  background-color: #73cee2;
}

.my-referrals thead {
  margin-bottom: 5px;
}

.my-referrals th {
  padding: 13px;
  color: white;
}

.my-referrals td {
  background-color: #73cee260;
  padding: 13px 30px;
  border: solid 2px white;
}

.my-referrals .right {
  flex: 1.5;
  text-align: center;
  margin-top: 20px;
}

.my-referrals .btn {
  background-color: #73cee2;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 44px;
  border-radius: 22px;
  padding-left: 40px;
  padding-right: 40px;
}

.my-referrals .history-btn {
  background-color: white;
  border: solid 1px #73cee2;
  color: #73cee2;
  margin-left: 20px;
}

.my-referrals .right img {
  margin-top: 110px;
}

.my-referrals .btn-action {
  background-color: transparent;
  color: #002255;
  border: none;
  font-weight: bold;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .my-referrals {
    background-image: none;
    padding: 30px 20px;
    display: block;
  }

  .my-referrals .left .header1 {
    font-size: 36px;
  }

  .my-referrals .left .header2 {
    margin-left: 20px;
  }
}
