@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
/*
=============================
     01. RESET CSS
=============================
*/

a:hover {
    text-decoration: none;
}
a {
    text-decoration: none;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
::selection {
    color: white;
    background: #ff7675;
}

::-webkit-selection {
    color: white;
    background: #ff7675;
}

::-moz-selection {
    color: white;
    background: #ff7675;
}
// table tr:nth-child(1):hover {
//     background-color: transparent !important;
// }
table tr:hover {
    background-color: var(--table-row-hover,--menu-a-hover);
}
td[rowspan] {
    background-color: var(--theme-background-second) !important; /* Or the desired non-hover color */
}
body, html,#root{
    margin:0;
    padding:0;
}
body,#root {
    min-width: fit-content;
}


/*
=============================
    02. BASE TYPOGRAPHY
=============================
*/

:root {
   
    --secondary-color: var(--theme-background-second);
 
    
    /* --text-color: #333; */
}

body {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    background-color: var(--theme-background-color);
    color:var(--theme-body-color);
}
/**********************************************
    DASHBOARD-BASE START HERE 
 **********************************************/

.main-right-area {
    padding: 0 32px;
    margin-bottom: 10%;
}

.main-left-col {
    background-color: var(--theme-background-second);
    min-height: 100vh;
    border-right: 1px solid var(--secondary-color);
}

.side-logo {
    margin-top: 30px;
    margin-bottom: 28px;
}

.main-side-nav-area {
    padding: 0 30px;
}

.side-logo a img {
    width: 100%;
    max-width: 150px;
}

.main-side-nav-area>ul li {
    display: block;
    margin-bottom: 10px;
}
.main-side-nav-area>ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 12px 0 12px 18px;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    transition: 0.1s;
}
.main-side-nav-area>ul li a:hover {
    background: var(--menu-a-hover);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}
.main-side-nav-area>ul li a:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1;
}
.main-side-nav-area>ul li a img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
    transition: 0.3s;
}
.active-sidenav {
    background: var(--menu-a-hover);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}
.active-sidenav img,.main-side-nav-area>ul li:hover a img{
    -webkit-filter: var(--navbar-icon-color) !important; 
    filter: var(--navbar-icon-color) !important;
    opacity: 1 !important;
}
.your-query-area h4 img{
    filter: var(--navbar-icon-color) !important;
}
.side-nav-promotion-area {
    width: 100%;
    border-radius: 10px;
    background: var(--menu-a-hover);
    margin-top: 20px;
    padding-top: 19px;
    padding-bottom: 15px;
    margin-bottom: 180px;
}

.side-nav-promotion-area h4 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    text-align: center;
}

.side-nav-promotion-area h2 {
    color: var(--theme-body-color);
    font-size: 17px;
    font-weight: 600;
    text-align: center;
}

.side-nav-promotion-area img {
    width: 100%;
    max-width: 170px;
    display: block;
    margin-top: 8px;
}

.side-nav-promotion-area > a {
    display: block;
    width: 100%;
    max-width: 140px;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    text-align: center;
    padding: 9px 0;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    z-index: 5;
    position: relative;
}
.side-nav-promotion-area > a::after {
    content: "";
    display: block;
    position: absolute;
    width: 10%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: skew(50deg);
    left: -20%;
    top: 0;
    z-index: -1;
}
.side-nav-promotion-area > a:hover:after {
    left: 120%;
    transition: 0.5s;
}
.main-right-header {
    padding-top: 35px;
}

.mr-inner-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mr-inner-right .nice-select {
    border-radius: 10px;
    background: var(--select-bg,#fff);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
}

.mr-inner-right .nice-select .current {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    padding-right: 30px;
}

.mr-inner-right .nice-select .option.selected {
    font-weight: 400;
    color: var(--primary-color);
}
.mr-inner-right .nice-select [data-value="5"].selected {
    color: #fff;
}

.mr-inner-right .nice-select .option {
    padding: 9px inherit;
}

.mr-inner-right .mr-title {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mr-inner-right .mr-title h4 {
    color: var(--theme-body-color);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}

.mr-user>ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}

.mr-user-icon {
    width: 40px;
    min-height: 40px;
    border-radius: 10px;
    background: var(--menu-icon-bg,#e5e5e5);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 0.3s;
}

.mr-user>ul li:nth-child(1)>a i {
    color: #999999;
    font-size: 18px;
}

.mr-user ul li:nth-child(2)>a i {
    color: #999999;
    font-size: 18px;
}

.mr-user ul li:nth-child(3)>a i {
    color: #999999;
    font-size: 16px;
}

.mr-user-icon:hover {
    background-color: var(--primary-color);
}

.white-text i {
    color: white !important;
}

.primary-bg {
    background-color: var(--primary-color) !important;
    ;
}

.mr-inner-right .mr-select [data-value="5"] {
    background-color: var(--primary-color);
    width: 100%;
    max-width: 150px;
    margin-left: 20px;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgba(33, 178, 152, 0.35);
    background-image: url(/images/icon-add.svg);
    background-repeat: no-repeat;
    background-position: 20px, 50%;
    padding-left: 40px;
    margin-bottom: 20px;
    position: relative;
    z-index: 5;
    margin-top: 10px;
}

.mr-inner-right .mr-select [data-value="5"]::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 10px;
    z-index: -1;
    background-image: url(/images/icon-add.svg);
    background-repeat: no-repeat;
    background-position: 20px, 50%;
}

.mr-inner-right .mr-select [data-value="5"]:hover {
    background-color: var(--primary-color);
}

/* .mr-user-icon:focus {
    background-color: var(--primary-color);
}
.mr-user-icon:focus i {
    color: #ffffff !important;
} */

.main-right-header .mr-user ul li>a:hover i {
    color: #ffffff;
}

.mr-user ul li {
    position: relative;
}

.mr-user .mr-user-dropdown3 {
    width: 155px;
    border-radius: 10px;
    background: var(--select-bg,#fff);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 125%;
    right: 0;
    display: none;
    z-index: 99;
}

.mru-dblock {
    display: block !important;
}

.mr-user .mr-user-dropdown3 .mr-user-dropdown1-title h4 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}

.mr-user .mr-user-dropdown3 .mr-user-dropdown1-title>p {
    color:var(--drop-light-text,#666);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-top: 2px;
    margin-bottom: 0;
}

.mr-user .mr-user-dropdown3 .mr-user-dropdown1-title>p span {
    font-weight: 600;
}

.mr-user .mr-user-dropdown3 .mr-user-dropdown1-title a {
    text-decoration: none;
    display: block;
    width: 100%;
    border-radius: 10px;
    background: var(--select-bg,#DEF3F0);
    margin-top: 6px;
    color: var(--theme-body-color);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 9px 0;
}

.mr-user-dropdown1-title {
    padding: 20px;
    border-bottom: 1px solid #EEE;
}

.mr-user-dropdown3-Navitem {
    padding: 12px 7px 20px 19px;
}

.mr-user-dropdown3-Navitem ul li a {
    text-decoration: none;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 9px 0;
    display: inline-block;
}

.mr-user-dropdown3-Navitem ul li a:hover {
    color: var(--theme-body-color);
}

.mr-user-dropdown3-active {
    color: var(--theme-body-color) !important;
}

.hamburger-icon {
    display: flex;
    flex-direction: column;
    gap: 4px;
    display: none;
    position: absolute;
    top: 16px;
    left: 16px;
}

.hamburger-icon span {
    width: 25px;
    height: 3px;
    border-radius: 50px;
    background-color: var(--theme-body-color);
    display: block;
    transition: 0.3s;
}

.hamburger-icon:hover span {
    background-color: #000;
}

.hamburger-icon:hover {
    cursor: pointer;
}

.offcanvas-start {
    width: 230px;
}

.offcanvas-header a img {
    max-width: 120px;
}

.offcanvas-body .main-side-nav-area {
    padding: 0;
}
.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: var(--theme-background-second);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .main-left-col {
        -ms-flex: 0 0 230px;
        flex: 0 0 230px;
        max-width: 230px;
    }
    .main-right-col {
        -ms-flex: 0 0 calc(100% - 230px);
        flex: 0 0 calc(100% - 230px);
        max-width: calc(100% - 230px);
    }
}

@media screen and (min-width: 1200px) {
    .main-left-col {
        -ms-flex: 0 0 230px;
        flex: 0 0 230px;
        max-width: 230px;
    }
    .main-right-col {
        -ms-flex: 0 0 calc(100% - 230px);
        flex: 0 0 calc(100% - 230px);
        max-width: calc(100% - 230px);
    }
}

/**********************************************
            LOGIN PAGE START HERE 
 **********************************************/

.login-area {
    width: 100%;
    min-height: 100vh;
    background: url(/images/login-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.login-content-wrapper {
    width: 100%;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 125px;
}

.login-content {
    width: 100%;
    max-width: 550px;
    text-align: center;
    background-color: #FFF;
    border-radius: 30px;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}

.login-content form label {
    width: 100%;
}

.login-content>a {
    display: inline-block;
    margin-top: 35px;
}

.login-content>a img {
    width: 100%;
    max-width: 240px;
}

.login-content > h4 {
    color: var(--theme-body-color);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 21px;
    margin-top: 37px;
}

.login-content > form input {
    display: block;
    width: 100%;
    max-width: 440px;
    min-height: 50px;
    border-radius: 10px;
    background-color: #f5f5f5;
    border: 0;
    margin-bottom: 15px;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 51px;
    padding-right: 20px;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border: 1px solid #f5f5f5;
}

.login-content>form input:focus {
    border: 1px solid var(--primary-color);
}

.login-content>form input[type="email"] {
    //background-image: url(/images/icon-email.svg) !important;
    background-repeat: no-repeat;
    background-position: 21px 50%;
}

.login-content>form input[type="password"] {
    //background-image: url(/images/icon-lock.svg);
    background-repeat: no-repeat;
    background-position: 21px 50%;
}

.login-content>form input::placeholder {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.login-content>form button {
    width: 100%;
    max-width: 440px;
    min-height: 50px;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    border: 0;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    margin-top: 15px;
    overflow: hidden;
    position: relative;
    z-index: 5;
}
.login-content>form button::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -50%;
    width: 20%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
    transform: skew(50deg);
}
.login-content>form button:hover:after {
    left: 150%;
    transition: 0.8s;
}
.login-content>span {
    color: #999;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    margin-top: 30px;
}

.login-content ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 23px;
    padding-bottom: 39px;
}

.login-content-bottom-link {
    text-align: center;
}

.login-content-bottom-link ul {
    margin-top: 30px;
}

.login-content-bottom-link ul li {
    display: block;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.login-area .login-content-bottom-link ul li {
    margin-bottom: 15px;
    display: block;
}
.login-content-bottom-link ul {
    padding-bottom: 30px;
}
.login-content-bottom-link ul li a {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

/* pass hide show */

.pass-label input {
    //background-image: url(/images/icon-lock.svg);
    background-repeat: no-repeat;
    background-position: 15px 50%;
}

.signIn-form form button {
    width: 100%;
    max-width: 250px;
    min-height: 52px;
    border: 0;
    border-radius: 11.068px;
    background: #18B5CF;
    margin-top: 22px;
    color: #F3F5F6;
    font-size: 12.649px;
    font-weight: 600;
    letter-spacing: 0.379px;
    text-transform: uppercase;
}

.pass-label {
    position: relative;
    max-width: 440px;
    min-height: 50px;
}

.pass-label #showPass {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate(-50%, -80%);
    color: #bbbbbb;
}

.signIn-left #showPass i {
    font-size: 14px;
    color: rgba(0, 90, 100, 0.35);
}

/* lang-picker */

.lang-picker em {
    font-size: 15px;
    font-weight: 600;
    color: #00303F;
    display: none;
}

.lang-picker button {
    border: 0;
    padding: 0;
}

.lang-picker button:focus {
    box-shadow: none;
}

.language-picker__dropdown {
    max-width: 90px;
}

.language-picker__flag {
    padding: 10px 10px;
}

.language-picker__button {
    width: 70px;
    height: 45px;
    background-color: #fff;
    background-image: url(/images/icon-down\ 2.svg);
    background-repeat: no-repeat;
    background-position: 80% 50%;
}

.login-language-picker-area {
    width: fit-content;
    margin-left: auto;
    padding: 30px;
}

.language-picker__flag--english::before {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 13px;
}

.language-picker__flag--deutsch::before {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 13px;
}

.language-picker__flag--francais::before {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 13px;
}

.language-picker__item[aria-selected=true] {
    background: var(--primary-color) !important;
}

/**********************************************
            SIGN_UP PAGE START HERE 
 **********************************************/

.signup-content>form input[type="text"]:nth-child(1) {
    background-image: url(/images/icon-user.svg);
    background-repeat: no-repeat;
    background-position: 21px 50%;
}

.signup-content>form input[type="text"]:nth-child(5) {
    background-image: url(/images/icon-referral.svg);
    background-repeat: no-repeat;
    background-position: 21px 50%;
}

.signup-checkbox {
    width: fit-content !important;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.signup-checkbox input {
    margin-bottom: 0 !important;
}

.signup-checkbox input[type="checkbox"] {
    width: 20px;
    min-height: 20px !important;
    border-radius: 5px;
    background: var(--secondary-color);
    display: inline-block;
    accent-color: var(--primary-color);
}

.signup-checkbox p {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.signup-checkbox p a {
    color: var(--primary-color);
    text-decoration: none;
}

/* custome checkbox */

.signup-checkbox label {
    display: flex;
    align-items: center;
    color: #9e9e9e;
    position: relative;
}

.signup-checkbox input {
    width: 0;
    height: 0;
    opacity: 0;
}

.signup-checkbox label>span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    border: 2px solid #E5E5E5;
    margin-right: 12px;
    border-radius: 3px;
    transition: all .3s;
    border-radius: 5px;
    background-color: #E5E5E5;
}

.signup-checkbox input:checked+label>span {
    border: 10px solid var(--primary-color);
    animation: bounce 250ms;
}

.signup-checkbox input:checked+label>span::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 6px;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checked-box 125ms 250ms forwards;
}

@keyframes checked-box {
    0% {
        width: 0;
        height: 0;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
    }
    33% {
        width: 4px;
        height: 0;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
    }
    100% {
        width: 4px;
        height: 8px;
        border-color: #fff;
        transform: translate(0, -8px) rotate(45deg);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(.7);
    }
    100% {
        transform: scale(1);
    }
}

.signup-checkbox label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}


.signup-checkbox label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.signup-checkbox label a {
    color: var(--primary-color);
    display: inline-block;
    text-decoration: none;
    margin-left: 5px;
}




/**********************************************
        RESET-PASSWORD PAGE START HERE 
 **********************************************/

.resetPassword-content {
    padding-bottom: 49px;
}

/**********************************************
            VERIFY_OTP PAGE START HERE 
 **********************************************/

.verifyOtp-content form>p {
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin-bottom: 25px;
}

/**********************************************
    dashboard-quickstart PAGE START HERE 
 **********************************************/

.quickstart-timeline-area {
    width: 100%;
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    margin-top: 30px;
    overflow: hidden;
    display: flex;
    gap: 128px;
}

.quickstart-timeline-left {
    width: 100%;
    max-width: 166px;
    background-color: var(--primary-color);
    padding: 60px 0;
}

.quickstart-timeline-left ul li a {
    width: 65px;
    height: 65px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.quickstart-timeline-left ul li a:not(.active) img {
    opacity: 0.3;
}

.quickstart-timeline-left ul li .active {
    background: #FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}

.quickstart-timeline-left ul li:nth-child(1) a img {
    opacity: 1;
}

.quickstart-timeline-left ul {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    justify-content: space-between;
}

.quickstart-timeline-left ul li {
    display: block;
    position: relative;
    padding: 0 50px;
}

.quickstart-timeline-left ul .current::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-color: transparent;
    transform: rotate(45deg);
    position: absolute;
    left: 94%;
    top: 40%;
}

.quickstart-timeline-left ul .current::after {
    background-color: var(--primary-color);
}

.quickstart-timeline-right {
    padding-top: 71px;
    padding-right: 90px;
}

.quickstart-timeline-right h3 {
    color: var(--theme-body-color);
    font-size: 20px;
    font-weight: 600;
}

.quickstart-timeline-right p {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin-bottom: 0;
}

.quickstart-timeline-right p a {
    color: var(--primary-color);
    text-decoration: none;
}

.quickstart-timeline-right ul {
    display: flex;
    gap: 14px;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 12px;
}

.quickstart-timeline-right ul li a {
    text-decoration: none;
    width: 370px;
    padding: 16px 0;
    display: block;
    text-align: center;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    position: relative;
    overflow: hidden;
}

.quickstart-timeline-right ul li:nth-child(1) a {
    background-color: var(--primary-color);
    border-radius: 10px;
    color: #fff;
}

.qtrblue-btn::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    top: 0;
    left: 100%;
    transition: 0.5s;
    z-index: -1;
}

.qtrblue-btn:hover:after {
    left: 0;
}

.qtrtrans-btn::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 125, 255, 1);
    top: 0;
    right: 100%;
    z-index: -1;
    transition: 0.5s;
}

.qtrtrans-btn:hover:after {
    right: 0;
}

.qtrtrans-btn:hover {
    color: #fff !important;
    transition: 0.3s;
}

.qtrtrans-btn {
    z-index: 5;
}

.qtrblue-btn {
    z-index: 5;
}
.quickstart-timeline-left ul li{
    position: relative; /* To position the line relative to the list item */
    padding: 10px 20px;
}
.quickstart-timeline-left ul li.active:not(:nth-child(1)):before {
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 90%;
    background-color: #fff;
    left: 50%;
    top: -80%;
    transform: translate(-50%, 0);
  }

.quickstart2-timeline-left ul {
    position: relative;
    z-index: 5;
}

.quickstart2-timeline-left ul::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 25%;
    background-color: #fff;
    left: 50%;
    top: 12%;
    z-index: -1;
    transform: translate(-50%, 0);
}

.quickstart2-timeline-right .qtrblue-btn {
    background-color: transparent !important;
    color: var(--primary-color) !important;
}

.quickstart2-timeline-right .qtrblue-btn::after {
    background-color: var(--primary-color) !important;
}

.quickstart2-timeline-right .qtrblue-btn:hover {
    color: #fff !important;
    transition: 0.5s !important;
}

.quickstart2-timeline-right .qtrtrans-btn {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}

.quickstart2-timeline-right .qtrtrans-btn::after {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.qs-3-params span {
    display: block;
    color: #666;
    font-size: 12px;
    ;
    font-weight: 400;
    letter-spacing: 0.24px;
    padding-left: 15px;
    position: relative;
}

.qs-3-params span b {
    color: var(--theme-body-color);
    font-weight: 600;
}

.quickstart3-timeline-right span::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #666;
    border-radius: 50%;
    top: 40%;
    left: 7.5px;
}

.quickstart3-timeline-right p {
    margin-bottom: 7px;
}

.quickstart3-timeline-right p:nth-child(6) {
    margin-top: 25px;
}

.quickstart-timeline-right form input {
    padding: 16px 22px;
    width: 100%;
    outline: none;
    border-radius: 10px;
    background: #F5F5F5;
    border: 0;
    margin-top: 15px;
}

.quickstart-timeline-right form input::placeholder {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.quickstart-timeline-right ul {
    margin-bottom: 99px;
}



.quickstart3-timeline-left ul li:nth-child(3) a {
    background: #FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}

.quickstart3-timeline-left ul li:nth-child(3) a img {
    opacity: 1;
}

.quickstart3-timeline-left ul::after {
    height: 60%;
    top: 8%;
}

.quickstart4-timeline-left ul li:nth-child(2) a {
    background: #FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}

.quickstart4-timeline-left ul li:nth-child(2) a img {
    opacity: 1;
}

.quickstart4-timeline-left ul li:nth-child(3) a {
    background: #FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}

.quickstart4-timeline-left ul li:nth-child(3) a img {
    opacity: 1;
}

.quickstart4-timeline-left ul li:nth-child(4) a {
    background: #FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}

.quickstart4-timeline-left ul li:nth-child(4) a img {
    opacity: 1;
}

.quickstart4-timeline-left ul::after {
    height: 80%;
}

/* custome checkbox */

.quickstart4-timeline-right>label {
    display: flex;
    align-items: center;
    color: #9e9e9e;
    position: relative;
}

.quickstart4-timeline-right input {
    width: 0;
    height: 0;
    opacity: 0;
}

.quickstart4-timeline-right label>span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    border: 2px solid #E5E5E5;
    margin-right: 12px;
    border-radius: 3px;
    transition: all .3s;
    border-radius: 5px;
    background-color: #E5E5E5;
}

.quickstart4-timeline-right input:checked+label>span {
    border: 10px solid var(--primary-color);
    animation: bounce 250ms;
}

.quickstart4-timeline-right input:checked+label>span::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 7px;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checked-box 125ms 250ms forwards;
}

@keyframes checked-box {
    0% {
        width: 0;
        height: 0;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
    }
    33% {
        width: 4px;
        height: 0;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
    }
    100% {
        width: 4px;
        height: 8px;
        border-color: #fff;
        transform: translate(0, -8px) rotate(45deg);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(.7);
    }
    100% {
        transform: scale(1);
    }
}

.quickstart4-timeline-right label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

/**********************************************
    Dashboard-NewUser PAGE START HERE 
 **********************************************/

.newuser-earning-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    margin-top: 30px;
}

.newuser-earning-area .newuser-cash-content:nth-child(1) {
    background-color: var(--dark-blue);
}

.newuser-earning-area .newuser-cash-content:nth-child(2) {
    background-color: #007DFF;
}

.newuser-earning-area .newuser-cash-content:nth-child(3) {
    background-color: var(--light-blue);
}

.dashboard-newuser-area .newuser-cash-content {
    display: flex;
    gap: 39px;
    align-items: center;
    padding: 20px;
    width: 33.33%;
    /* max-width: 362px; */
    border-radius: 20px;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
}

.dashboard-newuser-area .newuser-cash-content>button {
    border: 0;
    outline: 0;
    width: 60px;
    min-height: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    cursor: inherit;
}

.dashboard-newuser-area .newuser-cash-content::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 120%;
    width: 10%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    transform: skewX(20deg);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.dashboard-newuser-area .newuser-cash-content:hover:after {
    right: -20%;
    transition: 0.4s;
}

.dashboard-newuser-area .newuser-cash-content>div span {
    display: block;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
}

.dashboard-newuser-area .newuser-cash-content>div h5 {
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 4px;
}

.bot-create-area {
    width: 100%;
    min-height: 420px;
    margin-top: 40px;
    border-radius: 15px;
    border: 1px dashed #BBB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.bot-create-item h3 {
    color: var(--theme-body-color);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.bot-create-item p {
    margin-bottom: 0;
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin-top: 10px;
}

.bot-create-item button {
    width: 100%;
    max-width: 260px;
    background-color: var(--primary-color);
    padding: 16px 0;
    display: block;
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    border: 0;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    position: relative;
}

.bot-create-item button::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 120%;
    width: 15%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    transform: skewX(20deg);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.bot-create-item button:hover:after {
    right: -20%;
    transition: 0.5s;
}

/**********************************************
    Dashboard-normal PAGE START HERE 
 **********************************************/

.deshboard-normal-allBots-area {
    margin-top: 40px;
}

.deshboard-normal-allBots-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deshboard-normal-allBots-header-right {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 60px;
}

.deshboard-normal-allBots-header-right>label {
    display: flex;
    gap: 15px;
}

.deshboard-normal-allBots-header>h3 {
    color: var(--theme-body-color);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.createBot-dropdown>button {
    border-radius: 10px;
    background-color: var(--primary-color);
    border: 0;
    width: 100%;
    width: 140px;
    display: block;
    padding: 9px 0;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.createBot-dropdown{
    height: 100px;
    margin-top: 67px;
}
.createBot-dropdown.indicator-dropdown{
    margin-top: 10px;
    height: 55px !important;
    width: 50%;
}
.deshboard-normal-allBots-header-right .mr-dropdown .mr-select {
    width: 135px;
    border-radius: 10px;
    background: var(--select-bg,#fff);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
    height: 35px;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    line-height: 35px;
}

.deshboard-normal-allBots-header-right .mr-dropdown .mr-select .list {
    width: 135px;
}

.deshboard-normal-allBots-header-right .mr-dropdown .mr-select .list li {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.deshboard-normal-allBots-header-right .mr-dropdown .mr-select .option.selected {
    font-weight: 400;
    color: var(--primary-color);
}

.deshboard-normal-allBots-header-right>label input {
    width: 200px;
    height: 35px;
    border-radius: 10px;
    background: var(--select-bg,#fff);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
    outline: none;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    padding-left: 17px;
    padding-right: 30px;
    background-image: url(/images/icon-search.svg);
    background-repeat: no-repeat;
    background-position: 92% 50%;
}

.deshboard-normal-allBots-header-right>label input::placeholder {
    color: #999;
}

.deshboard-normal-allBots-header-right>label div>button {
    border: 0;
    background-color: var(--primary-color);
    width: 35px;
    height: 35px;
    border-radius: 10px;
}

.deshboard-normal-allBots-header-right>label div>button::after {
    display: none;
}

.createBot-dropdown .dropdown-menu li button {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 9px 0 9px 30px;
}

.createBot-dropdown .dropdown-menu {
    padding: 0;
    padding-bottom: 15px;
    width: 180px;
    border: 0;
    border-radius: 10px;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(0px, 47px, 0px) !important;
    top: -4px;
}

.createBot-dropdown .dropdown-menu li button i {
    margin-left: 15px;
}

.createBot-dropdown .dropdown-toggle::after {
    display: none;
}

.createBot-dropdown .dropdown-menu li button:hover {
    color: var(--primary-color);
    background-color: var(--menu-a-hover);
}

.createBot-dropdown .dropdown-menu li ul {
    width: 140px;
    border-radius: 10px;
    background: var(--select-bg,#FFF);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 0;
    left: 100%;
    overflow: hidden;
    display: none;
}

.createBot-dropdown .dropdown-menu li ul li a {
    text-decoration: none;
    display: block;
    padding: 9px 0;
    color: var(--drop-light-text,#999);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding-left: 30px;
}

.createBot-dropdown .dropdown-menu li ul li a:hover {
    color: var(--primary-color);
    background-color: var(--menu-a-hover);
}

.createBot-dropdown .dropdown-menu li:hover ul {
    display: block;
}

.deshboard-normal-allBots-header-right label #all-bots-dropdown {
    width: 160px;
    padding-left: 0;
}

.deshboard-normal-allBots-header-right #all-bots-dropdown {
    text-decoration: none;
    padding: 9px 0;
    color: var(--select-text-color,#999);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 0;
    border: 0;
    border-radius: 10px;
    background: var(--select-bg,#FFF);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}

.deshboard-normal-allBots-header-right #all-bots-dropdown .dropdown-item {
    padding: 9px 20px 9px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#all-bots-dropdown {
    transform: translate3d(-125px, 47px, 0px) !important;
}

.deshboard-normal-allBots-header-right #all-bots-dropdown .dropdown-item:hover {
    color: var(--primary-color);
}

.deshboard-normal-allBots-header-right #all-bots-dropdown .dropdown-item:focus {
    background-color: #e9ecef !important;
}

#all-bots-dropdown .dropdown-item:hover svg path {
    fill: var(--primary-color);
}

.deshboard-normal-allBots-body .dna-dsc {
    border-radius: 15px;
    background:  var(--bot-desc-bg,#E5E5E5);
    padding: 17px 15px;
}

.deshboard-normal-allBots-body .dna-dsc ul li {
    color: var(--drop-light-text,#666);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 8px;
    display: block;
    padding-left: 16px;
    position: relative;
}

.deshboard-normal-allBots-body .dna-dsc ul li::after {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.deshboard-normal-allBots-body {
    margin-top: 19px;
}

.deshboard-normal-allBots-body .dna-dsc ul li:nth-child(1):after {
    background-color: #20cd8e;
}

.deshboard-normal-allBots-body .dna-dsc ul li:nth-child(2):after {
    background-color:#F1B416;
}

.deshboard-normal-allBots-body .dna-dsc ul li:nth-child(3):after {
    background-color: #bbbbbb;
}

.deshboard-normal-allBots-body .dna-dsc {
    position: relative;
}

.deshboard-normal-allBots-body .dna-dsc i {
    font-size: 12px;
    color: #999999;
    position: absolute;
    cursor: pointer;
    transition: 0.3s;
    top: 5px;
    right: 5px;
    padding: 10px;
}

.deshboard-normal-allBots-body .dna-dsc i:hover {
    color: var(--theme-body-color);
}

.main-dna-cart {
    border-radius: 15px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 14px 15px 26px;
    min-height: 283px;
}

.dna-cart-header>ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.dna-cart-header ul li {
    display: flex;
    align-items: center;
}

.dna-cart-header ul li:nth-child(1) {
    gap: 7px;
}

.dna-cart-header ul li:nth-child(2) {
    gap: 9px;
}

.dna-cart-header ul li:nth-child(2) i {
    color: #bbbbbb;
    cursor: pointer;
}

.dna-cart-header ul li:nth-child(2) i:nth-child(1) {
    font-size: 12px;
    padding: 5px;
}

.dna-cart-header ul li:nth-child(2) i:nth-child(2) {
    font-size: 16px;
    padding: 5px;
    transition: 0.3s;
}

.dna-cart-header ul li:nth-child(2) i:nth-child(2):hover {
    color: var(--primary-color);
}
.menu-dots{
    color: var(--select-text-color,#000) !important;
    font-size: 16px !important;
}
.dna-cart-header ul li:nth-child(1) h6 {
    margin-bottom: 0;
    color: var(--theme-body-color);
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.22px;
}

.red-heart {
    color: #d33c6d !important;
}

.ellipsis-vertical-icon {
    color: var(--primary-color) !important;
}

.dna-cart-body>h4 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin-bottom: 21px;
    margin-top: 21px;
    padding-left: 20px;
    position: relative;
}
/* AFTER */
.dna-cart-body>h4::after {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.bot-active::after {
    background-color: #20cd8e;
}
.long-dot::after {
    background-color: #20cd8e;
}
.short-dot::after {
    background-color: #20cd8e;
}

.bot-waiting::after {
    background-color: #F1B416;
}

.bot-inactive::after {
    background-color: #bbbbbb;
}

/* BEFORE */
.bot-active::before {
    content: "deal in progress";
    display: block;
    border-radius: 2px;
    width: 95px;
    height: 25px;
    position: absolute;
    top: 100%;
    left: -44px;
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 9px;
    background-image: url(/images/toltip-green.svg) ;
    background-repeat: no-repeat;
    background-size: 100%;
}
.long-dot::before {
    content: "Long";
    display: block;
    border-radius: 2px;
    width: 95px;
    height: 25px;
    position: absolute;
    top: 100%;
    left: -44px;
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 9px;
    background-image: url(/images/toltip-green.svg) ;
    background-repeat: no-repeat;
    background-size: 100%;
}
.bot-inactive::before {
    content: "inactive";
    display: inline-block;
    border-radius: 2px;
    width: 60px;
    height: 25px;
    position: absolute;
    top: 100%;
    left: -27px;
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 9px;
    background-image: url(/images/toltip-gray.svg) ;
    background-repeat: no-repeat;
    background-size: 100%;
}
.bot-waiting::before {
    content: "waiting for entry";
    display: inline-block;
    border-radius: 2px;
    width: 100px;
    height: 25px;
    position: absolute;
    top: 100%;
    left: -46px;
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 9px;
    background-image: url(/images/toltip-redshort.svg) ;
    background-repeat: no-repeat;
    background-size: 100%;
}
.short-dot::before {
    content: "Short";
    display: inline-block;
    border-radius: 2px;
    width: 100px;
    height: 25px;
    position: absolute;
    top: 100%;
    left: -46px;
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 9px;
    background-image: url(/images/toltip-redshort.svg) ;
    background-repeat: no-repeat;
    background-size: 100%;
}
.dna-cart-body>h4::before {
    display: none;
}
.dna-cart-body>h4:hover:before {
    display: block;
}
.deal-tab>span::before {
    display: none;
}
.deal-tab>span:hover:before {
    display: block;
}
.trade-tab>span::before {
    display: none;
}
.trade-tab>span:hover:before {
    display: block;
}





.dna-cart-progress .progress {
    height: 5px;
    border-radius: 5px;
    background: var(--black-10, rgba(0, 0, 0, 0.10));
    margin-top: 9px;
}

.dna-cart-progress .progress .progress-bar {
    border-radius: 5px;
}

.dna-cart-progress ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dna-cart-progress ul li {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.dna-cart-progress ul li b {
    font-size: 12px;
    font-weight: 600;
    color: var(--theme-body-color);
}

.dna-cart-btc {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.dna-cart-btc>div {
    width: 50%;
}

.dna-cart-btc>div span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
}

.dna-cart-btc>div p {
    margin-bottom: 0;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.dna-cart-btc {
    margin-top: 25px;
}

.dna-cart-header ul li:nth-child(2) {
    position: relative;
}

.dna-cart-content {
    margin-top: 20px;
    padding-bottom: 30px;
}

.dna-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 24px;
}

.dna-footer a {
    text-decoration: none;
    display: block;
    width: 90px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 2px;
    padding: 1px 0;
}

.dna-green-footer a {
    color: #20cd8e;
    background: #e9faf4;
}

.dna-redshort-footer a {
    color: #d33c6d;
    background: #fbebf0;
}

.dna-footer a:nth-child(1) span {
    display: inline-block;
    color: #999;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-right: 8px;
}

.dna-footer a.strategy_name {
    background-color: var(--switch-bg,#F5F5F5)  !important;
    color: var(--table-text-color,#333);
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

/* cart dropdown here */

.dna-cart-dropdown {
    width: 165px;
    background-color: var(--dna-cart-bg,#FFF);
    border-radius: 10px;
    /* background: #FFF; */
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
}

.dna-cart-dropdown li a {
    color: var(--select-text-color,#999);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 8px 0;
    padding-left: 20px;
}
.dna-cart-dropdown li a img {
    display: inline-block;
    margin-right: 5px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
    transition: 0.3s;
}
.dna-cart-dropdown li a:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1;
}
.dna-cart-dropdown li a:hover svg path {
    fill: var(--primary-color);
}

.dna-cart-dropdown li a:hover {
    color: var(--primary-color);
}

.dna-cart-dropdown-footer input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 35px;
    height: 18px;
    background: #bbbbbb;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
}

.dna-cart-dropdown-footer input:checked[type="checkbox"] {
    background: var(--menu-a-hover);
}

.dna-cart-dropdown-footer input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    background: var(--primary-color);
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
}

.dna-cart-dropdown-footer input:checked[type="checkbox"]::after {
    left: 50%;
}



.dna-cart-dropdown-footer {
    display: flex;
    align-items: center;
    padding: 9px 15px 16px 20px;
    justify-content: space-between;
}

.dna-cart-dropdown-footer span {
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.dna-cart-dropdown-footer .dropdown-item.active,
.dropdown-item:active {
    background-color: var(--menu-a-hover);
}

.dna-cart-dropdown-footer {
    padding-top: 10px;
    border-top: 1px solid #f5f5f5;
    margin-top: 10px;
}

.dna-cart-dropdown {
    transform: translate3d(-113px, 21px, 0) !important;
    padding: 0 !important;
    overflow: hidden;
}

.dna-pagination ul {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
}

.dna-pagination ul li:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 12px;
}

.dna-pagination ul li:nth-child(3) a:first-child {
    color: var(--theme-body-color) !important;
}

.dna-pagination ul li:nth-child(3) a {
    text-decoration: none;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.dna-pagination ul li:last-child a {
    text-decoration: none;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.dna-pagination {
    margin-bottom: 35px;
}

.dna-pagination ul li a:hover svg path {
    fill: var(--theme-body-color);
}

.dna-pagination ul li a:hover {
    color: var(--theme-body-color);
}

/**********************************************
    Dashboard-rented-empty PAGE START HERE 
 **********************************************/

.db-rented-header {
    margin-top: 43px;
}

/**********************************************
    Dashboard-rented PAGE START HERE 
 **********************************************/

.db-rented-header {
    margin-top: 43px;
}

.dashboard-rented-cart-content {
    border-radius: 15px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 12px 15px 26px;
}

.dashboard-rented-cart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-rented-cart-header>h5 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    /* padding-left: 20px; */
    position: relative;
    margin-bottom: 0;
}

/* .dashboard-rented-cart-header>h5::after {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
} */

.dashboard-rented-cart-header i {
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
    color: #e5e5e5;
    transition: 0.1s;
}

.dashboard-rented-cart-header i:hover {
    color: var(--primary-color);
}

.dashboard-rented-cart-header i:focus {
    color: var(--primary-color);
}

.dashboard-rented-cart-body {
    display: flex;
    margin-top: 30px;
}

.dashboard-rented-cart-body>div {
    width: 50%;
}

.dashboard-rented-cart-body>div span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.dashboard-rented-cart-body>div p {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 0;
}

.dr-cart-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    margin-top: 24px;
}

.dr-cart-footer a {
    width: 110px;
    text-decoration: none;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 2px;
    display: block;
    padding: 3px;
}

.dr-cart-footer a span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 0 5px;
}

.dr-cart-dropdown .dropdown-menu {
    width: 165px;
    background: var(--select-bg,#fff);
    border-radius: 10px;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    right: 0;
}

.dr-cart-dropdown ul li a {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 10px 0;
    padding-left: 20px;
}

.dr-cart-dropdown ul li a:hover {
    color: var(--primary-color);
    background-color: var(--menu-a-hover);
}

.dr-cart-dropdown ul li a:not(.favourite) img {
    margin-right: 10px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
    margin-right: 0;
}
.dr-cart-dropdown ul li a:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1;
}

.dashboard-rented-cart-area {
    margin-top: 30px;
}

.dr-pagination {
    padding-top: 30px;
}

.heading-back {
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 10px 20px;
    border-radius: 5px;
}

.heading-back i {
    margin-right: 5px;
}

.heading-back:hover {
    background-color: var(--menu-a-hover);
}



.dashboard-rented-cart-header>h5::before {
    display: none;
}

/**********************************************
    Dashboard-botdetail PAGE START HERE 
 **********************************************/

.bot-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 25px;
}

.bot-detail-header>h4 {
    color: var(--theme-body-color);
    font-size: 20px;
    font-weight: 600;
    padding-left: 30px;
    position: relative;
}

.status-green {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: #20cd8e;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}
.status-red {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color:#d33c6d;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.bot-detail-header-btn {
    display: flex;
    gap: 16px;
    align-items: center;
}

.bot-detail-header-btn button {
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border: 0;
    width: 140px;
    height: 35px;
    position: relative;
    overflow: hidden;
    z-index: 5;
}

.bot-detail-header-btn button::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: -1;
    transform: skewY(50deg);
    transition: 0.5s;
}

.bot-detail-header-btn button:hover:after {
    left: 100%;
}



.bot-detail-header-btn button:nth-child(2):hover:after {
    left: 0;
}

.bot-detail-left {
    border-radius: 20px;
    background: var(--theme-background-second,#FFF);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 20px 20px 0 20px;
}

.bot-detail-left-header>label {
    display: block;
    margin-bottom: 20px;
}

.bot-detail-left-header>label img {
    display: inline-block;
    margin-right: 15px;
    filter: var(--navbar-icon-color);
}
.paper_exchange{
    filter: var(--navbar-icon-color);
} 

.bot-detail-left-header>label {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
}

.bot-detail-left-body {
    margin-top: 6px;
}

.bot-detail-left-body .bdol-item span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    margin-bottom: 6px;
}

.bot-detail-left-body .bdol-item h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin-bottom: 0;
}

.bot-detail-left-body .bdol-item {
    margin-bottom: 30px;
}

.bot-detail-left-header>label span {
    //color: #20CD8E;
    font-size: 17px;
    font-weight: 600;
}

.bot-detail-chart-area {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 42px 78px 33px 55px;
    margin-top: 30px;
}


.bot-detail-table-area {
    margin-top: 60px;
}

.bdt-header {
    display: flex;
    align-items: center;
    gap: 15px;
}



.bdt-header button:nth-child(2) {
    border-radius: 10px;
    background: #E5E5E5;
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    width: 122px;
    height: 35px;
    border: 0;
    transition: 0.4s;
    z-index: 5;
    position: relative;
    overflow: hidden;
}

.bdt-header button:nth-child(2):hover {
    color: #fff;
}

.bdt-header button:nth-child(2)::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: -1;
    transition: 0.5s;
}

.bdt-header button:nth-child(2):hover:after {
    left: 0;
}

.bdt-content table tr td {
    padding: 15px 0;
}

.bdt-content table tr {
    border-bottom: 1px solid #F5F5F5;
}

.bdt-table-responsive {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 10px 20px;
    display: block;
}

.bdt-content table tr:nth-child(1) td {
    color: var(--theme-body-color) !important;
    font-size: 11px !important;
    font-weight: 600;
    letter-spacing: 0.22px;
    border-top: 0;
}



.bdt-content table tr td:nth-child(2) span {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 2px 17px;
    margin-left: 10px;
}



.bdt-content table tr td {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}



.bdt-content table tr:last-child {
    border-bottom: transparent !important;
}

.bdt-content {
    margin-top: 19px;
}

.bot-detail-pag {
    margin-top: 30px;
}

/**********************************************
    AddBot-advanced-asset PAGE START HERE 
 **********************************************/

.bot-advance-asset-area {
    margin-top: 40px;
}

.bot-advance-asset-tab ul.bc-step-icons {
    /* max-width: 800px; */
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 75px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 9;
}
.bot-advance-asset-tab ul.bc-step-icons.rent {
    /* max-width: 800px; */
    max-width: 384px;
   
}

.bot-advance-asset-tab ul.bc-step-icons li label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color:var(--step-icon-bg,#FFF);
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    z-index: 9;
}

.bot-advance-asset-tab ul.bc-step-icons li a {
    text-decoration: none;
    text-align: center;
    display: inline-block;
    color: #BBB;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.bot-advance-asset-tab ul.bc-step-icons::before {
    content: "";
    display: block;
    position: absolute;
    top: 35%;
    left: 0;
    height: 1px;
}

.bot-advance-asset-tab ul.bc-step-icons::after {
    content: "";
    display: block;
    position: absolute;
    top: 35%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #e5e5e5;
    z-index: -1;
}

.ba-tab1 ul::before{
    width: 25%;
    background-color: var(--primary-color);
}
.ba-tab0.rent ul::before{
    width: 42%;
    background-color: var(--primary-color);
}
.ba-tab2 ul::before{
    width: 50%;
    background-color: var(--primary-color);
}
.ba-tab1.rent ul::before{
    width: 100%;
    background-color: var(--primary-color);
}
.ba-tab3 ul::before{
    width: 95%;
    background-color: var(--primary-color);
}
.ba-tab4 ul::before{
    width: 95%;
    background-color: var(--primary-color);
}
.ba-asset-form-area {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    padding: 30px 10px;
}

.ba-asset-form-content {
    width: 100%;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
}
.ba-asset-form-content.grid-params {
    max-width: 60%;
}

.ba-asset-form-content .ba-asset-left h6,.radiobtn-container h6,.checkbox-label h6 {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 0;
    //margin-top: 15px;
}

.ba-asset-form-content .ba-asset-left h6 img {
    margin-left: 5px;
}

.ba-asset-right:not(.react-select-div) input[type="text"],
.ba-asset-right:not(.react-select-div) input[type="number"],
.ba-asset-right:not(.react-select-div) input[type="password"]  {
    width: 100%;
    min-height: 40px;
    padding: 10px 20px;
    outline: none;
    border-radius: 10px;
    background-color:var(--create-bot-input,#F5F5F5) ;
    border: 0;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.react-select__control,.react-select__menu{
    background-color:var(--create-bot-input,#F5F5F5) !important;
    border: 0 !important;
    color: var(--theme-body-color) !important;
}
.react-select__single-value{
    color: var(--theme-body-color) !important;
}
.react-select__option:hover{
    color: #000 !important;
}


.exchange-input {
    padding-left: 45px !important;
    background-image: url(/images/icon-paper.svg);
    background-repeat: no-repeat;
    background-position: 5% 50%;
}

.ba-form-dropdown select {
    width: 100%;
    min-height: 40px;
    padding: 10px;
    outline: none;
    border-radius: 10px;
    background-color: #F5F5F5;
    border: 0;
    color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin-bottom: 20px;
}

.ba-form-radio label {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin-top: 2px;
    margin-left: 9px;
}

.ba-asset-right-mail span {
    display: block;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.ba-form-radio input {
    width: 16px;
    height: 16px;
    background-color: #E5E5E5;
    border: 0;
}

.ba-asset-right-mail span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.ba-asset-right-mail {
    margin-top: 15px;
}

.ba-asset-right-mail span a {
    text-decoration: none;
    color: var(--primary-color);
}

.ba-asset-Strategy-btn a {
    display: inline-block;
    text-decoration: none;
    padding: 2px 27px;
    background-color:var(--switch-bg,#F5F5F5);
    color: #BBB;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-right: 15px;
    border-radius: 2px;
    transition: 0.3s;
    cursor: pointer;
}
.ba-asset-Strategy-btn a.success.selected,.ba-asset-Strategy-btn a.success:hover {
    background-color: #20CD8E;
    color: #fff;
}
.ba-asset-Strategy-btn a.danger.selected,.ba-asset-Strategy-btn a.danger:hover {
    background-color: var(--badge-danger-color);
    color: #fff;
}
.ba-asset-Strategy-btn a.theme-color.success.selected,.ba-asset-Strategy-btn a.theme-color.success:hover {
    background-color: var(--dark-blue);
    color: #fff;
}
.ba-asset-Strategy-btn a.theme-color.danger.selected,.ba-asset-Strategy-btn a.theme-color.danger:hover {
    background-color: var(--button-success);
    color: #fff;
}
.bas-asset-submit button {
    width: 100%;
    border: 0;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    min-height: 35px;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-top: 20px;
    position: relative;
    z-index: 5;
    overflow: hidden;
}

.bas-asset-submit button::after {
    content: "";
    display: block;
    width: 10%;
    height: 100%;
    z-index: -1;
    transform: skew(50deg);
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: -20%;
}

.bas-asset-submit button:hover:after {
    left: 110%;
    transition: 0.8s;
}

.ba-asset-form-area {
    margin-top: 30px;
}

.ba-asset-middle-chart {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    padding: 20px 20px 29px 20px;
    margin-top: 20px;
}

.ba-asset-middle-chart-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 350px;
    background-color: #666;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border-radius: 10px;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
}

.ba-asset-chart-pag button {
    width: 30px;
    height: 26px;
    border-radius: 5px;
    background: #E5E5E5;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 0;
}

.ba-asset-chart-pag {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
    transition: 0.3s;
}

.ba-asset-chart-pag button:hover {
    color: #fff;
    background-color: var(--primary-color);
}

.pacp-active {
    color: #fff !important;
    background-color: var(--primary-color) !important;
}

/* checkbox btn */

/* Checkbox Input */

.checkbox-btn span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    transition: 0.3s;
    border-radius: 5px;
}

.checkbox-btn span::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 6px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    transform: scale(0) rotate(-45deg);
    position: relative;
    bottom: 1px;
    transition: 0.6s;
}

.checkbox-btn input {
    display: none;
}

.checkbox-btn input:checked~span {
    background-color: var(--primary-color);
}

.checkbox-btn input:checked~span::before {
    transform: scale(1) rotate(-45deg);
}

.ba-asset-backtest-right .checkbox-btn {
    display: flex;
    align-items: center;
    gap: 12px;
    width: fit-content;
}

.ba-asset-backtest-right .checkbox-btn label {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}

.ba-asset-backtest-form {
    display: flex;
    align-items: center;
    gap: 30px;
}

.ba-asset-backtest-form label {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    gap: 20px;
}

.ba-asset-backtest-form {
    margin-top: 28px;
}

.ba-asset-backtest-form label input {
    width: 166px;
    height: 40px;
    border-radius: 10px;
    background: #F5F5F5;
    border: 0;
    outline: none;
    padding-left: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    background-image: url(/images/icon-calendar\ 1.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%;
}

.ba-asset-backtest-left label {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}

.ba-asset-backtest-left label input {
    outline: none;
    width: 70px;
    height: 40px;
    border-radius: 10px;
    background: #F5F5F5;
    border: 0;
    padding: 10px;
}

.backtest-btn button {
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    width: 140px;
    height: 40px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border: 0;
    overflow: hidden;
    position: relative;
    z-index: 5;
}

.backtest-btn button::after {
    content: "";
    display: block;
    width: 10%;
    height: 100%;
    z-index: -1;
    transform: skew(50deg);
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: -20%;
}

.backtest-btn button:hover:after {
    left: 110%;
    transition: 0.5s;
}

.ba-summary-header button:nth-child(1) {
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    width: 100px;
    height: 35px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border: 0;
    overflow: hidden;
    position: relative;
    z-index: 5;
    margin-right: 15px;
}

.ba-summary-header button:nth-child(1)::after {
    content: "";
    display: block;
    width: 10%;
    height: 100%;
    z-index: -1;
    transform: skew(50deg);
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: -20%;
}

.ba-summary-header button:nth-child(1):hover:after {
    left: 110%;
    transition: 0.5s;
}

.ba-summary-header button:nth-child(2) {
    border-radius: 10px;
    background: #E5E5E5;
    width: 80px;
    height: 35px;
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border: 0;
    overflow: hidden;
    position: relative;
    z-index: 5;
}

.ba-summary-header button:nth-child(2)::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--primary-color);
    position: absolute;
    top: 0;
    left: -100%;
    transition: 0.3s;
}

.ba-summary-header button:nth-child(2):hover:after {
    left: 0;
}

.ba-summary-header button:hover {
    color: #fff;
}

.ba-summary-content {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    padding: 20px;
}

.ba-asset-backtest-area {
    margin-top: 40px;
}

.ba-summary-area {
    margin-top: 60px;
}

.ba-summary-header {
    margin-bottom: 20px;
}

.ba-summary-col1 ul li:nth-child(1) {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 6px;
}

.ba-summary-col1 ul li:nth-child(2) {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}

.ba-summary-col1 ul:nth-child(1) {
    margin-bottom: 20px;
}

.space-45 {
    margin-bottom: 40px;
}



/******************************************************
    AddBot-advanced-tradeparameters PAGE START HERE 
 ******************************************************/
 .ba-asset-form-content .bar2 input {
    position: relative;
    display: block;
    color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
 }
.ba-asset-form-content .bar2 span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: block;
    margin-top: 10px;
}



.order-limit-range .range {
    background: var(--primary-color);
    height: 5px;
    max-width: 100%;
    width: 100%;
    position: relative;
    border: none;
    border-radius: 20px;
    margin-top: 15px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  .order-limit-range  .range [type=range] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: transparent;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 5px;
  }
  .order-limit-range .range [type=range]:focus {
    outline: none;
  }
  .order-limit-range .range [type=range]::-webkit-slider-runnable-track {
    background: transparent;
  }
  .order-limit-range .range [type=range]::-moz-range-track {
    background: transparent;
  }
  .order-limit-range .range [type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #0064FE;
    cursor: pointer;
    border: none;
  }
  .order-limit-range  .range [type=range]::-moz-range-thumb {
    -moz-appearance: none;
         appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #0064FE;
    cursor: pointer;
    border: none;
  }
  .order-limit-range  .range [type=range]::-ms-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #0064FE;
    cursor: pointer;
    border: none;
  }
  .order-limit-range  .range [type=range]::-ms-fill-lower {
    background: var(--primary-color);
  }
  .order-limit-range  .range [type=range]::-ms-fill-upper {
    background: #e2e2ea;
  }
  .order-limit-range .range [type=range]::-ms-track {
    color: transparent;
    border: 0 solid transparent;
    background: transparent;
  }
  .order-limit-range  .range [type=range]::-ms-tooltip {
    display: none;
  }
  
  .order-limit-range  .range {
    max-width: 100%;
  }
  
  .order-limit-range-txt p {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
  }
  .order-limit-range-txt span {
    color:  #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
  }
  .price-gap-input input {
    display: block;
    margin-top: 20px;
    outline: none;
    border: 0;
    border-radius: 10px;
    background-color: #F5F5F5;
    max-width: 108px;
    min-height: 40px;
    padding: 10px 20px;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    background-image: url(/images/percent.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%; 
}
.bas-asset-btn2 { 
    display: flex;
    align-items: center;
    gap: 29px;
}
.bas-asset-btn2 button:nth-child(1) {
    border-radius: 10px;
    background: #999;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    transition: 0.3s;

}
.bas-asset-btn2 button:nth-child(1):hover {
    background-color: var(--primary-color);
}
.bafc-2 .ba-asset-right span {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-top: 10px;
    display: inline-block;
} 


.entry-condition-input input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 35px;
    height: 18px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 10px;
    background:var(--checkbox-bg,#E5E5E5);
}
.custom-input-check input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 35px;
    height: 18px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 10px;
    background:var(--checkbox-bg,#E5E5E5);
}
.entry-condition-input input:checked[type="checkbox"] {
    background: var(--menu-a-hover);
}
.custom-input-check input:checked[type="checkbox"] {
    background: var(--menu-a-hover);
}

.entry-condition-input input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border-radius: 18px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    transform: scale(1.1);
    transition: 0.4s;
    background-color:#fff;
}
.custom-input-check input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border-radius: 18px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    transform: scale(1.1);
    transition: 0.4s;
    background-color:#fff;
}
.custom-input-check input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border-radius: 18px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    transform: scale(1.1);
    transition: 0.4s;
    background-color:#fff;
}

.entry-condition-input input:checked[type="checkbox"]::after {
    left: 50%;
    background-color: var(--primary-color);
}
.custom-input-check input:checked[type="checkbox"]::after {
    left: 50%;
    background-color: var(--primary-color);
}

.entry-condition-input .check-box {
    margin-top: 6px;
}
.indicator-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 10px 4px 10px;
    border-radius: 2px;
    background: var(--menu-a-hover);
    margin-top: 15px;
}
.indicator-label span {
    color: var(--table-font-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.indicator-label > div {
    display: flex;
    align-items: center;
    gap: 30px;

}
.indicator-label > div img {
    cursor: pointer;
    padding: 0 5px;
}
.ec-indicator-area > button {
    border: 0;
    background-color: transparent;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: inline-block;
    margin-top: 11px;
}

.above-buy-price {
    display: flex;
    align-items: center;
    gap: 9px;
}
.above-buy-price label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: flex;
    align-items: center;
    gap: 9px;
}
.above-buy-price input {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background: #F5F5F5;
    color: var(--select-text-color,#333);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    outline: none;
    border: none;
    padding: 0 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 90% 50%;
}
.above-buy-price2 {
    background-color: var(--menu-a-hover,#666);
    border-radius: 2px;    
    padding: 10px 20px;
    position: relative;
    margin: 8px 0px;
}
.above-buy-price2 label {
    color: var(--theme-body-color);
}
.above-buy-price2 input {
    background-color: var(--select-bg,#FFF);
}
.above-buy-price2 > img {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50% , -50%);
    cursor: pointer;
}
.abp-btn {
    text-align: right;
}
.abp-btn a {
    text-decoration: none;
    display: inline-block;
    text-align: right;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-top: 15px;
}
.abp-btn a img {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 3px;
}
.eci-btn2  button {
    margin-top: 26px;
}
.ec-form2 {
    padding-top: 65px;
}
.disable-bot > label {
    display: flex;
    gap: 9px;
    align-items: center;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.disable-bot > label input {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background: #F5F5F5;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    outline: none;
    border: none;
    padding: 0 20px;
    display: inline-block;
}
.db-radio input {
    width: 15px;
    height: 15px;
    margin-right: 12px;
    border: 0;
    background-color: #E5E5E5;
}
.db-radio {
    margin-top: 30px;
}
.db-radio label {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.db-radio input:focus {
    box-shadow: none;
}
.bas-asset-btn3 button {
    margin-top: 0;
}
.advanced-exit-table table .side-data h6 {
    color: #20CD8E;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.advanced-exit-table table  .ordertype-data h6 {
    color:  var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}

.advanced-exit-table table .date-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.advanced-exit-table table .profit-data span {
    
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    display: inline-block;
    margin-right: 10px;
}
.advanced-exit-table table .profit-data a {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 1px 20px;
    margin-left: 10px;
    border-radius: 2px;
    text-decoration: none;
}

.olr-range2 input {
    width: 80px;
    text-align: left;
    outline: none;
    border: 0;
    color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.olr-range2 span {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}

.olr-range3 input {
    width: 24px;
}






/*********************************
    position tab page start
 *********************************/
 .position-tab-area {
    margin-top: 39px;
    margin-bottom: 50px;
 }
.position-tab-area .skltbs-tab,.bot-detail-table-area .skltbs-tab{
    border: 0;
    border-radius: 10px;
    background: var(--checkbox-bg,#E5E5E5);
    color: var(--table-text-color,#666);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding: 9px 23px;
    transition: 0.3s;
}
.position-tab-area .skltbs-tab-group,.bot-detail-table-area .skltbs-tab {
    display: flex;
    align-items: center;
    gap: 15px;
} 
.bot-detail-table-area .skltbs-tab-group{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}
button.skltbs-active {
	background-color: var(--primary-color) !important;
    color: #FFF !important;
}
.position-tab-area .skltbs-tab:hover {
    background-color: var(--primary-color);
    color: #fff;
}
.position-table-area {
    margin-top: 20px;
}
.open-table-content .dot {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
}
.open-table-content .dot-green::after {
    content: "ongoing";
    display: block;
    width: 60px;
    min-height: 22.17px;
    background-image: url(/images/toltip-green60_22.svg);
    color: var(--white, #FFF);
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 7px;
    position: absolute;
    top: calc(100% + 10px);
    left: -27px;
    display: none;
}
.tlc table .dot:hover:after {
    display: block;
}

.open-table-content .dot-green {
    background-color: #20CD8E;
    position: relative;
}
.open-table-content .dot-green::after {
    content: "ongoing";
    display: block;
    width: 60px;
    min-height: 22.17px;
    background-image: url(/images/toltip-green60_22.svg);
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 7px;
    position: absolute;
    top: calc(100% + 10px);
    left: -27px;
    display: none;
}
.open-table-content .dot-orange {
    background-color: #F1873A;
    position: relative;
}
.open-table-content .dot-gray {
    background-color: #bbbbbb;
    position: relative;
}
.open-table-content .dot-redshort {
    background-color: #D33C6D;
    position: relative;
}

.open-table-content .dot-redshort::after {
    content: "filled";
    display: block;
    width: 50px;
    min-height: 22.17px;
    background-image: url(/images/toltip-redshort50-22.svg);
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 7px;
    position: absolute;
    top: calc(100% + 10px);
    left: -22px;
    display: none;
}
.tlc table .dot:hover:after {
    display: block;
}

.open-table-content .dot-gray::after {
    content: "cancelled";
    display: block;
    width: 70px;
    min-height: 22.17px;
    background-image: url(/images/toltip-gray70_22.svg);
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 7px;
    position: absolute;
    top: calc(100% + 10px);
    left: -32px;
    display: none;
}
.open-table-content .dot-orange::after {
    content: "partially filled";
    display: block;
    width: 90px;
    min-height: 22.17px;
    background-image: url(/images/toltip-orange90_22.svg);
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.45px;
    padding-top: 7px;
    position: absolute;
    top: calc(100% + 10px);
    left: -42px;
    display: none;
}





.open-table-content table th {
    color: var(--table-text-color,#333);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.22px;
}
.open-table-content .bot-data {
    color: var(--table-text-color,#333);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.open-table-content .pair-data {
    display: flex;
    align-items: center;
}
.open-table-content .pair-data img {
    margin-right: 5px;
    display: inline-block;
}
.open-table-content .pair-data h6 {
    margin: 0;
    margin-right: 20px;
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.open-table-content .pair-data a {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 2px 22px;
    text-decoration: none;   
}
.open-table-content .valume-data h6 {
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin: 0;
}
.open-table-content .unr-return {
    display: flex;
    align-items: center;
}
.open-table-content .unr-return h6 {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin: 0;
    min-width: 100px;
}
.open-table-content .unr-return .text-success {
    color: #20CD8E;
}
.open-table-content .unr-return .text-danger {
    color: #D33C6D;
}
.open-table-content table th ,
.open-table-content table td {
    vertical-align: middle;
    padding: 15px 0;
    border-top: 0;
}
.open-table-content table tr th:first-child {
    padding-left: 30px;
}
.open-table-content table:not(.backtest-trades) tr td:first-child {
    padding-left: 30px;
}
.open-table-content .unr-return a {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 2px 22px;
    text-decoration: none;   
    display: inline-block;
    border-radius: 2px;
    margin-left: 10px;
}
.open-table-content .action-data {
    display: flex;
    align-items: center;
    gap: 18px;
}
.open-table-content {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}
.otc table tr:nth-child(1) th:nth-child(1)  {
    width: 9%;
}
.otc table tr:nth-child(1) th:nth-child(2) {
    width: 15%;
} 
.otc table tr:nth-child(1) th:nth-child(4) {
    width: 20%;
}
.otc table tr:nth-child(1) th:nth-child(6) {
    width: 10%;
}
.position-tab-header .deshboard-normal-allBots-header-right {
    justify-content: flex-end;
}

/* custom scroll */
.open-table-content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  .open-table-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }

  /* Handle */
  .open-table-content::-webkit-scrollbar-thumb {
    background: var(--primary-color); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .open-table-content::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color); 
}

.completed-table-content .exit-condition h6 {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.completed-table-content .action-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.pl-0{
    padding-left: 0 !important;
}
.tlc .side-BUY {
    color: #20CD8E;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.tlc .side-SELL {
    color: #D33C6D;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.tlc .filled-data {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin: 0;
}

.tlc .date-data {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin: 0;
}
.position-table-area table tr:hover {
    background: var(--table-row-hover,#f3f7ff);
}
.position-table-area table tr:not(:last-child) {
    border-bottom: 1px solid var(--border-color-table,#f5f5f5);
}
.table thead tr {
    border-bottom: 1px solid var(--border-color-table,#f5f5f5);
}
.open-table-content table tr:not(:last-child) {
    border-bottom: 1px solid var(--border-color-table,#f5f5f5);
}
.positions-detail-right-header {
    padding: 58px 29px;
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    min-height: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.positions-detail-right-header img {
    width: 100%;
    max-width: 500px;
}
.pd-left-header {
    min-height: 460px;
}
.pd-trade-history-area {
    margin-top: 44px;
}
.pd-th-header-left h4 {
    color: var(--theme-body-color);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}
.pd-th-header-right label {
    display: flex;
    align-items: center;
    gap: 12px;
}
.pd-th-header-right label span {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.pd-th-header-right label h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin-bottom: 0;
}
.pd-th-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
}
.pd-th-table-content table .date-data {
    color: var(--table-text-color,#333);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin: 0;
}
.pd-th-table-content table .side-data h6 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.pd-th-table-content table .order-type-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.pd-th-table-content table .amount-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.pd-th-table-content table .price-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.pd-th-table-content table .totalValue-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.pd-th-table-content table tr:nth-child(1) th:nth-child(3) {
    width: 10%;
}
.pd-th-table-content table tr:nth-child(1) th:nth-child(4) {
    width: 15%;
}
.pd-th-table-content table tr:nth-child(1) th:nth-child(5) {
    width: 15%;
}
.pd-th-table-content table tr:nth-child(1) th:nth-child(6) {
    width: 15%;
}
.pd-th-table-content table tr:nth-child(1) th:nth-child(7) {
    width: 15%;
}
.pd-th-table-area {
    margin-top: 25px;
}
.pdheader-btn {
    width: 90px !important;
}
.Portfolio-table-content table .coin-data {
    display: flex;
    //align-items: center;
    gap: 12px;
}
.Portfolio-table-content table .coin-data h6 {
    margin-right: 6px;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin-bottom: 10px;
}
.Portfolio-table-content table .coin-data span {
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.Portfolio-table-content table .holding-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.Portfolio-table-content table .change-data span {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 2px 20px;
    border-radius: 2px;
}
.Portfolio-table-content table .price-area h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.Portfolio-table-content table .amount-data h6 {
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.Portfolio-table-content table .total-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.Portfolio-table-content table tr:nth-child(1) th:nth-child(1) {
    width: 20%;
}


/**************************************
    Marketplace-landing page start
 **************************************/
 .marketpalce-landing-header {
    margin-top: 30px;
 }
.ml-header-left {
    display: flex;
    gap: 15px;
}
.ml-header-left button:nth-child(1) {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    border: 0;
    position: relative;
    z-index: 5;
    overflow: hidden;
}
.blue-button,.btn {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    border: 0;
    position: relative;
    z-index: 5;
    overflow: hidden;
}
.blue-button::after,.btn::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: -1;
    transform: skewY(50deg);
}
.blue-button:hover:after {
    left: 100%;
    transition: 0.5s;
}
.grey-button {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    background: var(--grey-button,#E5E5E5);
    //box-shadow: 0px 2px 4px var(--theme-body-color); /* Shadow with a hex color (#ff5733) */
    color: var(--theme-body-color);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    border: 0;
    position: relative;
    z-index: 5;
    overflow: hidden;
}
.ml-header-left button:nth-child(2) {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    background: var(--dark-blue);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    border: 0;
    position: relative;
    z-index: 5;
    overflow: hidden;
}
.ml-header-left button::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: -1;
    transform: skewY(50deg);
}
.ml-header-left button:hover:after {
    left: 100%;
    transition: 0.5s;
}
.ml-header-right .mr-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}
.ml-header-right .mr-dropdown select .list li {
    border-radius: 10px;
    background:#FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
}
.ml-header-right .nice-select {
    border: 0;
    border-radius: 10px;
    background:#FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    color: #333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    height: 35px;
    line-height: 35px;
}
.ml-header-right .nice-select .list {
    border: 0;
    border-radius: 10px;
    background:#FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    
}
.ml-header-dropdown .mlhd1 {
    width: 95px;
}
.ml-header-dropdown .mlhd2 {
    width: 95px;
}
.ml-header-dropdown .mlhd3 {
    width: 135px;
}
.ml-header-dropdown .mlhd1 .list {
    width: 100%;
}
.ml-header-dropdown .mlhd2 .list {
    width: 100%;
}
.ml-header-dropdown .mlhd3 .list {
    width: 100%;
}
.ml-header-right{
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}
.marketplace-landing-cart-content {
    border-radius: 15px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}
.marketplace-landing-cart-content .mlc-body {
    margin-top: 16px;
}
.marketplace-landing-cart-content .mlc-body .template-tile-div > h4 {
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.22px;
    margin-bottom: 15px;
}

.mlc-range-content .mlc-range-item label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
}
.mlc-range-content .mlc-range-item label span {
    color: #999;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.mlc-range-content .mlc-range-item label h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 0;
}
.mlc-range-item .progress {
    height: 5px;
}
.mlc-range-item .progress-bar {
    border-radius: 5px;
}
.marketplace-landing-cart-content .mlc-body {
    padding: 0 15px;
}
.marketplace-landing-cart-content .mlc-range-content {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 20px;
}
.mlc-range-item {
    width: 100%;
}
.marketplace-landing-cart-content .mlc-footer {
    margin: 0 15px;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}
.marketplace-landing-cart-content .mlc-footer a {
    text-decoration: none;
    display: inline-block;
    padding: 2px 20px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
}
.marketplace-landing-cart-content .mlc-footer label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    gap: 6px;
}
.marketplace-landing-cart-content .mlc-footer label i {
    cursor: pointer;
}
.marketplace-landing-cart-content .mlc-heading img {
    width: 100%;
}
.marketpalce-landing-cart-area {
    margin-top: 30px;
}
.marketplace-detail-area {
    margin-top: 36px;
}
.marketplace-detail-area .md-title h1 {
    color: var(--theme-body-color);
    font-size: 20px;
    font-weight: 600;
}
.marketplace-detail-area .md-title h1 a {
    text-decoration: none;
    border-radius: 2px;
    //background: #FBEBF0;
    display: inline-block;
    padding: 4px 40px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-left: 40px;
}
.marketplace-detail-area .md-title label {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}
.marketplace-detail-area .md-content .mdcl-bottom {
    width: 100%;
    max-width: 458px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.marketplace-detail-area .md-content .mdcl-bottom label {
    display: block;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    
}
.marketplace-detail-area .md-content .mdcl-bottom label i {
    cursor: pointer;
    color: #bbbbbb;
}
.md-content-left > img {
    width: 100%;
    max-width: 458px;
    border-radius: 15px;
}
.marketplace-detail-area .md-content {
    margin-top: 20px;
}
.marketplace-detail-area .mdc-para {
    padding: 20px;
    border-radius: 15px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}
.marketplace-detail-area .mdc-para  p {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin-bottom: 0;
}
.marketplace-detail-area .mdc-performance-left {
    border-radius: 20px;
    background:var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 20px;
    min-height: 280px;
}
.marketplace-detail-area .mdc-performance-left h4 {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
}
.marketplace-detail-area .mdc-performance-left h4 img:nth-child(1) {
    width: 22px;
    display: inline-block;
    margin-right: 14px;
}
.marketplace-detail-area .mdc-performance-left h4 img:nth-child(2) {
    width: 15px;
    display: inline-block;
    margin-left: 5px;
}
.marketplace-detail-area .mdc-performance-left ul {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}
.marketplace-detail-area .mdc-performance-left ul li span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.marketplace-detail-area .mdc-performance-left ul li h6 {
    margin-bottom: 0;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin-top: 5px;
}
.marketplace-detail-area .mdc-performance {
    margin-top: 30px;
}
.marketplace-detail-area .mdcp-btn button:nth-child(1) {
    width: 80px;
    height: 35px;
    border-radius: 10px;
    //background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    //color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    position: relative;
    z-index: 5;
    border: 0;
    margin-right: 10px;
    overflow: hidden;
}
.marketplace-detail-area .mdcp-btn .active {
    background: var(--primary-color) !important;
    color: #FFF !important;
}
.marketplace-detail-area .mdcp-btn button:nth-child(1)::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: -1;
    transform: skewY(70deg);
}
.marketplace-detail-area .mdcp-btn button:nth-child(1):hover:after {
    left: 100%;
    transition: 0.5s;
}
.marketplace-detail-area .mdcp-btn button:nth-child(2) {
    width: 120px;
    height: 35px;
    border-radius: 10px;
    // background: #E5E5E5;
    // color: #666;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    position: relative;
    z-index: 5;
    border: 0;
    overflow: hidden;
}
.marketplace-detail-area .mdcp-btn .inactive {
    background: #E5E5E5 ;
    color: #666 ;
}
.marketplace-detail-area .mdcp-btn button:nth-child(2)::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: -1;
    transition: 0.5s;
}
.marketplace-detail-area .mdcp-btn button:nth-child(2):hover:after {
    left: 0;
    color: #FFF ;
}
.marketplace-detail-area .mdcp-btn button:nth-child(2):hover {
    color: #fff ;
    transition: 0.5s;
}
.mdc-trade-stock h4 {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
}
.mdc-trade-stock h4 img {
    margin-right: 14px;
}
.mdc-trade-stock {
    padding: 20px 20px 33px 24px;
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    margin-top: 30px;
    margin-bottom: 0;
}
.mdc-trade-stock ul li a {
    width: 50px;
    padding: 6px 0;
    display: block;
    text-decoration: none;
    color:#999;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 5px;
    background-color:var(--bot-desc-bg,#E5E5E5);
    transition: 0.3s;
}
.mdc-trade-stock ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 25px;
}
.mdc-trade-stock ul li a:hover {
    background-color: var(--primary-color);
    color: #fff;
}
.mdcts-active {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}


.md-rent-bot form select .list li {
    border-radius: 10px;
    background:#FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    border: 0;
}
.md-rent-bot form > .nice-select {
    width: 293px;
    border: 0;
    border-radius: 10px;
    background:#F5F5F5;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.md-rent-bot form .nice-select .list {
    width: 100%;
    border: 0;
    border-radius: 10px;
    background:#FFF;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    
}
.md-rent-bot form .nice-select .list li {
    width: 100% !important;
}
.md-rent-bot form label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-top: 40px;
}
.md-rent-bot form label a {
    text-decoration: none;
    color: var(--primary-color);
    
}

.mdcts-dropdown {
    display: flex;
    width: 100%;
    display: flex;
}
.mdcts-dropdown span {
    width: 20px;
    height: 20px;
    background-color: #e5e5e5;
    border-radius: 5px;
    border: 0;
}
.mdcts-dropdown label {
	display: flex; 
    align-items: center; 
	color: #9e9e9e;
	position: relative;
    width: 100% !important;
}

.mdcts-dropdown input {
	width: 0;
	height: 0;
	opacity: 0;
}

.mdcts-dropdown  label > span {
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	margin-right: 15px;
	border-radius: 3px;
	transition: all .3s;
}

.mdcts-dropdown  input:checked + label > span {
	border: 10px solid var(--primary-color);
	animation: bounce 250ms;
}

.mdcts-dropdown  input:checked + label > span::before {
	content: '';
	position: absolute;
	top: 10px;
	left: 5px;
	border-right: 2px solid transparent;
	border-bottom: 2px solid transparent;
	transform: rotate(45deg);
	transform-origin: 0% 100%;
	animation: checked-box 125ms 250ms forwards;
}

@keyframes checked-box {
	0% {
		width: 0;
		height: 0;
		border-color: #fff;
		transform: translate(0,0) rotate(45deg);
	}
	33% {
		width: 4px;
		height: 0;
		border-color: #fff;
		transform: translate(0,0) rotate(45deg);
	}
	100% {
		width: 4px;
		height: 8px;
		border-color: #fff;
		transform: translate(0,-8px) rotate(45deg);
	}
}

@keyframes bounce {
	0% {
		transform: scale(1);
	}
	33% {
		transform: scale(.7);
	}
	100% {
		transform: scale(1);
	}
}

.md-rent-bot button {
    width: 100%;
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    display: block;
    padding: 16px 0;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    border: 0;
    margin-top: 30px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 5;
}
.md-rent-bot button:disabled{
    background: #ccc;
    box-shadow:none
}
.md-rent-bot button::after {
    content: "";
    display: block;
    position: absolute;
    width: 10%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: skew(50deg);
    left: -20%;
    top: 0;
    z-index: -1;
}
.md-rent-bot button:hover:after {
    left: 120%;
    transition: 0.8s;
}
.md-rent-bot  {
    margin-top: 27px;
}
.mp-bot-name {
    min-height: auto !important;
    padding: 17px 20px 27px 20px !important;
}
.mp-bot-name ul li h6 {
    font-size: 15px !important;
}
.mp-pay-area {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 20px;
}
.mp-pay-header h6 {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
}
.mp-pay-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mp-pay-header h6 img {
    display: inline-block;
    margin-right: 14px;
}
.mp-pay-header > ul {
    display: flex;
    align-items: center;
    gap: 5px;
}
.mp-pay-form form > input {
    background-color: #F5F5F5;
    width: 100%;
    padding: 12px 17px;
    color:#333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border-radius: 10px;
    outline: none;
    border: 0;
    border: 2px solid transparent;
}
.mp-pay-form form > input:focus {
    border: 2px solid var(--primary-color);
}
.mp-pay-form form > input::placeholder {
    color:#999;
    font-size: 12px;
}
.mp-pay-form form {
    margin-top: 25px;
}
.mp-pay-card-input input {
    background-color: #F5F5F5;
    width: 100%;
    padding: 12px 17px;
    color:#333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border-radius: 10px;
    outline: none;
    border: 0;
    border: 2px solid transparent;
}
.mp-pay-card-input  {
    margin-top: 15px;
    display: flex;
    gap: 30px;
}
.mp-pay-card-input input:nth-child(1) {
    width: 50%;
}
.mp-pay-card-input input:nth-child(2) {
    width: 25%;
}
.mp-pay-card-input input:nth-child(3) {
    width: 25%;
}
.mp-pay-form form > button {
    width: 100%;
    background-color: var(--primary-color);
    border: 0;
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    display: block;
    padding: 16px;
    border-radius: 10px;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    z-index: 5;
}
.mp-pay-form form > label {
    display: block;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    text-align: center;
    margin-top: 30px;
}
.mp-pay-form form > label a {
    text-decoration: none;
    color: var(--primary-color);
}
.mp-pay-card-input input:focus {
    border: 2px solid var(--primary-color);
}
.mp-pay-card-input input::placeholder {
    color:#999;
    font-size: 12px;
}

.mp-pay-form form > button::after {
    content: "";
    display: block;
    position: absolute;
    width: 10%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: skew(50deg);
    left: -20%;
    top: 0;
    z-index: -1;
}
.mp-pay-form form > button:hover:after {
    left: 120%;
    transition: 0.8s;
}



/**************************************
    reports page start here
 **************************************/
.reports-header {
    margin-top: 35px;
    display: flex;
    align-items: center;
    gap: 15px;
 }
.reports-header button {
    width: 90px;
    height: 35px;
    color:var(--table-text-color,#666);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border-radius: 10px;
    background:var(--checkbox-bg,#E5E5E5);
    border: 0;
    transition: 0.3s;
}
.reports-header button:hover {
    background: var(--primary-color);
    color: #fff;
}
.reports-header button.active {
    background: var(--primary-color);
    color: #fff;
}
.reports-header .dropdown-menu {
    width: 155px;
    height: 281px;
    overflow-y: auto;
    padding: 0;
    border: 0;
    transform: translate(0px, 47px) !important;
}


/* custom scroll */
.reports-header .dropdown-menu::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
  .reports-header .dropdown-menu::-webkit-scrollbar-track {
    border-radius: 0px 10px 10px 0px;
    border-radius: 10px;
    background-color: #e5e5e5;
}
.reports-header .dropdown-menu::-webkit-scrollbar-thumb {
    background: var(--primary-color); 
    border-radius: 10px;
  }
.reports-header .dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color); 
}
.reports-header .dropdown-menu li a:active {
    color: var(--primary-color);
}
.reports-header .dropdown-menu li a {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    background-color: #fff;
    padding: 10px 0;
    padding-left: 30px;
}
.reports-header .dropdown-menu li a:hover {
    color: var(--primary-color);
    background-color: var(--menu-a-hover);
}
.reports-header .dropdown button:focus {
    background-color: var(--primary-color);
    color: #fff;
}
.reports-chart-area {
    margin-top: 27px;
}
.reports-chart-area .rc-top a {
    width: 184px;
    color: var(--table-text-color,#000);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.22px;
    display: block;
    text-decoration: none;
    border-radius: 20px 20px 0px 0px;
    background: var(--subscription-card-bg,#E5E5E5);
    padding: 25px 14px;
    padding-left: 15px;
    transition: 0.3s;
}
.reports-chart-area .rc-top a h6 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px;
}
.reports-chart-area .rc-top {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}
.reports-chart-area .rc-top a:hover {
    color: #000 !important;
    background-color: #fff !important;
}
.active-rctop-btn {
    color: #000 !important;
    background-color: #fff !important;
}
.reports-chart-content .rc-header h4 {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
}
.reports-chart-content .rc-header h4 img {
    display: inline-block;
    margin-right: 14px;
}
.reports-chart-content {
    border-radius: 0 20px 20px 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 34px 20px;
    padding-bottom: 50px;
    max-width: 1100px !important;
}
.rc-detail-item {
    margin-top: 34px;
}
.rc-detail-content .rc-detail-item span {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.rc-detail-content .rc-detail-item h6 {
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    margin-top: 6px;
}
.reports-chart-area .report-chart-item img {
    width: 100%;
}
.reports-chart-area .report-chart-item {
    margin: 0 35px;
    margin-top: 40px;
}
.reports-chart-area .rc-footer-left a {
    text-decoration: none;
    color: #999;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: block;
    width: 30px;
    padding: 5px 0;
    border-radius: 5px;
    background: var(--checkbox-bg,#E5E5E5);
    transition: 0.3s;
}
.reports-chart-area .rc-footer-left a:hover, .rc-footer-left .pointer.active{
    background-color: var(--primary-color);
    color: #FFF;
}
.reports-chart-area .rc-footer-left a:last-child {
    width: 65px;
}
.reports-chart-area .rc-footer-left {
    display: flex;
    align-items: center;
    gap: 10px;
}
.reports-chart-area .rc-footer {
    margin-top: 40px;
}
.rc-footer-right {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: flex-end;
}
.rc-footer-right > h6 {
    margin-bottom: 0;
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.rc-footer-right label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: flex;
    align-items: center;
    gap: 15px;
}
.rc-footer-right label input {
    display: inline-block;
    padding: 11px 17px;
    padding-right: 25px;
    border-radius: 10px;
    background-color: #F5F5F5;
    width: 166px;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    background-image: url(/images/calender-icon.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%;
    outline: none;
    border: 0;
}
.rc-footer-right > button {
    border: 0;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    width: 40px;
    height: 40px;
}
.report-chart-table {
    margin-top: 30px;
    margin-bottom: 55px;
}


/* custom scroll */
.bdt-table-responsive::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
.bdt-table-responsive::-webkit-scrollbar-track {
    border-radius: 0px 10px 10px 0px;
    border-radius: 10px;
    background-color: #999;
}
.bdt-table-responsive::-webkit-scrollbar-thumb {
    background: var(--primary-color); 
    border-radius: 10px;
}
.bdt-table-responsive::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color); 
}
.report-chart-table table tr:nth-child(1) td {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.22px;
}
.report-chart-table table tr td:nth-child(1) {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.report-chart-table table tr td:nth-child(2) {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.report-chart-table table tr td:nth-child(3) {
    color:var(--theme-body-color);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.report-chart-table table tr td:nth-child(4) {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.report-chart-table table tr td:nth-child(4) span {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 1px 20px;
    margin-left: 10px;
}
.report-chart-table table tr td:nth-child(5) {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
} 
.report-chart-table table tr td {
    padding: 15px 0;
}
.report-chart-table table tr:last-child {
    border-bottom: 1px solid transparent;
}
.report-chart-table table {
    margin-bottom: 0;
}
.report-chart-table table tr:nth-child(1) td:nth-child(1) {
    width: 20%;
}
/* .report-chart-table table tr:nth-child(1) td:nth-child(2) {
    width: 20%;
}
.report-chart-table table tr:nth-child(1) td:nth-child(3) {
    width: 20%;
} */
.report-chart-table table tr:nth-child(1) td:nth-child(4) {
    width: 25%;
}
.report-chart-table table tr:nth-child(1) td:nth-child(5) {
    width: 15%;
}

/************************** 
    AI PAGE START HERE 
**************************/

.your-query-area {
    width: 100%;
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    padding: 20px;
    padding-bottom: 10px;
}
.your-query-area > h4 {
    color:var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
}
.your-query-area > h4 img {
    display: inline-block;
    margin-right: 15px;
    width: 20px;
}
.your-query-area .query-input input {
    width: 100%;
    outline: none;
    padding: 15px 22px;
    display: block;
    border-radius: 10px;
    background: var(--switch-bg,#F5F5F5);
    color: var(--table-text-color,#333);
    border: 0;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    border: 2px solid transparent;
    transition: 0.3s;
}
.your-query-area .query-input input::placeholder {
    color: #999;
}
.your-query-area .query-input input:focus {
    border: 2px solid var(--primary-color);
}
.your-query-area .query-input button {
    border: 0;
    border-radius: 10px;
    background: var(--dark-blue);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    padding: 16px 58px;
    position: relative;
    z-index: 5;
    overflow: hidden;
}
.your-query-area .query-input button::after {
    content: "";
    display: block;
    position: absolute;
    width: 20%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    top: 0;
    left: -50%;
    z-index: -1;
    transform: skew(40deg);
}
.your-query-area .query-input button:hover:after {
    left: 150%;
    transition: 0.8s;
}
.your-query-area .query-input {
    display: flex;
    align-items: center;
    gap: 15px;
}
.your-query-area .query-trending h6 {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 0;
    margin-top: 30px;
    margin-bottom: 18px;
}
.your-query-area .query-trending h6 img {
    width: 15px;
}
.your-query-area .query-tag a {
    width: 60px;
    padding: 5px 0;
    display: block;
    text-decoration: none;
    border-radius: 5px;
    background:var(--tbl-header,#E5E5E5);
    color: var(--table-text-color,#999);;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    transition: 0.3s;
}
.your-query-area .query-tag a:hover {
    background: var(--primary-color);
    color: #fff;
}
.your-query-area .query-tag  {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.active-query-tag {
    background: var(--primary-color) !important;
    color: #fff !important;
}
.query-ans-area {
    margin-top: 30px;
}
.query-ans-area textarea {
    border: 0;
    resize: none;
    width: 100%;
    min-height: 150px;
    background-color: #fff;
    outline: none;
    color:  var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    padding: 10px 5px;
    display: block;
}
.query-ans-area textarea::placeholder {
    color: #999;
}
.query-ans-area .query-ans-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}
.query-ans-area .query-ans-btn button {
    border: 0;
    width: 140px;
    display: block;
    padding: 9px 0;
    border-radius: 10px;
    border: 0;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    position: relative;
    overflow: hidden;
    z-index: 5;
    transition: 0.5s;
}
.query-ans-area .query-ans-btn button:nth-child(1) {
    background: var(--blue, var(--primary-color));
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #fff;
}
.query-ans-area .query-ans-btn button:nth-child(1)::after {
    content: "";
    display: block;
    position: absolute;
    width: 20%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    top: 0;
    left: -50%;
    z-index: -1;
    transform: skew(40deg);
}
.query-ans-area .query-ans-btn button:nth-child(1):hover:after {
    left: 150%;
    transition: 0.8s;
}
.query-ans-area .query-ans-btn button:nth-child(2) {
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    background-color: #E5E5E5;
    color: var(--theme-body-color);
}
.query-ans-area .query-ans-btn {
    margin-bottom: 30px;
}

.query-ans-area .query-ans-btn button:nth-child(2)::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    top: 0;
    left: -100%;
    z-index: -1;
    
    transition: 0.5s;
}
.query-ans-area .query-ans-btn button:nth-child(2):hover:after {
    left: 0;
}
.query-ans-area .query-ans-btn button:nth-child(2):hover {
    color: #fff ;
}
.your-query-area {
    margin-top: 25px;
}
.ai-accrodion-area .accordion-item {
    margin-bottom: 30px;
}
.ai-accrodion-area .accordion-button > div {
    margin-left: 14px;
}
.ai-accrodion-area .accordion-button > div span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.ai-accrodion-area .accordion-button > div h4 {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 0;
}
.ai-accrodion-area .accordion-item {
    width: 100%;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    border: 0;
    padding: 0;
    border-radius: 20px 20px 20px 20px !important;
}
.ai-accrodion-area .accordion-button:focus {
    box-shadow: none;
}
.ai-accrodion-area {
    margin-top: 25px;
}
.ai-accrodion-area .accordion-button {
    padding: 20px;
    border-radius: 20px;
    align-items: center !important;
}
.ai-accrodion-area .accordion-button:not(.collapsed) {
    background-color: transparent;
}
.ai-accrodion-area .accordion-item:last-of-type
 .accordion-button.collapsed {
    border-radius: 20px;
}
.ai-accrodion-area .accordion-item:first-of-type
 .accordion-button {
    border-radius: 20px;
 }
 .ai-accrodion-area .accordion-button:not(.collapsed) {
    box-shadow: none;
 }
 .ai-accrodion-area .accordion-body {
    color:var(--table-text-color,#666);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    padding-top: 0;
    padding-left: 50px;
 }
 .ai-accrodion-area .accordion-button:not(.collapsed)::after {
    transform: rotate(0);
    background-image: url(/images/arrow-down.svg);
}
.ai-accordion-item-wrapper {
    display: flex;
    align-items: flex-start;
}
.ai-accordion-item-wrapper {
    width: 100%;
}
.ai-accordion-item-wrapper > img {
    display: block;
    margin-right: 26px;
    margin-top: 30px;
}



/************************************
    Setting PAGE START HERE 
 ************************************/
 .setting-left-col {
    -ms-flex: 0 0 165px;
    flex: 0 0 165px;
    max-width: 165px;
}
.setting-right-col{
    -ms-flex: 0 0 calc(100% - 165px);
    flex: 0 0 calc(100% - 165px);
    max-width: calc(100% - 165px);
}
.setting-general-area .setting-nav-item ul li a {
    text-decoration: none;   
    color:#666;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    width: 165px;
    padding: 8px 0;
    padding-left: 15px;
    background-color: #E5E5E5;
    border-radius: 10px;
    transition: 0.3s;
}
.setting-general-area .setting-nav-item ul li a img {
    margin-right: 8px;
    //filter: grayscale(100%);
}
.setting-general-area .setting-nav-item ul li {
    display: block;
    margin-bottom: 10px;
}
.setting-general-area .setting-nav-item ul li a:hover img {
    filter: invert(0%) brightness(168%);
    opacity: 1;
}
.setting-general-area .setting-nav-item ul li a:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.sn-active {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}
.sn-active img {
    opacity: 1 !important;
    filter: invert(0%) brightness(168%);
}
.setting-general-content .sgc-right-checkbox input {
    width: 18px;
    height: 18px;
    border: 0;
}
.setting-general-content .sgc-right-checkbox input[type="radio"]:nth-child(1) {
    accent-color: var(--primary-color);
}
.setting-general-content .sgc-right-checkbox input[type="radio"]:nth-child(2) {
    accent-color: #333333;
}
.setting-general-content .sgc-right-checkbox input[type="radio"]:nth-child(3) {
    accent-color: #E33453;
}
.setting-general-content .sgc-right-checkbox input[type="radio"]:nth-child(4) {
    accent-color: #21B298;
}
.setting-general-content .sgc-right-checkbox input[type="radio"]:nth-child(5) {
    accent-color: #5D2BEA;
}
.setting-general-content .sgc-right-checkbox {
    display: flex;
    gap: 13px;
}
.setting-general-content .form-check-input[type=radio]:nth-child(1) {
    background-image: url(/images/radio-blue.svg);
}
.setting-general-content .form-check-input[type=radio]:nth-child(2) {
    background-image: url(/images/radio-gray.svg);
}
.setting-general-content .form-check-input[type=radio]:nth-child(3) {
    background-image: url(/images/radio-pink.svg);
}
.setting-general-content .form-check-input[type=radio]:nth-child(4) {
    background-image: url(/images/radio-green.svg);
}
.setting-general-content .form-check-input[type=radio]:nth-child(5) {
    background-image: url(/images/radio-purple.svg);
}
.setting-general-content .form-check-input[type=radio] {
    display: block;
}
.sgc-right-checkbox {
    display: flex;
    gap: 30px !important;
}
.form-check-input:checked[value='option1'] {
    box-shadow: 0 0 0 0.25rem rgba(0, 125, 255,0.25) !important;
}
.form-check-input:checked[value='option2'] {
    box-shadow: 0 0 0 0.25rem rgba(51, 51, 51,0.25) !important;
}
.form-check-input:checked[value='option3'] {
    box-shadow: 0 0 0 0.25rem rgba(227, 52, 83,0.25) !important;
}
.form-check-input:checked[value='option4'] {
    box-shadow: 0 0 0 0.25rem rgba(32, 174, 148,0.25) !important;
}
.form-check-input:checked[value='option5'] {
    box-shadow: 0 0 0 0.25rem rgba(91, 42, 228,0.25) !important;
}

.sgc-range .form-switch .form-check-input:checked {
    background-image: url(/images/checkbox-blue.svg);
}
.sgc-range .form-check-input:checked {
    background-color: var(--menu-a-hover);
}
.sgc-range .form-check-input:focus {
    box-shadow: none;
}
.sgc-range .form-check-input {
    border: none;
    width: 35px;
    height: 18px;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    background-color: var(--menu-a-hover); ;
    background-image: url(/images/checkbox-blue.svg);
}
.sgc-range .form-switch .form-check-input:focus {
    background-image: url(/images/checkbox-blue.svg);
}
.sgc-range .form-switch .form-check-input:active {
    background-color: var(--menu-a-hover);;
}
.sgc-left h6 {
    color:  #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 0;
}
.setting-general-area {
    margin-top: 25px;
}
.setting-general-content {
    margin-left: 30px;
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    padding: 32px 20px;
    min-height: 400px;
}
.setting-general-content .sgc-range > p {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-top: 12px;
}
.setting-general-content .nice-select {
    width: 264px;
    height: 40px;
    border-radius: 10px;
    background: #F5F5F5;
    border: 0;
    color: #333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
}
.setting-general-content .row {
    margin-bottom: 20px;
}
.settings-subscription-area {
    margin-left: 30px;
}
.settings-subscription-area .newuser-earning-area {
    gap: 25px;
    margin-top: 30px;
}
.settings-subscription-area
 .newuser-cash-content {
    gap: 15px;
}
.settings-subscription-area .newuser-cash-content > div h5 {
    font-size: 16px;
}
.settings-subscription-area .newuser-earning-area {
    margin-top: 0;
}
.settings-subscription-area .payment-details-btn button {
    width: 100%;
    margin-top: 30px;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    border: 0;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    padding: 16px 0;
    position: relative;
    overflow: hidden;
    z-index: 5;
}
.settings-subscription-area .payment-details-btn button::after {
    content: "";
    display: block;
    width: 5%;
    height: 100%;
    transform: skew(50deg);
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: -50%;
}
.settings-subscription-area .payment-details-btn button:hover:after {
    left: 150%;
    transition: 1s;
}
.settings-subscription-area .subs-plan-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.subs-plan-area {
    margin-top: 42px;
}
.settings-subscription-area .subs-plan-header h4 {
    color: var(--theme-body-color);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}
.settings-subscription-area .subs-plan-header ul li a {
    display: block;
    width: 90px;
    padding: 9px 0;
    text-decoration: none;
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border-radius: 10px;
    background: #E5E5E5;
    transition: 0.3s;
}
.settings-subscription-area .subs-plan-header ul li a:hover {
    background-color: var(--primary-color);
    color: #fff;
}
.settings-subscription-area .subs-plan-header ul {
    display: flex;
    align-items: center;
    gap: 15px;
}
.spb-active {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}
.subs-plan-item .sp-cart-header span {
    color: var(--primary-color);
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    display: block;
    text-transform: uppercase;
}
.subs-plan-item .sp-cart-header h4 {
    color: var(--theme-body-color);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}
.subs-plan-item .sp-cart-header h4 small {
    font-size: 17px;
}
.subs-plan-item .sp-cart-header {
    width: 100%;
    min-height: 73px;
}
.subs-plan-item .sp-cart-header {
    padding: 11px 0;
}
.subs-plan-item .sp-cart-body ul li {
    margin-bottom: 22px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.subs-plan-item .sp-cart-body ul li span {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.24px;

}
.subs-plan-item .sp-cart-body ul li img {
    display: inline-block;
}
.subs-plan-item .sp-cart-body ul li span {
    width: 160px;
    display: inline-block;
    margin: 0;
}
.subs-plan-item .sp-cart-body > a {
    text-decoration: none;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    width: 140px;
    border-radius: 10px;
    margin-top: 8px;
    padding: 9px 0;
    margin-left: auto;
    margin-right: auto;
    transition: 0.3s;
}
.subs-plan-area .spcc-1 {
    background-color: var(--theme-background-second);
    border-radius: 20px 0 0 0;
}
.subs-plan-area .spcc-2 {
    background-color: var(--subscription-card-bg,--menu-a-hover);
    border-radius: 0 0 0 0;
}
.subs-plan-area .spcc-4 {
    background-color: var(--subscription-card-bg,--menu-a-hover);
    border-radius: 0 0 0 0;
}
.subs-plan-area .spcc-1 .sp-cart-body > a {
    background:var(--checkbox-bg,#E5E5E5);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    color: var(--theme-body-color);
}
.subs-plan-area .spcc-2 .sp-cart-body > a {
    background:var(--select-bg,#fff);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    color: var(--theme-body-color);
}
.subs-plan-area .spcc-1 .sp-cart-body > a:hover {
    background-color: var(--primary-color);
    color: #fff;
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
}
.subs-plan-area .spcc-2 .sp-cart-body > a:hover {
    background-color: var(--dark-blue);
    color: #fff;
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
}
.subs-plan-area .sp-cart-body {
    padding: 20px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}
.subs-plan-item .sp-cart-body ul {
    max-width: 185px;
    margin-left: auto;
    margin-right: auto;
}
.subs-plan-item {
    margin-top: 20px;
}
.subs-plan-item .spcc-3 {
    border-radius: 0 0 0 0;
}
.subs-plan-item .spcc-4 {
    border-radius: 0 20px 0 0;
}
.subs-plan-item .sp-cart-footer {
    padding: 22px 0;
    background-color: var(--theme-background-second);
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}
.subs-plan-item .sp-cart-footer a {
    text-decoration: none;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    text-align: center;
    display: block;
}
.subs-history-table-area table tr td {
    width: 33%;
}
.subs-plan-op2-area > div:nth-child(1) {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}
.subs-plan-op2-area .subs-plan-header {
    margin-bottom: 9px;
}
.subs-plan-op2-area .spcc-1 {
    background-color: var(--menu-a-hover) !important;
}
.subs-plan-op2-area .spcc-2 {
    background-color: #F5F5F5 !important;
}
.subs-plan-op2-area .sp-cart-body {
    border-bottom: 0px solid transparent !important;
}
.subs-plan-op2-area .subs-plan-item .sp-cart-footer {
    box-shadow: none;
}
.subs-plan-op2-area .spcc-1 .sp-cart-body > a {
    background-color: #fff;
}
.subs-plan-op2-area .spcc-4 {
    border-radius: 0 20px 20px 0;
}
.subs-plan-op2-area .spcc-1 {
    border-radius: 20px 0 0 20px;
}
.subs-plan-op2-area .spcc-3 {
    border-radius: 0 0 0 0;
}

.subs-plan-op2-area .subs-plan-header ul li a  {
    border-radius: 5px;
    width: 100px;
    padding: 5px 0;
}
.subs-plan-op2-area .subs-plan-header h4 {
    font-size: 15px;
}


/**********************************
settings-exchangesAPI page start 
***********************************/
.setting-exchange-area {
    margin-left: 30px;
}
.add-brokerage-area {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.02);
    padding: 20px;
}
.brokerage-logos .name-div {
    width: 33.33%;
    height: 80px;
    border-radius: 10px;
    background:var(--switch-bg,#F5F5F5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left:  30px;
    text-align: left;
}
.brokerage-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 30px;
    flex-flow: wrap;
}
.add-brokerage-area > h4 {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
}
.add-brokerage-area > h4 img {
    display: inline-block;
    margin-left: 10px;
}
.brokerage-footer {
    margin-top: 11px;
}
.brokerage-footer p {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    text-align: center;
    margin-bottom: 0;
}
// .brokerage-footer a {
//     text-decoration: none;
//     display: block;
//     max-width: 264px;
//     color: #FFF;
//     text-align: center;
//     font-size: 12px;
//     font-weight: 700;
//     letter-spacing: 0.24px;
//     border-radius: 10px;
//     background:  var(--primary-color);
//     box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
//     margin-top: 30px;
//     padding: 16px;
//     margin-left: auto;
//     margin-right: auto;
//     overflow: hidden;
//     position: relative;
//     z-index: 5;
// }
.brokerage-footer span {
    display: block;
    text-align: center;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin-top: 20px;
}




.brokerage-footer a::after {
    content: "";
    display: block;
    width: 20%;
    height: 100%;
    transform: skew(50deg);
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: -50%;
}
/* .brokerage-footer a:hover:after {
    left: 150%;
    transition: 0.8s;
} */
.connected-brokerage-area {
    margin-top: 40px;
}
.connected-brokerage-area h4 {
    color: var(--theme-body-color);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
}
.main-brokerage-cart .dna-cart-header ul li:nth-child(1) h6 {
    margin-bottom: 0;
    color: var(--theme-body-color);
    font-size: 12px;
}
.main-brokerage-cart .dna-cart-body p {
    word-wrap: break-word;
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    margin-bottom: 22px;
}
.main-brokerage-cart .dna-cart-body span {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    margin-top: 15px;
}
.brokerage-cart-footer > span {
    color:#999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
    margin-bottom: 4px;
}
.brokerage-cart-footer {
    display: block;
}
.brokerage-cart-footer > div input {
    width: 80px;
    height: 35px;
    border: 0;
    outline: none;
    border-radius: 10px;
    background: var(--checkbox-bg,#f5f5f5);
    color: var(--table-text-color,#333);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    padding: 7px 16px ;
    padding-right: 10px;
    display: block;
    text-align: right;
}
.brokerage-cart-footer > div select {
    width: 80px;
    height: 35px;
    border: 0;
    outline: none;
    border-radius: 10px;
    background: var(--checkbox-bg,#f5f5f5);
    color: var(--table-text-color,#333);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    padding: 7px 16px;
    display: block;
}
.brokerage-cart-footer > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.brokerage-cart-footer > div button {
    width: 85px;
    padding: 9px 0;
    border: 0;
    border-radius: 10px;
    background: var(--primary-color);
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    display: block;
}
.main-brokerage-cart {
    min-height: 231px;
}
.brokerage-cart-footer > div h6 {
    color:  var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin-top: 10px;
    margin-bottom: 0;
}
.settings-PaperWallet-area {
    margin-left: 30px;
}
.settings-PaperWallet-heading .spw-headong-left button {
    width: 60px;
    height: 60px;
    border: 0;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
.spw-heading-row {
    border-radius: 20px;
    background:  var(--dark-blue);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 15px 0;
}
.spw-headong-left {
    display: flex;
    gap: 39px;
    align-items: center;
    padding-left: 15px;
}
.spw-headong-left label span {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
}
.spw-headong-left label h6 {
    color:#FFF;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}
.spw-headong-right {
    display: flex;
    align-items: center;
    gap: 15px;
}
.spw-headong-right button {
    width: 120px;
    display: block;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 10px 0px rgba(0, 125, 255, 0.35);
    color: var(--primary-color);
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    padding: 16px 0;
    border: 0;
    transition: 0.3s;
}
.spw-headong-right button:hover {
    background-color: var(--primary-color);
    color: #fff;
}
.spwhbtn-active {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}
.paperwallet-table-area .paperwallet-btn button {
    width: 80px;
    padding: 9px 0;
    border-radius: 10px;
    background: #E5E5E5;
    color: #666;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    border: 0;
    transition: 0.3s;
}
.paperwallet-table-area .paperwallet-btn button:nth-child(3) {
    width: 100px !important;
}
.paperwallet-table-area {
    margin-top: 40px;
}
.paperwallet-table-area .paperwallet-btn {
    display: flex;
    align-items: center;
    gap: 15px;
}
.paperwallet-table-area .paperwallet-btn button:hover {
    background-color: var(--primary-color);
    color: #fff;
}
.pwtbtnactive {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}

.paperwallet-table-content {
    border-radius: 20px;
    background: var(--theme-background-second);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
    padding: 20px;
}
.paperwallet-table-content .pwtc-heading ul {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 10px;
}
.paperwallet-table-content .pwtc-heading ul li span {
    color: var(--theme-body-color);
    font-size: 15px;
    font-weight: 600;
}
.paperwallet-table-content .pwtc-heading ul li h6 {
    color:var(--theme-body-color);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
.paperwallet-table-content .pwtc-heading > input:not(.custom-checkbox),.setting-general-content .general  > input:not(.custom-checkbox),.paperwallet-table-content .pwtc-cross > input:not(.custom-checkbox){
    width: 250px;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: var(--checkbox-bg,#f5f5f5);
    display: block;
    padding: 11px 17px;
    padding-right: 40px;
    background-image: url(/images/icon-search.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%;
}
.paperwallet-table-content .pwtc-heading > input:not(.custom-checkbox)::placeholder,.setting-general-content .general  > input:not(.custom-checkbox)::placeholder,.paperwallet-table-content .pwtc-cross > input:not(.custom-checkbox)::placeholder {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.pwtc-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}
.pwtc-heading > div {

}

/* custome checkbox */

.pwtc-heading > div > label {
    display: flex;
    align-items: center;
    color: #9e9e9e;
    position: relative;
}

.pwtc-heading > div input {
    width: 0;
    height: 0;
    opacity: 0;
}

.pwtc-heading > div label>span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    border: 2px solid #E5E5E5;
    margin-right: 12px;
    border-radius: 3px;
    transition: all .3s;
    border-radius: 5px;
    background-color: #E5E5E5;
}

.pwtc-heading > div input:checked+label>span {
    border: 10px solid var(--primary-color);
    animation: bounce 250ms;
}

.pwtc-heading > div input:checked+label>span::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 6px;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checked-box 125ms 250ms forwards;
}

@keyframes checked-box {
    0% {
        width: 0;
        height: 0;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
    }
    33% {
        width: 4px;
        height: 0;
        border-color: #fff;
        transform: translate(0, 0) rotate(45deg);
    }
    100% {
        width: 4px;
        height: 8px;
        border-color: #fff;
        transform: translate(0, -8px) rotate(45deg);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(.7);
    }
    100% {
        transform: scale(1);
    }
}

.pwtc-heading > div label {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
}
.paperwallet-main-table .open-table-content {
    border-radius: 0;
    background: var(--theme-background-second);
    box-shadow: none;
    margin-top: 30px;
}

.paperwallet-main-table .total-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400 !important;
    letter-spacing: 0.24px;
}
.paperwallet-main-table .avaiable-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.paperwallet-main-table .reserved-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.paperwallet-main-table .usdt-data h6 {
    color: var(--theme-body-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}
.paperwallet-main-table table tr td h6 {
    margin-bottom: 0 !important;
}
.paperwallet-table-content {
    margin-top: 25px;
}


.showdaisy {
    display: none;
}
.showdaisy.hidedasiy {
    display:  flex;
}
.pagination{
    justify-content: end;
}
.symbol-name{
    min-width: 67px;
    max-width: 70px;
}
.bot-list-base-img-icon{
    width: 30px;
}
.rental-menu li a{
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;
}
.stock-select{
    width: 150px;
}
.bot-select{
    width: 300px;
}
.gap20{
    gap: 20px;
}
.gap10{
    gap: 10px;
}
.select-normal.darker{
    background: var(--switch-bg,#F5F5F5);
    box-shadow: none;
}
.premium_info_div{
    min-height: 200px;
}
.sp-cart-body ul{
    min-height: 370px;
}
.cancel-btn{
    font-size: 10px !important;
    position: absolute;
    cursor: pointer;
    right: 14px;
}
.mr-10{
    margin-right: 10px;
}
.brokerage-logos .name-div.active{
    box-shadow: 0 0 0 0.25rem rgba(0, 125, 255, 0.25);
}
.close{
    border: none !important;
    background: var(--primary-color);
    color: #fff;
}
.bc-step-icons li a label.active{ 
    background-color: var(--primary-color);
    color: #fff;
}
.bc-step-icons li a label.active img{ 
    filter: brightness(100);
}
.cb-form-div{
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.dropdown-menu.indicator{
    padding: 5px 0px;
    font-size: 13px;
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.05);
}
.dropdown-menu.indicator li a{
    text-decoration: none;
    display: block;
    padding: 9px 0px;
    color: #999;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.22px;
    padding-left: 30px;
}
.blue-button.small{
    height: 36px;
}
.blue-button.small-blue-button{
    height: 36px;
    width:70px;
    font-size: 11px;
    font-weight: normal;
}
.react-switch-bg{
    background:var(--primary-color) !important
}
.dca-entry-select{
    box-shadow: none !important;
    background: #F5F5F5 !important;
}
.var-tp-cross{
    position: absolute;
    right: 15px;
    top: 20px;
}
.select-normal.small{
    height: 32px;
    padding: 3px 13px;
}
.progress-bar {
    background-color: var(--progress-bar-color) !important;
}
.dropdown-menu li:hover{
    background-color: var(--menu-a-hover);
}
.m-0-auto{
    margin: 0 auto;
} 
.w-80{
    width: 80%;
}
.cb-quit{
    font-size: 30px;
    color: var(--primary-color);
    position: absolute;
}
.terms-link{
    margin: 2px 4px;
}
.select-normal.bot-filter-dropdown{
    height: 37px;
}
.highlighted-text{
    font-size: 14px;
    font-weight: 500;
}
.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: var(--select-text-color,#212529);
    text-align: left;
    list-style: none;
    background-color: var(--select-bg,#fff);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: var(--select-text-color,#212529);;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--theme-body-color);
    background-color: var(--menu-a-hover);
  }
  .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--table-text-color,#212529);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--table-text-color,#212529);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--table-text-color,#212529);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--table-text-color,#212529);
    vertical-align: top;
    //border-color: #dee2e6;
  }
  .table thead tr:first-child:hover {
    background-color: inherit; /* Prevent color change */
  }
  .text-right{
    text-align: right;
  }
  .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--theme-body-color);
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    filter: var(--invert-filter,brightness(100%));
  }
  .btn-close:hover {
    color: var(--theme-body-color);
    text-decoration: none;
    opacity: 0.75;
  }
  .mr-20{
    margin-right: 20px !important;
  }
  .mr-2{
    margin-right: 2%;
  }
  .mr-4{
    margin-right: 4%;
  }
  .newuser-earning-area.portfolio{
    justify-content: flex-start;
  }
  .template-tile-div{
    min-height: 35px;
  }