#indicator-select .dropdown-menu {
  position: relative !important;
  z-index: 10000 !important;
  transform: none !important;
  width: 100% !important;
}

#indicator-select .dropdown-item {
  width: 100% !important;
}

#indicator-select .btn {
  width: 100%;
}
