.deal-tab {
  min-height: 700px;
}

.deal-tab .badge {
  font-size: 12px !important;
  font-weight: normal !important;
}

@media screen and (min-height: 1440px) {
  .deal-tab {
    min-height: 1000px;
  }
}

@media screen and (min-height: 2000px) {
  .deal-tab {
    min-height: 1400px;
  }
}
