.search-results{
    padding: 5px;
    position: absolute;
    background: var(--input-select-bg-color);
    width: 271px;
    z-index: 20000000;
    height: 165px;
    overflow-y: auto;
    margin-top: 4px;
    border-radius: 6px;
}
.search-results li{
    padding: 5px;
    cursor: pointer;
}
.search-results li:hover{
   // background-color: var;
}
/* Customize the scroll bar track */
.search-results::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  /* Customize the scroll bar thumb */
  .search-results::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  /* Customize the scroll bar track */
  .search-results::-moz-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  /* Customize the scroll bar thumb */
  .search-results::-moz-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  /* Change the appearance of the scroll bar thumb on hover */
  .search-results::-webkit-scrollbar-thumb:hover,
  .search-results::-moz-scrollbar-thumb:hover {
    background-color: #555;
  }
  .accordion > .card {
    overflow: unset;
}