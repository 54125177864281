.new-pass .new-pass-col {
  max-width: 37% !important;
  margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
  .new-pass .new-pass-col {
    max-width: 100% !important;
  }
}

@media only screen and (min-height: 1440px) {
  .new-pass {
    margin-top: 180px !important;
  }

  .new-pass .new-pass-col {
    max-width: 35% !important;
  }

  .new-pass .form-group {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }

  .new-pass .card-body {
    padding: 40px !important;
  }
}

@media only screen and (min-height: 2000px) {
  .new-pass {
    margin-top: 220px !important;
  }

  .new-pass .new-pass-col {
    max-width: 22% !important;
  }

  .new-pass .form-group {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }

  .new-pass .card-body {
    padding: 50px !important;
  }
}
